import React, { Component } from "react";
import moment from "moment";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import { LineChart, XAxis, YAxis, CartesianGrid, Brush, Tooltip, Line, Legend } from 'recharts';
import CustomOAuthButton from '../CustomOAuthButton';
import { DatePicker } from 'antd';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

const { RangePicker } = DatePicker;

export default class CheckInStats extends Component {
  constructor(props) {
    super(props);
    let defaultStart = this.nearestPastMinutes(15, moment());
    let defaultEnd = defaultStart.clone().add(15, 'minutes');
    this.state = {
      startTime: defaultStart,
      endTime: defaultEnd,
      testType: 'AG',
      isLoading: false,
      noResults: true,
      results: [],
      grpsOk: false,
      summary: {},
      stats: {}
    };
    this.getStats = this.getStats.bind(this);
    this.handleDateTimeRangeChange = this.handleDateTimeRangeChange.bind(this);
  }

  nearestPastMinutes(interval, someMoment){
    const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
    let pastMins = someMoment.clone().minute(roundedMinutes).second(0);
    if (someMoment.minute() === roundedMinutes) {
      pastMins.subtract(15, 'minutes');
    }
    return pastMins;
  }

  handleDateTimeRangeChange(value, dateString) {
    if (value === null) {
      this.setState({
        startTime: null,
        endTime: null
      })
    } else {
      this.setState({
        startTime: value[0],
        endTime: value[1]
      });
    };
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:self-checkin-admin:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {

      this.setState({ csvGroups: this.props.groups });
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false });
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // download JSON data from API endpoint
  async getStats() {
    this.setState({
      isLoading: true,
      noResults: false,
      stats: {}
    });
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          start_time: this.state.startTime.format('YYYY-MM-DD HH:mm:ss'),
          end_time: this.state.endTime.format('YYYY-MM-DD HH:mm:ss')
        }
      }
      return API.post("sampleCollectionStats", "/sampleCollectionStats", myInit)
        .then(res => {
          if (Object.keys(res.stats).length === 0 && res.stats.constructor === Object) {
            this.setState({
              noResults: true,
              stats: {},
              isLoading: false
            });
          } else {
            this.setState({
              noResults: false,
              stats: res.stats,
              isLoading: false
            });
          }
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderStats() {
    const formatXAxis = tickItem => {
      return moment(tickItem).format('MM/DD HH:mm');
    };

    return Object.keys(this.state.stats).map(s => (
        <div className="row">
          <h3>{s}</h3>
          <div className='line-chart-wrapper' style={{ padding: 0}}>
            <LineChart
              width={1000}
              height={500}
              data={this.state.stats[s]}
              margin={{top: 10, bottom: 10, left: 0, right: 50}}
            >
              <XAxis dataKey='time' angle={-45} height={90} textAnchor="end" tickFormatter={formatXAxis} />
              <YAxis />
              <Legend />
              <CartesianGrid stroke='#f5f5f5'/>
              <Brush />
              <Tooltip filterNull={false} />
              <Line
                type="monotone"
                key="0"
                dataKey="AG"
                stroke="#EE4055"
                strokeWidth={2}
                activeDot={{fill: '#EE4055', stroke: 'none', r: 6}}
                onClick={this.handleLineClick}
              />
              <Line type='monotone' key="1" dataKey='PCR-NP' stroke='#378DBD' strokeWidth={2} activeDot={{fill: '#378DBD', stroke: 'none', r: 6}}/>
              <Line type='monotone' key="2" dataKey='PCR-SG' stroke='#4A634E' strokeWidth={2} activeDot={{fill: '#4A634E', stroke: 'none', r: 6}}/>
            </LineChart>
          </div>
        </div>
      )
    )
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>View Check-In Stats</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to View Check-In Stats</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>View Check-In Stats</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>View Check-In Stats</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderStatsDisplay() {
    return (
      <>
        <div className="row d-flex">
          <div className="col col-md-4 form-group">
            <label htmlFor="testDate">Date/Time Range</label>
            <RangePicker ref={el => this.rangePicker = el}
              showTime={{ format: 'HH:mm', minuteStep: 15 }}
              format="YYYY-MM-DD HH:mm"
              defaultValue={[this.state.startTime, this.state.endTime]}
              onChange={this.handleDateTimeRangeChange}
            />
          </div>
          <div className="col col-md-4 align-self-center">
            <button className={`btn btn-primary`} onClick={this.getStats}>Get Stats</button>
          </div>
        </div>
        {
          !this.state.noResults && this.renderStats()
        }
      </>
    )
  }

  renderDateTestSelect() {
    return (
      <>
        <div className="row">
          <h3>View Check-In Stats</h3>
        </div>
        <div class="row">
          <div class="alert alert-info" role="alert">Each data point represents all the check-ins performed during a 15-minute interval (e.g., 12/07 09:00 would contain all check-ins between 12/07 09:00:00 and 12/07 09:14:59).</div>
        </div>
        { this.renderStatsDisplay()}
      </>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading)) && this.renderDateTestSelect()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
