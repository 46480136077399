import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class ModernaTrial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reqComplete: false,
      grpsOk: true
    };
    // this.modernaTrialLogin = this.modernaTrialLogin.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authn !== prevProps.authn) {
      if (this.props.authn !== 'signedIn') {
        return;
      }
      // invoke check for Moderna trial eligibility
      if (!this.state.reqComplete) {
        await this.modernaTrialLogin();
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn === 'signedIn') {
      // invoke check for Moderna trial eligibility
      if (!this.state.reqComplete) {
        await this.modernaTrialLogin();
      }      
    }
  }

  // request student compliance report
  async modernaTrialLogin() {
    this.setState({isLoading: true});
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("modernaTrialLogin", "/modernaTrialLogin", myInit)
        .then(res => {
          window.location.href = res.redirect_url
        });
    }).catch(error => {
      this.setState({
        isLoading: false,
        reqComplete: true,
        grpsOk: false
      });
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Moderna Vaccine Study</h1>
        <h3>Learn more about this study</h3>
        <div className="container">
          <div className="row mb-4">
            <div className="col col-md-8 col-md-offset-2">
              Clicking the link below will verify your NetID, age, and status as a UArizona student. Upon verification you will be directed to the Informed Consent for this study.
            </div>
          </div>
        </div>
        <CustomOAuthButton variant="primary" size="lg">Login</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Moderna Vaccine Study</h1>
        <div className="alert alert-danger" role="alert">You are not eligible to participate in this study.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Moderna Vaccine Study</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && !this.state.isLoading && this.state.reqComplete && !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
