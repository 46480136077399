const dev = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack2: {
    REGION: "us-gov-west-1",
    URL: "https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    // REGION: "us-west-2",
    // USER_POOL_ID: "us-west-2_s5072pJC9",
    // APP_CLIENT_ID: "14tnphml63ovpdvmq9vo3093pu",
    // DOMAIN: "applinnov.auth.us-west-2.amazoncognito.com",
    REDIRECT_SIGNIN: "http://localhost:4200/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:azdhs-csv'
  },
  pcradmin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv'
  },
  submenu: {
    "arizona.edu:services:enterprise:ctrds:azdhs-csv": [
      {
        "menu_item": "AZDHS Antigen Results Export",
        "uri": "/export",
        "idx": 0
      },
      {
        "menu_item": "Antigen Results Search",
        "uri": "/search",
        "idx": 1
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv": [
      {
        "menu_item": "AZDHS PCR Results Export",
        "uri": "/exportpcr",
        "idx": 2
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-all": [
      {
        "menu_item": "Campus Health Positive Results Report",
        "uri": "/posresults",
        "idx": 3
      },
      {
        "menu_item": "Campus Health Results Upload",
        "uri": "/chupload",
        "idx": 4
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm": [
      {
        "menu_item": "Campus Housing Positive Results Report",
        "uri": "/posresults",
        "idx": 5
      }
    ],
    "arizona.edu:services:enterprise:ctrds:safer-results-dump": [
      {
        "menu_item": "SAFER Positive Results CSV",
        "uri": "/safer",
        "idx": 6
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-lab:allowed-users": [
      {
        "menu_item": "PCR Results Upload",
        "uri": "/pcrupload",
        "idx": 7
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-map:access": [
      {
        "menu_item": "Test Smart Map",
        "uri": "/map",
        "idx": 8
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-compliance": [
      {
        "menu_item": "Dorm Compliance CSV",
        "uri": "/dorm-compliance",
        "idx": 9
      }
    ],
    "arizona.edu:services:enterprise:ctrds:test-aging-report:allowed-users": [
      {
        "menu_item": "Test Aging Report",
        "uri": "/tar",
        "idx": 10
      }
    ],
    "arizona.edu:services:enterprise:ctrds:positive-antigen-sample-curator:allowed-users": [
      {
        "menu_item": "Positive Antigen Sample Curator",
        "uri": "/pasc",
        "idx": 11
      },
      {
        "menu_item": "Borderlines CSV Report",
        "uri": "/borderlines-csv",
        "idx": 13
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-testing-report:allowed-users": [
      {
        "menu_item": "Dorm Testing Counts",
        "uri": "/dorm-counts",
        "idx": 12
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pac12-results:allowed-users": [
      {
        "menu_item": "PAC-12 Results Report",
        "uri": "/pac12",
        "idx": 14
      }
    ],
    "arizona.edu:services:enterprise:ctrds:self-checkin-admin:allowed-users": [
      {
        "menu_item": "Check-In Stats",
        "uri": "/checkin-stats",
        "idx": 15
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-exemptions:admin-rollup": [
      {
        "menu_item": "Accommodations Admin",
        "uri": "/accommodations",
        "idx": 16
      }
    ],
    "arizona.edu:services:enterprise:ctrds:student-compliance": [
      {
        "menu_item": "Student Compliance CSV",
        "uri": "/student-compliance",
        "idx": 17
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-upload": [
      {
        "menu_item": "Campus Health Vaccine Records Upload",
        "uri": "/vaccine-records",
        "idx": 18
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-report": [
      {
        "menu_item": "Campus Health Vaccine Exemption Report",
        "uri": "/vaccine-exemption",
        "idx": 19
      }
    ],
    "arizona.edu:services:enterprise:ctrds:wifi-block-stats": [
      {
        "menu_item": "UAWiFi Block Stats",
        "uri": "/wifi-block-stats",
        "idx": 20
      }
    ],
    "arizona.edu:services:enterprise:ctrds:immunity-rpt": [
      {
        "menu_item": "Immunity Report",
        "uri": "/immunity",
        "idx": 21
      }
    ],
    "arizona.edu:services:enterprise:ctrds:rotc-compliance": [
      {
        "menu_item": "ROTC Compliance CSV",
        "uri": "/rotc-compliance",
        "idx": 22
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:neg-results-users": [
      {
        "menu_item": "Off-Campus Negative Results Review",
        "uri": "/neg-result-admin",
        "idx": 23
      },
    ],
    "arizona.edu:services:enterprise:ctrds:breakthru-rpt": [
      {
        "menu_item": "Breakthrough Infections/Reinfections",
        "uri": "/breakthrough",
        "idx": 24
      },
    ],
    "arizona.edu:services:enterprise:ctrds:student-vaccinations-rpt": [
      {
        "menu_item": "Student Vaccination Report",
        "uri": "/student-vaccination-report",
        "idx": 25
      },
    ],
    "arizona.edu:services:enterprise:ctrds:stud-athlete-vax-rpt": [
      {
        "menu_item": "Athletics Vaccination Report",
        "uri": "/athletics-vaccination",
        "idx": 26
      },
    ],
    "arizona.edu:services:enterprise:ctrds:tats-running-total": [
      {
        "menu_item": "TATS Test Tracking",
        "uri": "/test-tracking",
        "idx": 27
      },
    ],
    "arizona.edu:services:enterprise:ctrds:inc-samples-rpt": [
      {
        "menu_item": "TATS Incomplete Samples",
        "uri": "/incomplete",
        "idx": 28
      },
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-iso-rpt": [
      {
        "menu_item": "Isolation Testing Report",
        "uri": "/isolation",
        "idx": 29
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:allowed-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 30
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:housing-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 31
      },
    ],
  }
  // cognito: {
  //   REGION: "us-west-2",
  //   USER_POOL_ID: "us-west-2_s5072pJC9",
  //   APP_CLIENT_ID: "14tnphml63ovpdvmq9vo3093pu",
  //   DOMAIN: "applinnov.auth.us-west-2.amazoncognito.com",
  //   REDIRECT_SIGNIN: "http://localhost:4200/",
  //   REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  // }
};

const test = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack2: {
    REGION: "us-gov-west-1",
    URL: "https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    REDIRECT_SIGNIN: "https://results-test.wellcheck.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:azdhs-csv'
  },
  pcradmin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv'
  },
  submenu: {
    "arizona.edu:services:enterprise:ctrds:azdhs-csv": [
      {
        "menu_item": "AZDHS Antigen Results Export",
        "uri": "/export",
        "idx": 0
      },
      {
        "menu_item": "Antigen Results Search",
        "uri": "/search",
        "idx": 1
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv": [
      {
        "menu_item": "AZDHS PCR Results Export",
        "uri": "/exportpcr",
        "idx": 2
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-all": [
      {
        "menu_item": "Campus Health Positive Results Report",
        "uri": "/posresults",
        "idx": 3
      },
      {
        "menu_item": "Campus Health Results Upload",
        "uri": "/chupload",
        "idx": 4
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm": [
      {
        "menu_item": "Campus Housing Positive Results Report",
        "uri": "/posresults",
        "idx": 5
      }
    ],
    "arizona.edu:services:enterprise:ctrds:safer-results-dump": [
      {
        "menu_item": "SAFER Positive Results CSV",
        "uri": "/safer",
        "idx": 6
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-lab:allowed-users": [
      {
        "menu_item": "PCR Results Upload",
        "uri": "/pcrupload",
        "idx": 7
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-map:access": [
      {
        "menu_item": "Test Smart Map",
        "uri": "/map",
        "idx": 8
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-compliance": [
      {
        "menu_item": "Dorm Compliance CSV",
        "uri": "/dorm-compliance",
        "idx": 9
      }
    ],
    "arizona.edu:services:enterprise:ctrds:test-aging-report:allowed-users": [
      {
        "menu_item": "Test Aging Report",
        "uri": "/tar",
        "idx": 10
      }
    ],
    "arizona.edu:services:enterprise:ctrds:positive-antigen-sample-curator:allowed-users": [
      {
        "menu_item": "Positive Antigen Sample Curator",
        "uri": "/pasc",
        "idx": 11
      },
      {
        "menu_item": "Borderlines CSV Report",
        "uri": "/borderlines-csv",
        "idx": 13
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-testing-report:allowed-users": [
      {
        "menu_item": "Dorm Testing Counts",
        "uri": "/dorm-counts",
        "idx": 12
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pac12-results:allowed-users": [
      {
        "menu_item": "PAC-12 Results Report",
        "uri": "/pac12",
        "idx": 14
      }
    ],
    "arizona.edu:services:enterprise:ctrds:self-checkin-admin:allowed-users": [
      {
        "menu_item": "Check-In Stats",
        "uri": "/checkin-stats",
        "idx": 15
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-exemptions:admin-rollup": [
      {
        "menu_item": "Accommodations Admin",
        "uri": "/accommodations",
        "idx": 16
      }
    ],
    "arizona.edu:services:enterprise:ctrds:student-compliance": [
      {
        "menu_item": "Student Compliance CSV",
        "uri": "/student-compliance",
        "idx": 17
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-upload": [
      {
        "menu_item": "Campus Health Vaccine Records Upload",
        "uri": "/vaccine-records",
        "idx": 18
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-report": [
      {
        "menu_item": "Campus Health Vaccine Exemption Report",
        "uri": "/vaccine-exemption",
        "idx": 19
      }
    ],
    "arizona.edu:services:enterprise:ctrds:wifi-block-stats": [
      {
        "menu_item": "UAWiFi Block Stats",
        "uri": "/wifi-block-stats",
        "idx": 20
      }
    ],
    "arizona.edu:services:enterprise:ctrds:immunity-rpt": [
      {
        "menu_item": "Immunity Report",
        "uri": "/immunity",
        "idx": 21
      }
    ],
    "arizona.edu:services:enterprise:ctrds:rotc-compliance": [
      {
        "menu_item": "ROTC Compliance CSV",
        "uri": "/rotc-compliance",
        "idx": 22
      }
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:neg-results-users": [
      {
        "menu_item": "Off-Campus Negative Results Review",
        "uri": "/neg-result-admin",
        "idx": 23
      },
    ],
    "arizona.edu:services:enterprise:ctrds:breakthru-rpt": [
      {
        "menu_item": "Breakthrough Infections/Reinfections",
        "uri": "/breakthrough",
        "idx": 24
      },
    ],
    "arizona.edu:services:enterprise:ctrds:student-vaccinations-rpt": [
      {
        "menu_item": "Student Vaccination Report",
        "uri": "/student-vaccination-report",
        "idx": 25
      },
    ],
    "arizona.edu:services:enterprise:ctrds:stud-athlete-vax-rpt": [
      {
        "menu_item": "Athletics Vaccination Report",
        "uri": "/athletics-vaccination",
        "idx": 26
      },
    ],
    "arizona.edu:services:enterprise:ctrds:tats-running-total": [
      {
        "menu_item": "TATS Test Tracking",
        "uri": "/test-tracking",
        "idx": 27
      },
    ],
    "arizona.edu:services:enterprise:ctrds:inc-samples-rpt": [
      {
        "menu_item": "TATS Incomplete Samples",
        "uri": "/incomplete",
        "idx": 28
      },
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-iso-rpt": [
      {
        "menu_item": "Isolation Testing Report",
        "uri": "/isolation",
        "idx": 29
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:allowed-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 30
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:housing-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 31
      },
    ],
  }
};

const prod = {
  apiGateway: {
    REGION: "us-gov-west-1",
    URL: "https://7zj1u3rfy9.execute-api.us-gov-west-1.amazonaws.com/prd"
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack2: {
    REGION: "us-gov-west-1",
    URL: "https://hw6nf8z470.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  apiGatewayStack3: {
    REGION: "us-gov-west-1",
    URL: "https://a8hbnudn9e.execute-api.us-gov-west-1.amazonaws.com/prd"
    // REGION: "us-west-2",
    // URL: "https://9bm89pl9ak.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-gov-west-1",
    USER_POOL_ID: "us-gov-west-1_Yerqo6rUv",
    APP_CLIENT_ID: "4e450ll69pq651l3o3dkjsda5g",
    DOMAIN: "ctrds.auth-fips.us-gov-west-1.amazoncognito.com",
    REDIRECT_SIGNIN: "https://results.wellcheck.arizona.edu/",
    REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  },
  admin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:azdhs-csv'
  },
  pcradmin: {
    GROUPER_GROUP: 'arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv'
  },
  submenu: {
    "arizona.edu:services:enterprise:ctrds:azdhs-csv": [
      {
        "menu_item": "AZDHS Antigen Results Export",
        "uri": "/export",
        "idx": 0
      },
      {
        "menu_item": "Antigen Results Search",
        "uri": "/search",
        "idx": 1
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-azdhs-csv": [
      {
        "menu_item": "AZDHS PCR Results Export",
        "uri": "/exportpcr",
        "idx": 2
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-all": [
      {
        "menu_item": "Campus Health Positive Results Report",
        "uri": "/posresults",
        "idx": 3
      },
      {
        "menu_item": "Campus Health Results Upload",
        "uri": "/chupload",
        "idx": 4
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm": [
      {
        "menu_item": "Campus Housing Positive Results Report",
        "uri": "/posresults",
        "idx": 5
      }
    ],
    "arizona.edu:services:enterprise:ctrds:safer-results-dump": [
      {
        "menu_item": "SAFER Positive Results CSV",
        "uri": "/safer",
        "idx": 6
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pcr-lab:allowed-users": [
      {
        "menu_item": "PCR Results Upload",
        "uri": "/pcrupload",
        "idx": 7
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-map:access": [
      {
        "menu_item": "Test Smart Map",
        "uri": "/map",
        "idx": 8
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-compliance": [
      {
        "menu_item": "Dorm Compliance CSV",
        "uri": "/dorm-compliance",
        "idx": 9
      }
    ],
    "arizona.edu:services:enterprise:ctrds:test-aging-report:allowed-users": [
      {
        "menu_item": "Test Aging Report",
        "uri": "/tar",
        "idx": 10
      }
    ],
    "arizona.edu:services:enterprise:ctrds:positive-antigen-sample-curator:allowed-users": [
      {
        "menu_item": "Positive Antigen Sample Curator",
        "uri": "/pasc",
        "idx": 11
      },
      {
        "menu_item": "Borderlines CSV Report",
        "uri": "/borderlines-csv",
        "idx": 13
      }
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-testing-report:allowed-users": [
      {
        "menu_item": "Dorm Testing Counts",
        "uri": "/dorm-counts",
        "idx": 12
      }
    ],
    "arizona.edu:services:enterprise:ctrds:pac12-results:allowed-users": [
      {
        "menu_item": "PAC-12 Results Report",
        "uri": "/pac12",
        "idx": 14
      }
    ],
    "arizona.edu:services:enterprise:ctrds:self-checkin-admin:allowed-users": [
      {
        "menu_item": "Check-In Stats",
        "uri": "/checkin-stats",
        "idx": 15
      }
    ],
    "arizona.edu:services:enterprise:ctrds:testing-exemptions:admin-rollup": [
      {
        "menu_item": "Accommodations Admin",
        "uri": "/accommodations",
        "idx": 16
      }
    ],
    "arizona.edu:services:enterprise:ctrds:student-compliance": [
      {
        "menu_item": "Student Compliance CSV",
        "uri": "/student-compliance",
        "idx": 17
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-upload": [
      {
        "menu_item": "Campus Health Vaccine Records Upload",
        "uri": "/vaccine-records",
        "idx": 18
      }
    ],
    "arizona.edu:services:enterprise:ctrds:vaccinations-report": [
      {
        "menu_item": "Campus Health Vaccine Exemption Report",
        "uri": "/vaccine-exemption",
        "idx": 19
      }
    ],
    "arizona.edu:services:enterprise:ctrds:wifi-block-stats": [
      {
        "menu_item": "UAWiFi Block Stats",
        "uri": "/wifi-block-stats",
        "idx": 20
      }
    ],
    "arizona.edu:services:enterprise:ctrds:immunity-rpt": [
      {
        "menu_item": "Immunity Report",
        "uri": "/immunity",
        "idx": 21
      }
    ],
    "arizona.edu:services:enterprise:ctrds:rotc-compliance": [
      {
        "menu_item": "ROTC Compliance CSV",
        "uri": "/rotc-compliance",
        "idx": 22
      }
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:neg-results-users": [
      {
        "menu_item": "Off-Campus Negative Results Review",
        "uri": "/neg-result-admin",
        "idx": 23
      },
    ],
    "arizona.edu:services:enterprise:ctrds:breakthru-rpt": [
      {
        "menu_item": "Breakthrough Infections/Reinfections",
        "uri": "/breakthrough",
        "idx": 24
      },
    ],
    "arizona.edu:services:enterprise:ctrds:student-vaccinations-rpt": [
      {
        "menu_item": "Student Vaccination Report",
        "uri": "/student-vaccination-report",
        "idx": 25
      },
    ],
    "arizona.edu:services:enterprise:ctrds:stud-athlete-vax-rpt": [
      {
        "menu_item": "Athletics Vaccination Report",
        "uri": "/athletics-vaccination",
        "idx": 26
      },
    ],
    "arizona.edu:services:enterprise:ctrds:tats-running-total": [
      {
        "menu_item": "TATS Test Tracking",
        "uri": "/test-tracking",
        "idx": 27
      },
    ],
    "arizona.edu:services:enterprise:ctrds:inc-samples-rpt": [
      {
        "menu_item": "TATS Incomplete Samples",
        "uri": "/incomplete",
        "idx": 28
      },
    ],
    "arizona.edu:services:enterprise:ctrds:dorm-iso-rpt": [
      {
        "menu_item": "Isolation Testing Report",
        "uri": "/isolation",
        "idx": 29
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:allowed-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 30
      },
    ],
    "arizona.edu:services:enterprise:ctrds:offcampus-results:housing-users": [
      {
        "menu_item": "Off-Campus Positive Uploads",
        "uri": "/offcampus",
        "idx": 31
      },
    ],
  }
};

const configMap = {
  'production': prod,
  'testing': test,
  'development': dev
};

const config = configMap[process.env.REACT_APP_STAGE];

export default {
  apiGateway: config.apiGateway,
  apiGatewayStack2: config.apiGatewayStack2,
  apiGatewayStack3: config.apiGatewayStack3,
  cognito: config.cognito,
  admin: config.admin,
  pcradmin: config.pcradmin,
  submenu: config.submenu
};