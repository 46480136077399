import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import { testingLocationOptions } from '../components/NegResultChoices';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import "../css/FixedTableHeader.css";
import "../css/Home.css";

const statusClassMap = {
  'OPEN': 'text-azurite',
  'VERIFIED': 'text-leaf',
  'NOT VERIFIED': 'text-chili'
};

export default class NegResultAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false,
      addResult: false,
      status: null,
      reviewRequest: {},
      isReview: false,
      reviewSubmit: false,
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.getResults = this.getResults.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.resolveRequest = this.resolveRequest.bind(this);
    this.prepReviewData = this.prepReviewData.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;

    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:offcampus-results:neg-results-users',
    ];

    if (this.props.groups !== prevProps.groups) {
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        await this.getResults(this.props.groups);
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // prepare info for request review form
  prepReviewData(id, curStatus, newStatus, name, netid, resultDate, collectionDate, testingLocation, reqTime, lastUpdateTime, lastUpdateNetid) {
    this.setState({
      reviewRequest: {
        id: id,
        curStatus: curStatus,
        newStatus: newStatus,
        name: name,
        netid: netid,
        resultDate: resultDate,
        collectionDate: collectionDate,
        testingLocation: testingLocation,
        reqTime: reqTime,
        lastUpdateTime: lastUpdateTime,
        lastUpdateNetid: lastUpdateNetid
      },
      status: newStatus,
      isReview: true
    });
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({ isLoading: true}, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          }
        }
        return API.get("offCampusNegativeResultList", "/offCampusNegativeResultList", myInit)
        .then(res => {
          if (res.results.length === 0) {
            this.setState({
              isLoading: false,
              noResults: true,
              results: []
            });
          } else {
            this.setState({
              isLoading: false,
              noResults: false,
              results: res.results
            });
          }
        });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }

  // get file data for request
  async getFiles(uuid) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        body: {
          uuid: uuid
        }
      }
      return API.post("offCampusNegativeResultListFiles", "/offCampusNegativeResultListFiles", myInit)
      .then(res => {
        this.setState({
          files: res.files,
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      this.setState({
        files: []
      });
    });
  }

  // resolve request
  async resolveRequest() {
    this.setState({
      reviewSubmit: true
    }, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          },
          body: {
            txid: this.state.reviewRequest.id,
            status: this.state.status,
            name: this.state.reviewRequest.name,
            netid: this.state.reviewRequest.netid
          }
        }
        return API.post("offCampusNegativeResultAdminUpdate", "/offCampusNegativeResultAdminUpdate", myInit)
        .then(res => {
          if (this.state.status === 'VERIFIED') {
            toast.success("Negative result verified");
          } else {
            toast.warning("Negative result NOT verified");
          }
          this.setState({
            status: null,
            reviewRequest: {},
            isReview: false,
            reviewSubmit: false,
            isLoading: true
          }, () => {
            this.getResults();
          });
        });
      }).catch(error => {
        console.log("Error: " + error);
        toast.error(error.response.data.errmsg);
        this.setState({
          status: null,
          reviewRequest: {},
          isReview: false,
          reviewSubmit: false
        });
      });
    });
  }

  // display files available for download
  async displayFiles(uuid) {
    await this.getFiles(uuid);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <div className="card-title">
              <h4>
                Files
              </h4>
              <h5><em>(Links expire after 30 minutes)</em></h5>
            </div>
            <table>
              <tbody>
                {
                  this.state.files.map((f, i) => (
                    <tr key={`file-${i}`}>
                      <td>
                        {f.filename}
                      </td>
                      <td>
                        <a href={f.url}><i className="ua-brand-download text-blue"></i></a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <button className="btn btn-primary" onClick={onClose}>CLOSE</button>
          </div>
        );
      }
    });
  }

  // display request review form
  renderReviewForm() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col col-md-8 cold-md-offset-2">
              <h2>Off-Campus Negative Results for Graduation Ceremonies</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h3>
                <strong>Review Off-Campus Negative Result</strong>
              </h3>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-6 col-md-offset-3">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Requester:</strong></td>
                    <td>{this.state.reviewRequest.name} ({this.state.reviewRequest.netid})</td>
                  </tr>
                  <tr>
                    <td><strong>Submitted Date:</strong></td>
                    <td>{moment(this.state.reviewRequest.reqTime).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Test Collection Date:</strong></td>
                    <td>{moment(this.state.reviewRequest.collectionDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Test Results Date:</strong></td>
                    <td>{moment(this.state.reviewRequest.resultDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Test Location:</strong></td>
                    <td>{testingLocationOptions.filter(opt => opt.value === this.state.reviewRequest.testingLocation)[0].display}</td>
                  </tr>
                  {
                    this.state.reviewRequest.lastUpdateTime &&
                    <tr>
                      <td><strong>Last Update Time:</strong></td>
                      <td>{moment(this.state.reviewRequest.lastUpdateTime).format('MMM D, YYYY h:mm A')}</td>
                    </tr>
                  }
                  {
                    this.state.reviewRequest.lastUpdateNetid &&
                    <tr>
                      <td><strong>Last Updated By:</strong></td>
                      <td>{this.state.reviewRequest.lastUpdateNetid}</td>
                    </tr>
                  }
                  <tr>
                    <td><strong>Current Status:</strong></td>
                    <td><span className={statusClassMap[this.state.reviewRequest.curStatus]}><strong>{this.state.reviewRequest.curStatus}</strong></span></td>
                  </tr>
                  <tr>
                    <td><strong>New Status:</strong></td>
                    <td><span className={statusClassMap[this.state.reviewRequest.newStatus]}><strong>{this.state.reviewRequest.newStatus}</strong></span></td>
                  </tr>
                </tbody>
              </table>
              <button 
                className="btn btn-default mr-2"
                disabled={this.state.reviewSubmit}
                onClick={
                  () => {
                    this.setState({
                      isReview: false,
                      status: null,
                      reviewRequest: {}
                    }) 
                  }
                }
              >
                Cancel
              </button>
              <button 
                className="btn btn-primary"
                disabled={this.state.reviewSubmit}
                onClick={this.resolveRequest}
              >SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Off-Campus Negative Results for Graduation Ceremonies</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to review negative result uploads</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Off-Campus Negative Results for Graduation Ceremonies</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Off-Campus Negative Results for Graduation Ceremonies</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Off-Campus Negative Results for Graduation Ceremonies</h2>
              <h3>There are no negative result uploads.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResultsList() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-md-8 margin-align-middle">
              <p className="text-size-h2 margin-zero-top margin-zero-bottom" style={{ fontFamily: '"PT Serif", serif', color: '#49595e'}}>Off-Campus Negative Results for Graduation Ceremonies&nbsp;&nbsp;
                <button type="button" className="btn btn-sm btn-primary margin-zero-top margin-zero-bottom" onClick={() => { this.getResults(); }}>
                  <i className="btn-icon ua-brand-refresh">&nbsp;</i>
                </button>
              </p>
            </div>
          </div>
          <div className="callout callout-info">
            <span className="text-size-h3">Testing Locations Legend</span>
            <br/><br/>
            <ul>
              {
                testingLocationOptions.filter(i => i.value !== '').map(i => <li><strong>{i.value}</strong>: {i.display}</li>)
              }
            </ul>
          </div>
          <div className="row">
            <div className="col col-md-12">
              <div>
                <table className="table-striped table-dark table-hover">
                  <thead>
                      <tr>
                        <th className="fixed-header">#</th>
                        <th className="fixed-header">Status</th>
                        <th className="fixed-header">Submission Time</th>
                        <th className="fixed-header">Last Update Time</th>
                        <th className="fixed-header">Last Updated By</th>
                        <th className="fixed-header">Name</th>
                        <th className="fixed-header">EMPLID</th>
                        <th className="fixed-header">NetID</th>
                        <th className="fixed-header">Phone</th>
                        <th className="fixed-header">Affiliation</th>
                        <th className="fixed-header">Test Location</th>
                        <th className="fixed-header">Test Type</th>
                        <th className="fixed-header">Test Result</th>
                        <th className="fixed-header">Collection Date</th>
                        <th className="fixed-header">Result Date</th>
                        <th className="fixed-header">Files</th>
                        <th className="fixed-header">Review</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.results.map((r, i) => (
                        <tr key={r.id}>
                          <td>{i+1}</td>
                          <td><strong><span className={statusClassMap[r.status]}>{r.status}</span></strong></td>
                          <td>{r.entry_timestamp}</td>
                          <td>{r.last_update_timestamp}</td>
                          <td>{r.last_update_netid}</td>
                          <td>{r.name}</td>
                          <td>{r.emplid}</td>
                          <td>{r.netid}</td>
                          <td>{r.phone}</td>
                          <td>{r.affil}</td>
                          <td>{r.testing_location}</td>
                          <td>{r.test_type}</td>
                          <td>{r.test_result}</td>
                          <td>{r.collection_date}</td>
                          <td>{r.result_date}</td>
                          <td><center><a onClick={() => this.displayFiles(r.txid)}><i className="ua-brand-download text-chili"></i></a></center></td>
                          <td>
                            <div className="btn-group">
                              <button type="button" className="btn btn-hollow-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Options <span className="caret"></span>
                              </button>
                              <ul className="dropdown-menu">
                                { r.status !== 'VERIFIED' && <li><a onClick={ () => this.prepReviewData(r.txid, r.status, 'VERIFIED', r.name, r.netid, r.result_date, r.collection_date, r.testing_location, r.entry_timestamp, r.last_update_timestamp, r.last_update_netid) }>VERIFIED</a></li> }
                                { r.status !== 'NOT VERIFIED' && <li><a onClick={ () => this.prepReviewData(r.txid, r.status, 'NOT VERIFIED', r.name, r.netid, r.result_date, r.collection_date, r.testing_location, r.entry_timestamp, r.last_update_timestamp, r.last_update_netid) }> NOT VERIFIED</a></li> }
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && !this.state.isReview)) && this.renderResultsList()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.isReview)) && this.renderReviewForm()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
