import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CSVReader from 'react-csv-reader';
import CustomOAuthButton from '../CustomOAuthButton';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter, selectFilter } from 'react-bootstrap-table2-filter';

import "../css/Home.css";

export default class CampusHealthUpload extends Component {
  constructor(props) {
    super(props);
    this.postResults=this.postResults.bind(this);
    this.completeUpload=this.completeUpload.bind(this);
    this.renderUploadErrors=this.renderUploadErrors.bind(this);
    this.getRecords=this.getRecords.bind(this);
    this.closeRecordView=this.closeRecordView.bind(this);
    this.csvErrors=this.csvErrors.bind(this);
    this.state = {
      isLoading: false,
      error: false,
      success: false,
      grpsOk: false,
      complete: false,
      showRecords: false,
      records: [],
      errDetail: [],
      stats: null
    };
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:pos-results-dump-all'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getPosResults from componentDidUpdate");
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getPosResults from componentDidMount");
    // await this.getPosResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // handle errors from CSVReader component
  csvErrors(error) {
    this.setState({
      success: false,
      error: true,
      isLoading: false
    });
  }

  // download JSON data from API endpoint
  async getRecords() {
    return Auth.currentSession().then(session => {
      this.setState({isLoading: true},
        () => {
          const token = session.idToken.jwtToken;
          let myInit = { // OPTIONAL
            headers: {
              Authorization: token
            }
          }
          return API.get("campusHealthResultsDump", "/campusHealthResultsDump", myInit)
          .then(res => {
            if (res.results.length === 0) {
              this.setState({
                records: [],
                isLoading: false
              });
            } else {
              this.setState({
                records: res.results,
                isLoading: false,
                showRecords: true
              });
            }
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // upload JSON data to API endpoint
  async postResults(data, fileInfo) {
    return Auth.currentSession().then(session => {
      this.setState({
        success: false,
        error: false,
        isLoading: true
      });
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          results: data
        }
      }
      return API.post("campusHealthUploadStage", "/campusHealthUploadStage", myInit)
      .then(res => {
        console.log("Success from campusHealthUploadStage");
        console.log("res = " + JSON.stringify(res));
        this.setState({
          success: true,
          error: false,
          stats: res
        });
      }).catch(error => {
        console.log("Error in campusHealthUploadStage" + error);
        this.setState({
          success: false,
          error: true,
        });
        if (error.response.status === 400) {
          this.setState({
            errDetail: error.response.data.details,
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  // complete upload
  async completeUpload(stage_file) {
    return Auth.currentSession().then(session => {
      this.setState({
        isLoading: true
      });
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          stage_file: stage_file
        }
      }
      return API.post("campusHealthUploadComplete", "/campusHealthUploadComplete", myInit)
      .then(res => {
        console.log("Success from campusHealthCompleteUpload");
        this.setState({
          complete: true
        });
      }).catch(error => {
        console.log("Error in campusHealthCompleteUpload: " + error);
        this.setState({
          success: false,
          error: true,
        });
        if (error.response.status === 400) {
          this.setState({
            errDetail: error.response.data.details,
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  closeRecordView() {
    this.setState({showRecords: false});
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
         <CustomOAuthButton variant="primary" size="lg">Login to Upload Campus Health Test Results</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Campus Health Test Results</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUploadComplete() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-success" role="alert">Upload completed successfully.</div>
      </div>
    );
  }

  renderUploadErrors() {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Your upload was rejected due to one or more errors. Please correct and resubmit.</strong>
        <ul>
        { 
          this.state.errDetail.map((err, i) => (
            <li key={i}>
            { !this.state.success && 
              <>
                <span className="label label-primary">row {err.rownum}</span>
                &nbsp;
              </> 
            }
            {err.errmsg}
            </li>
          ))
        }
        </ul>
      </div>
    );
  }

  renderRecords() {
    const testTypeOptions = {
      'Antigen': 'Antigen',
      'PCR': 'PCR'
    };

    const testResultOptions = {
      'Positive': 'Positive',
      'Negative': 'Negative',
      'Inconclusive': 'Inconclusive',
      'Invalid': 'Invalid'
    };

    const affiliationOptions = {
      'Student': 'Student',
      'Employee': 'Employee',
      'Unknown': 'Unknown'
    };

    const dormSelectOptions = {
      'Arbol de la Vida': 'Arbol de la Vida',
      'Arizona-Sonora': 'Arizona-Sonora',
      'Cochise': 'Cochise',
      'Colonia de la Paz': 'Colonia de la Paz',
      'Coronado': 'Coronado',
      'Gila': 'Gila',
      'Graham-Greenlee': 'Graham-Greenlee',
      'Honors Village': 'Honors Village',
      'Hopi': 'Hopi',
      'La Aldea Graduate Hall': 'La Aldea Graduate Hall',
      'Likins': 'Likins',
      'Manzanita-Mohave': 'Manzanita-Mohave',
      'Maricopa': 'Maricopa',
      'Navajo-Pinal': 'Navajo-Pinal',
      'Pima': 'Pima',
      'Posada San Pedro': 'Posada San Pedro',
      'Pueblo de la Cienega': 'Pueblo de la Cienega',
      'Villa Del Puente': 'Villa Del Puente',
      'Yavapai': 'Yavapai',
      'Yuma': 'Yuma'
    };

    let columns = [
      {
        dataField: 'id',
        text: "ID",
        sort: true,
        headerStyle: {width: '50px'}
      },
      {
        dataField: 'netid',
        text: 'NetID',
        filter: textFilter(),
        formatter: this.netidCellFormatter,
        sort: true
      },
      {
        dataField: 'affiliation',
        text: 'Affiliation',
        formatter: cell => affiliationOptions[cell],
        filter: selectFilter({
          options: affiliationOptions
        }),
        sort: false
      },
      {
        dataField: 'test_type',
        text: 'Test Type',
        sort: false,
        formatter: cell => testTypeOptions[cell],
        filter: selectFilter({
          options: testTypeOptions
        })
      },
      {
        dataField: 'test_result',
        text: 'Test Result',
        formatter: cell => testResultOptions[cell],
        filter: selectFilter({
          options: testResultOptions
        })
      },
      {
        dataField: 'collection_timestamp',
        text: 'Collection Date/Time',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'result_timestamp',
        text: 'Result Date/Time',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'dorm_name',
        text: 'Dorm',
        formatter: cell => dormSelectOptions[cell],
        filter: selectFilter({
          options: dormSelectOptions
        }),
        sort: true
      },
      {
        text: 'Street Address 1',
        dataField: 'address1',
        filter: textFilter(),
        sort: true
      },
      {
        text: 'Street Address 2',
        dataField: 'address2',
        filter: textFilter(),
        sort: true
      },
      {
        text: 'City/State/Zip',
        dataField: 'city_state_zip',
        filter: textFilter(),
        sort: true
      }
    ];
    const defaultSorted = [
      {
        dataField: 'id',
        order: 'desc'
      }
    ];
    return (
      <div>
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-md-12 d-flex align-items-center">
              <span className="h2">Campus Health Test Results</span>
              &nbsp;&nbsp;
              <button className={`btn btn-success`} onClick={this.closeRecordView}>Close</button>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              <BootstrapTable
                keyField='id'
                data={this.state.records}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={ filterFactory() }
                hover
                striped
                classes="table-responsive"
                bootstrap4
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCSVUpload() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
    };
    const statKeyMap = {
      numrows: 'Total rows',
      pcr_pos: 'Positive PCR results',
      pcr_neg: 'Negative PCR results',
      pcr_inc: 'Inconclusive PCR results',
      antigen_pos: 'Positive Antigen results',
      antigen_neg: 'Negative Antigen results',
      antigen_inc: 'Inconclusive Antigen results',
      stud_dorm: 'Student (On-Campus) results',
      stud_nondorm: 'Student (Off-Campus) results',
      employee: 'Employee results'
    };
    return(
      <div>
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-12 d-flex align-items-center">
              <span className="h2">Campus Health Test Results</span>
              {
                !this.state.success &&
                <>
                  &nbsp;&nbsp;
                  <button className={`btn btn-success`} onClick={this.getRecords}>View Records</button>
                </>
              }
            </div>
          </div>
          { this.state.success && 
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-success" role="alert">Your CSV upload is ready to be submitted. Please check confirm the information below and click <strong>Complete</strong> to complete your upload. If the information looks incorrect, click <strong>Cancel</strong> to cancel the upload and try again.</div>
                <div className="alert alert-info" role="alert">
                  <ul>
                  { 
                    Object.keys(this.state.stats).filter(key => key !== 'stage_file').map((key, idx) => {
                      console.log(key);
                      return (
                        <li key={idx}><span className="label label-primary">{statKeyMap[key]}</span>&nbsp;{this.state.stats[key]}</li>
                      )
                    })
                  }
                  </ul>
                </div>
                <div className="col-md-6 mt-1 mb-2">
                  <span className="pr-2"><button className={`btn btn-success`} onClick={() => this.completeUpload(this.state.stats.stage_file)}>Complete</button></span>
                  <span className="pl-2"><button className={`btn btn-default`} onClick={() => window.location.reload()}>Cancel</button></span>
                </div>
              </div>
            </div>
          }
          {this.state.error &&
            <div className="row">
              <div className="col-md-12">
                { this.renderUploadErrors() }
              </div>
            </div>
          }
      
          {/* <Button variant="primary"> */}
          { !this.state.success &&
            <div className="row">
              <div className="col-md-12">
                <CSVReader
                  label="Upload Results CSV"
                  onFileLoaded={this.postResults}
                  onError={this.csvErrors}
                  parserOptions={papaparseOptions}
                />
              </div>
            </div>
          }
          {/* </Button> */}
        </div>
      </div>    
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.showRecords)) && this.renderRecords()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.complete)) && this.renderCSVUpload()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.complete)) && this.renderUploadComplete()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
