import React, { Component } from "react";
import { Table, Card, Collapse, Button } from "react-bootstrap";
import CustomOAuthButton from '../CustomOAuthButton';
import { AuthenticatedLinkBase64 } from "../components/AuthenticatedLinkBase64";
import config from "../config";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import "../css/Home.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.resultsToggleCallback = this.resultsToggleCallback.bind(this);
    this.getCovidWatchCode = this.getCovidWatchCode.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.collapseAll = this.collapseAll.bind(this);
    this.state = {
      isLoading: false,
      noResults: false,
      isDormResident: false,
      resultsOpenState: {},
      covidWatchCodes: {},
      searchResults: []
    };
  }

  async componentWillReceiveProps(props) {
    if (this.props !== props) {
      // console.log("*** inside componentWillReceiveProps()");
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (props.authn !== 'signedIn') {
        return;
      }
      this.setState({ isLoading: true });
      // this.setState({ resultsOpenState: {} });
      this.setState({ covidWatchCodes: {} });
      console.log("*** calling refreshSearchResults from componentWilReceiveProps");
      await this.refreshSearchResults();
      this.setState({ isLoading: false });
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
    this.setState({ resultsOpenState: {} });
    this.setState({ covidWatchCodes: {} });
    this.setState({ isLoading: true });
    console.log("*** calling refreshSearchResults from componentDidMount");
    console.log("*** covidWatchCodes");
    console.log(this.state.covidWatchCodes);
    await this.refreshSearchResults();
    this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // grab test results from API gateway endpoint
  async refreshSearchResults() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("query", "/query", myInit)
        .then(res => {
          if (res.results.length === 0) {
            this.setState({
              noResults: true
            });
          } else {
            // let _resultsOpenState = {};
            // res.results.map(r => _resultsOpenState[r.testGuid] = false);
            // this.setState({ resultsOpenState: _resultsOpenState});
            // // console.log(this.state.resultsOpenState);
          }
          this.setState({
            searchResults: res.results,
            isDormResident: res.dorm_res
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // grab Covid Watch positive diagnosis code from API
  async getCovidWatchCode(barcode, guid) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          barcode: barcode
        }
      };
      return API.get(barcode.startsWith('TST') ? "covidwatchCodeTest" : "covidwatchCode", barcode.startsWith('TST') ? "/covidwatchCodeTest" : "/covidwatchCode", myInit)
        .then(res => {
          let _covidWatchCodes = { ...this.state.covidWatchCodes };
          _covidWatchCodes[guid] = res;
          this.setState({ covidWatchCodes: _covidWatchCodes });
          // AUDIT Covid Watch code generation event
          Auth.currentSession().then(session => {
            const token = session.idToken.jwtToken;
            let auditObj = {
              type: 'GET_CODE',
              detail: guid
            };
            let myInit = { // OPTIONAL
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              },
              body: auditObj
            };
            return API.post("audit", "/audit", myInit);
          }).catch(error => {
            console.log("Error in Auth.currentSession: " + error);
            return [];
          });
        }).catch(error => {
          console.log("Error getting CovidWatch code: " + error);
          let _covidWatchCodes = { ...this.state.covidWatchCodes };
          _covidWatchCodes[guid] = error.response.data;
          this.setState({ covidWatchCodes: _covidWatchCodes });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  resultsToggleCallback(resultId) {
    // console.log("resultId = " + resultId + " , activeResult = " + this.state.activeResult);
    let _resultsOpenState = { ...this.state.resultsOpenState };
    // console.log(_resultsOpenState);
    _resultsOpenState[resultId] = !_resultsOpenState[resultId];
    this.setState({ resultsOpenState: _resultsOpenState });
    if (_resultsOpenState[resultId]) {
      // console.log("onClick", resultId);
      // audit VIEW_RESULT event
      Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let auditObj = {
          type: 'VIEW_RESULT',
          detail: resultId
        };
        let myInit = { // OPTIONAL
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          },
          body: auditObj
        };
        return API.post("audit", "/audit", myInit);
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    }
  }

  renderSearchResultsList(results) {
    const resultsLabelMap = {
      'POSITIVE': 'red',
      'DETECTED (POSITIVE)': 'red',
      'INCONCLUSIVE (PRESUMED POSITIVE)': 'red',
      'NEGATIVE': 'leaf',
      'NOT DETECTED': 'leaf',
      'INDETERMINATE': 'azurite',
      'REJECTED': 'mesa',
      'INVALID': 'mesa',
      'INCONCLUSIVE': 'mesa',
      'BORDERLINE1': 'mesa',
      'BORDERLINE2': 'mesa',
      'IN PROCESS': 'sage'
    };

    return (
      results.map(
        (r) => (
          <Card border="primary" key={r.testGuid}>
            <Card.Header onClick={() => this.resultsToggleCallback(r.testGuid)}>
              {r.testType}{['PCR', 'Antigen', 'Antibody'].includes(r.testType) && " Virus"} &mdash; {r.dateSubmitted}
              <span className="pull-right">
                <Button variant="link" className="expand-btn"
                  aria-controls={"card-body-" + r.testGuid}
                  aria-expanded={this.state.resultsOpenState[r.testGuid]}>
                  {!this.state.resultsOpenState[r.testGuid] && <i className="ua-brand-down-arrow"></i>}
                  {this.state.resultsOpenState[r.testGuid] && <i className="ua-brand-up-arrow"></i>}
                </Button>
              </span>
            </Card.Header>
            <Collapse in={this.state.resultsOpenState[r.testGuid]}>
              <Card.Body id={"card-body-" + r.testGuid}>
                <Table striped>
                  <tbody>
                    <tr>
                      <th width="50%">Name</th>
                      <td width="50%">{r.name}</td>
                    </tr>
                    <tr>
                      <th width="50%">Date/Time Collected</th>
                      <td width="50%">{r.dateReceived}</td>
                    </tr>
                    <tr>
                      <th width="50%">Test Type</th>
                      <td width="50%">{r.testType.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <th width="50%">Result</th>
                      <td width="50%">
                        <span className={`text-${resultsLabelMap[r.testResult]}`}>
                          <strong>
                            {
                              ["BORDERLINE1", "BORDERLINE2"].includes(r.testResult) ?
                                r.testResult === "BORDERLINE1" ?
                                  `BORDERLINE POSITIVE — RETEST REQUIRED`
                                  :
                                  `2ND BORDERLINE POSITIVE — PCR TEST REQUIRED`
                                :
                                r.testResult
                            }
                          </strong>
                        </span>
                      </td>
                    </tr>
                    {
                      (r.testResult === "POSITIVE" || r.testResult === "DETECTED (POSITIVE)") && r.testType.toUpperCase() === "ANTIGEN" &&
                      <>
                        <tr>
                          <td colSpan={'2'} width="100%" className="bg-bloom text-white">
                            <a className={`text-white pos-info-link`} href={`https://health.arizona.edu/covidpositive`} target={`_blank`} rel={`noopener noreferrer`}>Next steps</a> regarding your positive test result.
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            Because of your positive test result, you will be eligible for another test on <strong>{this.state.isDormResident ? moment(r.dateReceived, 'MM/DD/YYYY').add((this.props.groups.length > 0 && this.props.groups.slice(1, -1).split(', ').includes('arizona.edu:services:enterprise:ctrds:dorm-ag-retest')) ? 6 : 11, 'days').format('MM/DD/YYYY') : moment(r.dateReceived, 'MM/DD/YYYY').add(6, 'days').format('MM/DD/YYYY')}</strong>, which is {this.state.isDormResident ? (this.props.groups.length > 0 && this.props.groups.slice(1, -1).split(', ').includes('arizona.edu:services:enterprise:ctrds:dorm-ag-retest') ? '6' : '11') : '6'} days from the date of your positive result.
                          </td>
                        </tr>
                      </>
                    }
                    {
                      (r.testResult === "POSITIVE" || r.testResult === "DETECTED (POSITIVE)") && r.testType.toUpperCase() === "PCR" &&
                      <>
                        <tr>
                          <td colSpan={'2'} width="100%" className="bg-bloom text-white">
                            <a className={`text-white pos-info-link`} href={`https://health.arizona.edu/covidpositive`} target={`_blank`} rel={`noopener noreferrer`}>Next steps</a> regarding your positive test result.
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            Because of your positive test result, you will be eligible for another test on <strong>{moment(r.dateReceived, 'MM/DD/YYYY').add(60, 'days').format('MM/DD/YYYY')}</strong>, which is 60 days from the date of your positive result.
                          </td>
                        </tr>
                      </>
                    }
                    {
                      ["POSITIVE", "NEGATIVE", "INDETERMINATE"].includes(r.testResult) && r.testType.toUpperCase() === "ANTIBODY" &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          <strong>The result provided here is for informational purposes and does not represent a diagnosis or medical advice.</strong> If you have questions or concerns about your test result, please consult with your primary physician or health care provider.<br /><br />
                          For further information on your test results, please <a href={`https://ctrds-test-results-documents.s3-us-west-2.amazonaws.com/Test_Result_Scripts_AB_${r.testResult}.pdf`} target={`_blank`} rel={`noopener noreferrer`}>click here</a>.
                        </td>
                      </tr>
                    }
                    {
                      ["NEGATIVE"].includes(r.testResult) && r.testType.toUpperCase() === "ANTIGEN" &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          For further information on your test results, please <a href={`https://ctrds-test-results-documents.s3-us-west-2.amazonaws.com/Test_Result_Scripts_AG_${r.testResult}.pdf`} target={`_blank`} rel={`noopener noreferrer`}>click here</a>.
                        </td>
                      </tr>
                    }
                    {
                      r.testResult === "INVALID" && r.testType.toUpperCase() === "PCR" &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          An invalid test means there was a problem with the sample, and a result could not be generated. You will need to be retested. Visit <a href="https://covid19.arizona.edu/covid19-testing" rel="noopener noreferrer" target="_blank">https://covid19.arizona.edu/covid19-testing</a> to learn more about on-campus testing options. Questions? Contact the COVID Ambassador Team (CAT) Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.
                        </td>
                      </tr>
                    }
                    {
                      ["NOT DETECTED"].includes(r.testResult) && r.testType.toUpperCase() === "PCR" &&
                      <>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            Please <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/pcrLetterDownload?barcode=" + r.barcode} filename={`TATS_${r.testType.toUpperCase()}_results_${r.dateSubmitted.replace(/\//g, '-')}`} >click here</AuthenticatedLinkBase64> to download your PCR test report.
                          </td>
                        </tr>
                        {
                          r.barcode.startsWith('UA89-') ?
                            <tr>
                              <td colSpan={'2'} width="100%">
                                <strong>NOTE</strong>: This document may <strong>not</strong> be used for travel or other official purposes.
                              </td>
                            </tr>
                            :
                            <tr>
                              <td colSpan={'2'} width="100%">
                                <strong>NOTE</strong>: This document may be used for <strong>travel or other official purposes</strong>. As such, it lists your legal name for verification with your driver's license, passport, or other identification documents.
                              </td>
                            </tr>
                        }
                      </>
                    }
                    {
                      ["DETECTED (POSITIVE)", "INCONCLUSIVE (PRESUMED POSITIVE)", "INCONCLUSIVE", "INVALID"].includes(r.testResult) && r.testType.toUpperCase() === "PCR" &&
                      <>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            Please <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/pcrLetterDownload?barcode=" + r.barcode} filename={`TATS_${r.testType.toUpperCase()}_results_${r.dateSubmitted.replace(/\//g, '-')}`} >click here</AuthenticatedLinkBase64> to download your PCR test report.
                          </td>
                        </tr>
                        {
                          r.barcode.startsWith('UA89-') ?
                            <tr>
                              <td colSpan={'2'} width="100%">
                                <strong>NOTE</strong>: This document may <strong>not</strong> be used for travel or other official purposes.
                              </td>
                            </tr>
                            :
                            <tr>
                              <td colSpan={'2'} width="100%">
                                <strong>NOTE</strong>: This document may be used for <strong>travel or other official purposes</strong>. As such, it lists your legal name for verification with your driver's license, passport, or other identification documents.
                              </td>
                            </tr>
                        }
                      </>
                    }
                    {
                      ["POSITIVE"].includes(r.testResult) && r.testType.toUpperCase() === "ANTIGEN" &&
                      <>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            Please <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/agLetterDownload?barcode=" + r.barcode} filename={`TATS_${r.testType.toUpperCase()}_results_${r.dateSubmitted.replace(/\//g, '-')}`} >click here</AuthenticatedLinkBase64> to download your Antigen test report.
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={'2'} width="100%">
                            <strong>NOTE</strong>: This document may be used for <strong>medical or other official purposes</strong>. As such, it lists your legal name for verification with your driver's license, passport, or other identification documents.
                          </td>
                        </tr>
                      </>
                    }
                    {
                      r.testResult === "REJECTED" && ["ANTIBODY", "ANTIGEN"].includes(r.testType.toUpperCase()) &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          A rejected test means there was a problem with the sample and a result could not be generated. For further instructions on how to be retested, please contact the COVID Ambassador Team (CAT) Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.
                        </td>
                      </tr>
                    }
                    {
                      r.testResult === "INVALID" && r.testType.toUpperCase() === "ANTIGEN" &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          An invalid test means there was a problem with the sample, and a result could not be generated. You will need to be retested. Visit <a href="https://covid19.arizona.edu/covid19-testing" rel="noopener noreferrer" target="_blank">https://covid19.arizona.edu/covid19-testing</a> to learn more about on-campus testing options. Questions? Contact the COVID Ambassador Team (CAT) Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.
                        </td>
                      </tr>
                    }
                    {
                      ["BORDERLINE1", "BORDERLINE2"].includes(r.testResult) && r.testType.toUpperCase() === "ANTIGEN" &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          For further information on your test results, please <a href={`https://ctrds-test-results-documents.s3-us-west-2.amazonaws.com/Test_Result_Scripts_AG_${r.testResult}.pdf`} target={`_blank`} rel={`noopener noreferrer`}>click here</a>.
                        </td>
                      </tr>
                    }
                    {
                      ["POSITIVE", "DETECTED (POSITIVE)"].includes(r.testResult) &&
                      r.testType.toUpperCase() !== 'ANTIBODY' &&
                      typeof (r.covidwatchCode) === "undefined" &&
                      !this.state.covidWatchCodes.hasOwnProperty(r.testGuid) &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> Positive Diagnosis Verification Code&nbsp;&nbsp;<button className={`btn btn-primary btn-sm`} onClick={() => this.getCovidWatchCode(r.barcode, r.testGuid)}>Get Code</button>
                        </td>
                      </tr>
                    }
                    {
                      ["POSITIVE", "DETECTED (POSITIVE)"].includes(r.testResult) &&
                      r.testType.toUpperCase() !== 'ANTIBODY' &&
                      typeof (r.covidwatchCode) === "undefined" &&
                      this.state.covidWatchCodes.hasOwnProperty(r.testGuid) &&
                      !this.state.covidWatchCodes[r.testGuid].hasOwnProperty('error') &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          <div>
                            Your <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> code is:&nbsp;
                            <span className={`pwdisplay`}>{this.state.covidWatchCodes[r.testGuid].code}</span>
                            &nbsp;
                            <CopyToClipboard text={this.state.covidWatchCodes[r.testGuid].code}>
                              <button type={`button`} className={`btn-sm btn btn-primary`}>Copy</button>
                            </CopyToClipboard>
                            . It expires in <strong>1 HOUR</strong> ({this.state.covidWatchCodes[r.testGuid].expires}).
                          </div>
                          <div className="mt-2">Help stop the spread by entering the code in the <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> mobile app.</div>
                        </td>
                      </tr>
                    }
                    {
                      ["POSITIVE", "DETECTED (POSITIVE)"].includes(r.testResult) &&
                      r.testType.toUpperCase() !== 'ANTIBODY' &&
                      typeof (r.covidwatchCode) === "undefined" &&
                      this.state.covidWatchCodes.hasOwnProperty(r.testGuid) &&
                      this.state.covidWatchCodes[r.testGuid].hasOwnProperty('error') &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          We encountered an error creating your <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> code. Please refresh this page and try again, or contact Campus Health at (520) 621-9202 to obtain a code.
                        </td>
                      </tr>
                    }
                    {
                      ["POSITIVE", "DETECTED (POSITIVE)"].includes(r.testResult) &&
                      r.testType.toUpperCase() !== 'ANTIBODY' &&
                      typeof (r.covidwatchCode) !== "undefined" &&
                      r.covidwatchCode !== "too-old" &&
                      r.hasOwnProperty('covidwatchCodeExpiration') &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          <div>
                            Your <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> code is:&nbsp;
                            <span className={`pwdisplay`}>{r.covidwatchCode}</span>
                            &nbsp;
                            <CopyToClipboard text={r.covidwatchCode}>
                              <button type={`button`} className={`btn-sm btn btn-primary`}>Copy</button>
                            </CopyToClipboard>
                            . It expires in <strong>1 HOUR</strong> ({r.covidwatchCodeExpiration}).
                          </div>
                          <div className="mt-2">Help stop the spread by entering the code in the <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> mobile app.</div>
                        </td>
                      </tr>
                    }
                    {
                      ["POSITIVE", "DETECTED (POSITIVE)"].includes(r.testResult) &&
                      r.testType.toUpperCase() !== 'ANTIBODY' &&
                      typeof (r.covidwatchCode) !== "undefined" &&
                      r.covidwatchCode !== "too-old" &&
                      !r.hasOwnProperty('covidwatchCodeExpiration') &&
                      <tr>
                        <td colSpan={'2'} width="100%">
                          You previously generated a <a target={`_blank`} rel={`noopener noreferrer`} href={`https://covid19.arizona.edu/covidwatch`}>Covid Watch</a> code and it has expired. Request a new code by contacting Campus Health at (520) 621-9202.
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Collapse>
          </Card>
        ))
    );
  }


  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <CustomOAuthButton variant="primary" size="lg">Login to View Your Results</CustomOAuthButton>
      </div>
    );
  }

  expandAll() {
    let _resultsOpenState = { ...this.state.resultsOpenState };

    Object.keys(_resultsOpenState).forEach((key) => { _resultsOpenState[key] = true; this.resultsToggleCallback(key) })

    this.setState({ resultsOpenState: _resultsOpenState });
  }

  collapseAll() {
    let _resultsOpenState = { ...this.state.resultsOpenState };

    Object.keys(_resultsOpenState).forEach((key) => { _resultsOpenState[key] = false })

    this.setState({ resultsOpenState: _resultsOpenState });

  }

  renderSearchResults() {
    // console.log("*** in renderSearchResults");
    return (
      <div className="searchResults">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Your Test Results</h2>
              {/* <div className="alert alert-warning" role="alert">If you receive a POSITIVE test result for any test, please visit <a target="_blank" href="https://health.arizona.edu/healthalerts">https://health.arizona.edu/healthalerts</a> for instructions.</div> */}
              <div className="alert alert-warning" role="alert">In order to preserve your privacy, please logout when done viewing results.</div>
              <div className="results">
                <Button variant="link" onClick={this.expandAll}>Expand All</Button> | <Button variant="link" onClick={this.collapseAll}>Collapse All</Button>
                {this.renderSearchResultsList(this.state.searchResults)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-info" role="alert">If your test was completed at Campus Health, please visit <a href="https://health.arizona.edu/patientlink">https://health.arizona.edu/patientlink</a> for your results.</div>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Your Test Results</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Your Test Results</h2>
              <h3>There are no test results reported for you at this time. Please check back later.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.authz && this.state.searchResults.length === 0 && !this.state.noResults) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && this.props.authz && this.state.searchResults.length === 0 && this.state.noResults) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && this.props.authz && this.state.searchResults.length > 0) && this.renderSearchResults()}
        {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
