import React, { Component } from "react";
import logo from '../images/housing_hdr.png';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class HousingIsolationRetestMsg extends Component {

  renderLander() {
    return (
      <div className="lander">
        <h1>Important Message from Housing &amp; Residential Life</h1>
        <p>Please log into this secure site to read an important message from Housing &amp; Residential Life.</p>
        <CustomOAuthButton variant="primary" size="lg">Login</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderMessage() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-xs-8 col-sm-4 my-2">
            <a href="https://housing.arizona.edu/" title="Housing & Residential Life" target="_blank" rel="noopener noreferrer" className="remove-external-link-icon"><img src={logo} style={{ maxWidth: '300px' }} alt="Housing & Residential Life" /></a>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 col-xs-12">
            <p>
              Greetings,
            </p>
            <p>
              As a campus housing resident who has completed at least five days of isolation, you are now eligible to test out of isolation and return to class and resume daily activities if you have been fever-free for at least 24 hours and your symptoms are improving (or you are asymptomatic).
            </p>
            <p>
              If your symptoms are not improving right now, you can wait until a later day to complete the attestation form and take a test.
            </p>
            <p>
              <strong>To test out of isolation, follow these steps:</strong>
              <ol>
                <li>Visit <a href="https://testing.wellcheck.arizona.edu/dormretest" target="_blank" rel="noopener noreferrer">https://testing.wellcheck.arizona.edu/dormretest</a> and log in using NetID.</li>
                <li><strong>Review and complete the attestation form in order to be eligible for testing.</strong></li>
                <li>Once you have completed the form, click &quot;Next&quot; to schedule your rapid antigen test at Test All Test Smart located at Campus Health - Second Floor in the Meder Room B207.<br/><strong>Note:</strong> Testing is available Monday - Friday.</li>
              </ol>
            </p>
            <p>
              <span style={{ textDecoration: 'underline' }}><strong>If you test negative:</strong></span> You may return to class and resume daily activities. Continue to wear a mask through Day 10.
            </p>
            <p>
              <span style={{ textDecoration: 'underline' }}><strong>If you test positive:</strong></span> You must continue to isolate in place or isolate off campus until Day 10. You will not be able to take another antigen test through Test All Test Smart during this time.
            </p>
            <p>
              <strong>Details:</strong>
              <ul>
                <li>Day 0 is the date your sample was collected for your positive test.</li>
                <li>You may attempt to test out of isolation starting on Day 6.</li>
              </ul>
            </p>
            <p>
              Read more: <a href="https://housing.arizona.edu/isolation" target="_blank" rel="noopener noreferrer">Isolating On Campus</a> (Housing &amp; Residential Life)
              <br/>
              <a href="https://health.arizona.edu/sites/default/files/CHS%20COVID-19%20Isolation%20Steps%20Flier%20PROOF%2002%20%28002%29.pdf" target="_blank" rel="noopener noreferrer">Isolation &amp; Retesting Calendar</a> (Campus Health)
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.authz) && this.renderMessage()}
        {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }

}
