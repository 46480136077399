import React, { Component } from "react";

import "../css/Home.css";

export default class NegResultsInactive extends Component {
  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>Negative Result Upload for Graduation Ceremonies</h1>
          <div className="container">
            <div className="row mb-4">
              <div className="col col-md-8 col-md-offset-2">
                <div className="callout callout-warning" style={{textAlign: 'left'}}>
                  Off-campus negative results are no longer being accepted. You are welcome to take a COVID-19 test on campus. Please view the <a href="https://covid19.arizona.edu/covid19-testing/locations">locations and hours of testing sites</a>.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
