import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CSVReader from 'react-csv-reader';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class VaccineRecords extends Component {
  constructor(props) {
    super(props);
    this.postResults=this.postResults.bind(this);
    this.completeUpload=this.completeUpload.bind(this);
    this.renderUploadErrors=this.renderUploadErrors.bind(this);
    this.csvErrors=this.csvErrors.bind(this);
    this.state = {
      isLoading: false,
      error: false,
      success: false,
      grpsOk: false,
      complete: false,
      errDetail: [],
      stats: null
    };
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:vaccinations-upload'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getPosResults from componentDidUpdate");
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getPosResults from componentDidMount");
    // await this.getPosResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // handle errors from CSVReader component
  csvErrors(error) {
    this.setState({
      success: false,
      error: true,
      isLoading: false
    });
  }

  // upload JSON data to API endpoint
  async postResults(data, fileInfo) {
    return Auth.currentSession().then(session => {
      this.setState({
        success: false,
        error: false,
        isLoading: true
      });
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          results: data
        }
      }
      return API.post("vaccinationsUploadStage", "/vaccinationsUploadStage", myInit)
      .then(res => {
        console.log("Success from campusHealthUploadStage");
        console.log("res = " + JSON.stringify(res));
        this.setState({
          success: true,
          error: false,
          stats: res
        });
      }).catch(error => {
        console.log("Error in vaccinationsUploadStage" + error);
        this.setState({
          success: false,
          error: true,
        });
        if (error.response.status === 400) {
          this.setState({
            errDetail: error.response.data.details,
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  // complete upload
  async completeUpload(stage_file) {
    return Auth.currentSession().then(session => {
      this.setState({
        isLoading: true
      });
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          stage_file: stage_file
        }
      }
      return API.post("vaccinationsUploadComplete", "/vaccinationsUploadComplete", myInit)
      .then(res => {
        console.log("Success from vaccinationsUploadComplete");
        this.setState({
          complete: true
        });
      }).catch(error => {
        console.log("Error in campusHealthCompleteUpload: " + error);
        this.setState({
          success: false,
          error: true,
        });
        if (error.response.status === 400) {
          this.setState({
            errDetail: error.response.data.details,
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Campus Health Vaccine Records</h1>
         <CustomOAuthButton variant="primary" size="lg">Login to Campus Health Vaccine Records</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Campus Health Vaccine Records</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Campus Health Vaccine Records</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUploadComplete() {
    return (
      <div className="lander">
        <h1>Campus Health Vaccine Records</h1>
        <div className="alert alert-success" role="alert">Upload completed successfully.</div>
      </div>
    );
  }

  renderUploadErrors() {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Your upload was rejected due to one or more errors. Please correct and resubmit.</strong>
        <ul>
        { 
          this.state.errDetail.map((err, i) => (
            <li key={i}>
            { !this.state.success && 
              <>
                <span className="label label-primary">row {err.rownum}</span>
                &nbsp;
              </> 
            }
            {err.errmsg}
            </li>
          ))
        }
        </ul>
      </div>
    );
  }

  renderCSVUpload() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
    };
    const statKeyMap = {
      numrows: 'Total rows',
      duplicates: 'Ignored duplicates'
    };
    return(
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Campus Health Vaccine Records</h2>
              { this.state.success && 
                <>
                  <div className="alert alert-success" role="alert">Your CSV upload is ready to be submitted. Please check confirm the information below and click <strong>Complete</strong> to complete your upload. If the information looks incorrect, click <strong>Cancel</strong> to cancel the upload and try again.</div>
                  <div className="alert alert-info" role="alert">
                    <ul>
                    { 
                      Object.keys(this.state.stats).filter(key => key !== 'stage_file').map((key, idx) => {
                        console.log(key);
                        return (
                          <li key={idx}><span className="label label-primary">{statKeyMap[key]}</span>&nbsp;{this.state.stats[key]}</li>
                        )
                      })
                    }
                    </ul>
                  </div>
                  <div className="col-md-6 mt-1 mb-2">
                    <span className="pr-2"><button className={`btn btn-success`} onClick={() => this.completeUpload(this.state.stats.stage_file)}>Complete</button></span>
                    <span className="pl-2"><button className={`btn btn-default`} onClick={() => window.location.reload()}>Cancel</button></span>
                  </div>
                </>
              }
              {this.state.error && this.renderUploadErrors()}

              {/* <Button variant="primary"> */}
              { !this.state.success &&
                <CSVReader
                  label="Upload Vaccine Records CSV"
                  onFileLoaded={this.postResults}
                  onError={this.csvErrors}
                  parserOptions={papaparseOptions}
                />
              }
              {/* </Button> */}
            </div>
          </div>
        </div>
      </div>    
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.complete)) && this.renderCSVUpload()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.complete)) && this.renderUploadComplete()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
