import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
// import Csv from "./containers/Csv";
import PosResults from "./containers/PosResults";
import Safer from "./containers/Safer";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import ExportAdmin from "./containers/ExportAdmin";
import ExportAdminPcr from "./containers/ExportAdminPcr";
import Search from "./containers/Search";
import PcrUpload from "./containers/PcrUpload";
import GroupResults from "./containers/GroupResults";
import CampusHealthUpload from "./containers/CampusHealthUpload";
import Map from "./containers/Map";
// import LabelPrint from "./containers/LabelPrint";
import DormCompliance from "./containers/DormCompliance";
import TarResults from "./containers/TarResults";
import PascResults from "./containers/PascResults";
import PascCsv from "./containers/PascCsv";
import DormTestResults from "./containers/DormTestResults";
import Pac12Results from "./containers/Pac12Results";
import OffCampus from "./containers/OffCampus";
import CheckIn from "./containers/CheckIn";
import CheckInTest from "./containers/CheckInTest";
import CheckInStats from "./containers/CheckInStats";
import SampleInvalidate from "./containers/SampleInvalidate";
import Accommodations from "./containers/Accommodations";
import StudentCompliance from "./containers/StudentCompliance";
import VaccineRecords from "./containers/VaccineRecords";
import VaccineExemptionReport from "./containers/VaccineExemptionReport";
import SaferCcn from "./containers/SaferCcn";
// import WifiBlockStats from "./containers/WifiBlockStats";
import ImmunityReport from "./containers/ImmunityReport";
import ModernaTrial from "./containers/ModernaTrial";
import RotcCompliance from "./containers/RotcCompliance";
import EventWidget from "./containers/EventWidget";
import NegResultsInactive from "./containers/NegResultsInactive";
import NegResultAdmin from "./containers/NegResultAdmin";
import BreakthruReinfections from "./containers/BreakthruReinfections";
import StudentVaccinationReport from "./containers/StudentVaccinationReport";
import StudentAthletesVaccinationReport from "./containers/StudentAthletesVaccinationReport";
import TestTracking from "./containers/TestTracking";
import IncompleteSamples from "./containers/IncompleteSamples";
import CampusHealthPosMsg from "./containers/CampusHealthPosMsg";
import DormIsolationRetestRpt from "./containers/DormIsolationRetestRpt";
import QuickVue from "./containers/QuickVue";
import HousingIsolationRetestMsg from "./containers/HousingIsolationRetestMsg";
import IndicatorDashboardTableauReact from "./containers/IndicatorDashboardTableauReact";
import OffCampusPosUpload from "./containers/OffCampusPosUpload";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/export" exact component={ExportAdmin} props={childProps} />
    <AppliedRoute path="/exportpcr" exact component={ExportAdminPcr} props={childProps} />
    <AppliedRoute path="/search" exact component={Search} props={childProps} />
    {/* <AppliedRoute path="/csv" exact component={Csv} props={childProps} /> */}
    <AppliedRoute path="/posresults" exact component={PosResults} props={childProps} />
    <AppliedRoute path="/safer" exact component={Safer} props={childProps} />
    <AppliedRoute path="/pcrupload" exact component={PcrUpload} props={childProps} />
    <AppliedRoute path="/groupresults" exact component={GroupResults} props={childProps} />
    <AppliedRoute path="/chupload" exact component={CampusHealthUpload} props={childProps} />
    <AppliedRoute path="/map" exact component={Map} props={childProps} />
    {/* <AppliedRoute path="/labelprint" exact component={LabelPrint} props={childProps} /> */}
    <AppliedRoute path="/dorm-compliance" exact component={DormCompliance} props={childProps} />
    <AppliedRoute path="/tar" exact component={TarResults} props={childProps} />
    <AppliedRoute path="/pasc" exact component={PascResults} props={childProps} />
    <AppliedRoute path="/borderlines-csv" exact component={PascCsv} props={childProps} />
    <AppliedRoute path="/dorm-counts" exact component={DormTestResults} props={childProps} />
    <AppliedRoute path="/pac12" exact component={Pac12Results} props={childProps} />
    <AppliedRoute path="/offcampus" exact component={OffCampus} props={childProps} />
    <AppliedRoute path="/checkin" exact component={CheckIn} props={childProps} />
    <AppliedRoute path="/checkin-test" exact component={CheckInTest} props={childProps} />
    <AppliedRoute path="/checkin-stats" exact component={CheckInStats} props={childProps} />
    <AppliedRoute path="/sample-inv" exact component={SampleInvalidate} props={childProps} />
    <AppliedRoute path="/accommodations" exact component={Accommodations} props={childProps} />
    <AppliedRoute path="/student-compliance" exact component={StudentCompliance} props={childProps} />
    <AppliedRoute path="/vaccine-records" exact component={VaccineRecords} props={childProps} />
    <AppliedRoute path="/vaccine-exemption" exact component={VaccineExemptionReport} props={childProps} />
    <AppliedRoute path="/classroom-notification" exact component={SaferCcn} props={childProps} />
    {/* <AppliedRoute path="/wifi-block-stats" exact component={WifiBlockStats} props={childProps} /> */}
    <AppliedRoute path="/immunity" exact component={ImmunityReport} props={childProps} />
    <AppliedRoute path="/moderna-trial" exact component={ModernaTrial} props={childProps} />
    <AppliedRoute path="/rotc-compliance" exact component={RotcCompliance} props={childProps} />
    <AppliedRoute path="/event" exact component={EventWidget} props={childProps} />
    {/* <AppliedRoute path="/neg-result" exact component={NegResult} props={childProps} /> */}
    <AppliedRoute path="/neg-result" exact component={NegResultsInactive} props={childProps} />
    <AppliedRoute path="/neg-result-admin" exact component={NegResultAdmin} props={childProps} />
    <AppliedRoute path="/breakthrough" exact component={BreakthruReinfections} props={childProps} />
    <AppliedRoute path="/student-vaccination-report" exact component={StudentVaccinationReport} props={childProps} />
    <AppliedRoute path="/athletics-vaccination" exact component={StudentAthletesVaccinationReport} props={childProps} />
    <AppliedRoute path="/test-tracking" exact component={TestTracking} props={childProps} />
    <AppliedRoute path="/incomplete" exact component={IncompleteSamples} props={childProps} />
    <AppliedRoute path="/ch-msg" exact component={CampusHealthPosMsg} props={childProps} />
    <AppliedRoute path="/isolation" exact component={DormIsolationRetestRpt} props={childProps} />
    <AppliedRoute path="/quickvue" exact component={QuickVue} props={childProps} />
    <AppliedRoute path="/housing-message" exact component={HousingIsolationRetestMsg} props={childProps} />
    <AppliedRoute path="/indicators" exact component={IndicatorDashboardTableauReact} props={childProps} />
    <AppliedRoute path="/pos-upload" exact component={OffCampusPosUpload} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
