import React, {Component} from "react";
import moment from "moment";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';

export default class Search extends Component {
    constructor(props) {
        super(props);

        let now_str = moment().format("YYYY-MM-DD");

        this.state = {
            grpsOk: false,
            netid: '',
            vialBarcode: '',
            startDate: now_str,
            endDate: now_str,
            testTypes: {
                AG: true,
                PCR: true
            },
            dateType: 'collection',
            azDhsCsv: false,
            noResultsFound: false,
            loading: false,
            results: {
                positive: false,
                borderline: false,
                negative: false,
                indeterminate: false,
                rejected:false
            },
            status: {
                status1: false,
                status2: false,
                status3: false,
                status4: false,
                status5: false,
                status6: false

            },
            searchResults: []
        };

        this.statusTypes = [
            {text: 'Sample received at collection facility (1)', value: 1},
            {text: 'Sample received at lab (2)', value: 2},
            {text: 'Result received from lab (3)', value: 3},
            {text: 'Result sent to portal (4)', value: 4},
            {text: 'Additional alerts sent for positive result (5)', value: 5},
            {text: 'Sample rejected, sent to patient (6)', value: 6},
            {text: 'Borderline positive, retest required (7)', value: 7}
        ];

        this.onSearch = this.onSearch.bind(this);
        this.renderSearchHome = this.renderSearchHome.bind(this);
        this.onNetIdChange = this.onNetIdChange.bind(this);
        this.onVialBarcodeChange = this.onVialBarcodeChange.bind(this);
        this.onFromDateChange = this.onFromDateChange.bind(this);
        this.onToDateChange = this.onToDateChange.bind(this);
        this.onResultsChange = this.onResultsChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onTestTypesChange = this.onTestTypesChange.bind(this);
        this.renderResultsTable = this.renderResultsTable.bind(this);
        this.renderSampleStatus = this.renderSampleStatus.bind(this);
    }

    async componentDidUpdate(prevProps) {
        let validGroup = false;
        let allowedGroups = [
            'arizona.edu:services:enterprise:ctrds:search-test-results'
        ];

        if (this.props.groups !== prevProps.groups) {
            if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
                return;
            }

            // see if any of allowed groups are in our group set
            for (let grp of allowedGroups) {
                if (this.props.groups.includes(grp)) {
                    validGroup = true;
                }
            }

            if (validGroup) {
                this.setState({ grpsOk: true, isLoading: false });
            }
        }
    }

    onSearch(event) {
        event.preventDefault();
        event.stopPropagation();

        let testTypes = [];
        if (this.state.testTypes.AG) {
            testTypes.push('AG');
        }
        if (this.state.testTypes.PCR) {
            testTypes.push('PCR');
        }
        if (!this.state.testTypes.AG && !this.state.testTypes.PCR) {
            testTypes = ['AG', 'PCR'];
        }

        let queryParams = {
            test_type: testTypes.join(',')
        };

        const netid = this.state.netid;
        if (netid.length > 0 && netid.match(/^[a-z0-9]+$/i)) {
            queryParams['netid'] = netid;
        }

        const vialBarcode = this.state.vialBarcode;
        if (vialBarcode.length > 0 && vialBarcode.match(/^[a-zA-Z0-9-]+$/))  {
            queryParams['vialbarcode'] = vialBarcode;
        }

        const startDate = this.state.startDate;
        if (startDate.length > 0 && startDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            queryParams['from'] = startDate;
        }

        const endDate = this.state.endDate;
        if (endDate.length > 0 && endDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            queryParams['to'] = endDate;
        }

        const results = Object.entries(this.state.results);
        for (const [name, value] of results) {
            if (value) {
                queryParams[name + '_results'] = 1;
            }
        }

        const statusTypes = [];

        for (let step = 1; step < 8; step++) {
            if (this.state.status['status' + step]) {
                statusTypes.push(step);
            }
        }

        if (statusTypes.length > 0) {
            queryParams['status_types'] = statusTypes.join(',');
        }



/*        const status = Object.entries(this.state.status);
        for (const [name, value] of status) {
            if (value) {
                queryParams[name + '_status'] = 1;
            }
        }*/

        this.setState({
            loading: true,
            noResultsFound: false,
        });

        Auth.currentSession().then(session => {
            const token = session.idToken.jwtToken;

            API.get("search", "/searchResults",
            {
                    headers: {
                        Authorization: token
                    },
                    queryStringParameters: queryParams
                }
            ).then(response => {
                this.setState({
                    searchResults: response,
                    noResultsFound: response.length === 0,
                    loading: false
                })
            });
        });

    }

    onNetIdChange(event) {
        this.setState({
            netid: event.target.value
        });
    }

    onVialBarcodeChange(event) {
        this.setState({
            vialBarcode: event.target.value
        });
    }

    onFromDateChange(event) {
        this.setState({
            startDate: event.target.value
        });
    }

    onToDateChange(event) {
        this.setState({
            endDate: event.target.value
        });
    }

    onResultsChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        this.setState({
            results: { ...this.state.results, [name]: value }
        });
    }

    onStatusChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        this.setState({
            status: { ...this.state.status, [name]: value }
        });
    }

    onTestTypesChange(event) {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        this.setState({
            testTypes: { ...this.state.testTypes, [name]: value }
        });
    }

    renderUnauthorized() {
        return (<p>You do not have the appropriate permissions to access this page.</p>);
    }

    renderSearchHome() {
        return (
            <>
            <div className="row">
                <h3>Search Test Results</h3>
            </div>

            <div className="row">
                <form onSubmit={this.onSearch}>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="netidInput">Netid</label>
                            <input type="text" className="form-control" id="netidInput"
                                   value={this.state.netid} onChange={this.onNetIdChange} />
                        </div>
                        <div className="form-group col-md-9">
                            <label htmlFor="vialBarcodeInput">Vial Barcode</label>
                            <input type="text" className="form-control" id="vialBarcodeInput"
                                   value={this.state.vialBarcode} onChange={this.onVialBarcodeChange}/>
                        </div>
                    </div>
                    <div className="row">
{/*                        <div className="col-md-4">
                            <div className="input-group">
                                <label>Date Range</label>
                                <div className="radio">
                                    <label className="radio-inline">
                                        <input type="radio" name="dateRangeType" value="collection" checked={true}/> Collection Date
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="dateRangeType" value="completed" /> Completed Date
                                    </label>
                                </div>
                            </div>

                        </div>*/}
                        <div className="form-group col-md-4">
                            <label htmlFor="fromDateInput">From</label>
                            <input type="date" className="form-control" id="fromDateInput" placeholder="yyyy-mm-dd"
                                   value={this.state.startDate} onChange={this.onFromDateChange}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="toDateInput">To</label>
                            <input type="date" className="form-control" id="toDateInput" placeholder="yyyy-mm-dd"
                                   value={this.state.endDate} onChange={this.onToDateChange}/>
                        </div>
                    </div>

{/*                    <div className="row">
                        <div className="col-md-4">
                            <div className="input-group">
                                <label>Test Type</label>
                                <div className="radio">
                                    <label className="radio-inline">
                                        <input type="radio" name="testType" value="AG" checked={true}/> Antigen (AG)
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="testType" value="PCR" /> PCR
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="testType" value="AB" /> Antibody (AB)
                                    </label>
                                </div>
                            </div>

                        </div>



                    </div>*/}
                    <div className="row">
                        <div className="form-group col-md-4">
                            {this.renderSampleStatus()}
                        </div>

                        <div className="form-group col-md-4">
                            <div className="input-group">
                                <label>Results</label>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="positive" onChange={this.onResultsChange}
                                               checked={this.state.results.positive}/> Positive
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="borderline" onChange={this.onResultsChange}
                                               checked={this.state.results.borderline}/> Borderline Positive
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="negative" onChange={this.onResultsChange}
                                               checked={this.state.results.negative}/> Negative
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="indeterminate" onChange={this.onResultsChange}
                                               checked={this.state.results.indeterminate}/> Indeterminate
                                    </label>
                                </div>
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="rejected" onChange={this.onResultsChange}
                                               checked={this.state.results.rejected}/> Rejected
                                    </label>
                                </div>
                            </div>
                        </div>
                            <div className="form-group col-md-4">
                                <div className="input-group">
                                    <label>Test Types</label>
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" name="AG" onChange={this.onTestTypesChange}
                                                checked={this.state.testTypes.AG} /> Antigen
                                    </label>
                                    </div>
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" name="PCR" onChange={this.onTestTypesChange}
                                                checked={this.state.testTypes.PCR} /> PCR
                                    </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-primary" type="submit">Search</button>
                           {/* <button className="btn btn-secondary">Reset</button>*/}
                        </div>

{/*                        <div className="col-md-6">
                            <button className="btn btn-primary">Download CSV</button>
                            <div className="checkbox">
                                <label className="checkbox-inline">
                                    <input type="checkbox" name="azdhsformat" checked={true} disabled={true} /> AZDHS Format
                                </label>
                            </div>
                        </div>*/}
                    </div>
                </form>
            </div>

            <div className="row">
                <div className="col-md-2 col-md-offset-5">
                    {this.state.noResultsFound &&
                    <p>No results found.</p>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 col-md-offset-5">
                    {this.state.searchResults.length > 0 && !this.state.loading && !this.state.noResultsFound &&
                    <p>Total rows: {this.state.searchResults.length}</p>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-md-2 col-md-offset-5">
                    {this.state.loading &&
                    <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
                    }
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    {this.state.searchResults.length > 0 && !this.state.loading && !this.state.noResultsFound && this.renderResultsTable()}
                </div>

            </div>



            </>
        )
    }

    renderSampleStatus() {
        const entries = this.statusTypes.map((entry) =>
            <div className="checkbox" key={"status" + entry.value}>
                <label>
                    <input type="checkbox" name={"status" + entry.value} onChange={this.onStatusChange}
                           checked={this.state.status["status" + entry.value] === true} /> {entry.text}
                </label>
            </div>

            // <label className="checkbox-inline">
            //     <input type="checkbox" name={"status" + entry.value} onChange={this.onStatusChange}
            //            checked={this.state.status["status" + entry.value] === true}/> {entry.text}
            // </label>
        )

        return (
            <div className="input-group">
                <label>Sample Status</label>
                {entries}
            </div>
        )
    }

    renderResultsTable() {
        const entries = this.state.searchResults.map((entry, index) =>
                <tr key={entry.netid + entry.vialBarcode}>
                    <td>{index + 1}</td>
                    <td>{entry.name}</td>
                    <td>{entry.netid}</td>
                    <td>{entry.vialBarcode.startsWith('UA79-') ? 'QV AG' : entry.testType}</td>
                    <td>{entry.collectionDate}</td>
                    <td>{entry.completedDate}</td>
                    <td>{entry.sampleStatus}</td>
                    <td>{entry.result}</td>
                    <td>{entry.vialBarcode}</td>
                </tr>
        );

        return (
            <div className="table-responsive">
                <table className="table-striped table-dark table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Netid</th>
                            <th>Test Type</th>
                            <th>Collection Date</th>
                            <th>Completed Date</th>
                            <th>Sample Status</th>
                            <th>Result</th>
                            <th>Vial Barcode</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries}
                    </tbody>
                </table>
            </div>
        );
    }


    render() {
        return (
            <div className="Search container">
                {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk)) && this.renderSearchHome()}
                {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
            </div>
        );
    }
}