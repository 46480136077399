import React, { Component } from "react";
import chlogo from '../images/campus_health_hdr.png';
import { Auth } from 'aws-amplify';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class CampusHealthPosMsg extends Component {

  renderLander() {
    return (
      <div className="lander">
        <h1>Important Message from Campus Health</h1>
        <p>Please log into this secure site to read an important message from Campus Health.</p>
        <CustomOAuthButton variant="primary" size="lg">Login</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderMessage() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-xs-8 col-sm-4 my-2">
            <a href="https://health.arizona.edu" title="Campus Health" target="_blank" rel="noopener noreferrer" className="remove-external-link-icon"><img src={chlogo} style={{ maxWidth: '300px' }} alt="Campus Health" /></a>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 col-xs-12">
            {/* <h3>Guardian Consent Required</h3> */}
            <p>
              Dear Wildcat,
            </p>
            <p>
              We were notified that your COVID-19 test was positive and want to offer support and information to help you.
            </p>
            <p>
              You should isolate yourself from others, as soon as possible, to avoid spreading the virus, even if you don't have symptoms. Please review this link on how to isolate when <a href="https://health.arizona.edu/covidpositive" target="_blank" rel="noopener noreferrer">COVID positive</a>.
            </p>
            <p>
              If you feel you are at high risk for COVID-19 complications, please consider monoclonal antibody therapy or anti-viral treatments. Details can be found in the link above.
              <ul>
                <li>Stay home except to get medical care</li>
                <li>Monitor your symptoms</li>
                <li>Stay in a separate room from other household members, if possible</li>
                <li>Use a separate bathroom, if possible</li>
                <li>Avoid contact with other members of the household and pets</li>
                <li>Don't share personal household items, like cups, towels, and utensils</li>
                <li>Wear a mask when around other people</li>
                <li>If you have <a href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html#seek-medical-attention" target="_blank" rel="noopener noreferrer">emergency warning signs</a> (including trouble breathing), seek emergency medical care immediately.</li>
              </ul>
            </p>
            <p>
              Learn more about what to do <a href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html" target="_blank" rel="noopener noreferrer">if you are sick</a>.
            </p>
            <p>
              If you have urgent needs or your symptoms worsen, call <a href="https://health.arizona.edu/" target="_blank" rel="noopener noreferrer">Campus Health</a> during business hours at 520-621-6493 or call our After Hours line at 520-570-7898.
            </p>
            <p>
              Additionally, <a href="https://caps.arizona.edu/" target="_blank" rel="noopener noreferrer">Counseling and Psych Services (CAPS)</a> is available for students 24/7 at 520-621-3334 if you want to talk or need support for your mental health. Faculty and staff can reach out to <a href="https://lifework.arizona.edu/" target="_blank" rel="noopener noreferrer">Life &amp; Work Connections</a> at 520-621-2493.
            </p>
            <p>
              For more info, please see the Campus Health website at <a href="https://health.arizona.edu/" target="_blank" rel="noopener noreferrer">health.arizona.edu</a>
            </p>
            <p>
              Wherever you are, we are here for you.
            </p>
            <p>
              Bear Down and Mask Up.
            </p>
            <p>
              Sincerely,
              <br />
              Campus Health
            </p>
            <p>
              Business hours:  520-621-6493
              <br />
              After hours:  520-570-7898
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && this.props.authz) && this.renderMessage()}
        {(this.props.authn === 'signedIn' && !this.props.authz) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }

}
