import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CSVReader from 'react-csv-reader';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class PcrUpload extends Component {
  constructor(props) {
    super(props);
    this.postPcrResults=this.postPcrResults.bind(this);
    this.renderUploadErrors=this.renderUploadErrors.bind(this);
    this.csvErrors=this.csvErrors.bind(this);
    this.state = {
      isLoading: false,
      error: false,
      success: false,
      grpsOk: false,
      errDetail: [],
      numRows: 0
    };
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:pcr-lab:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getPosResults from componentDidUpdate");
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getPosResults from componentDidMount");
    // await this.getPosResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // handle errors from CSVReader component
  csvErrors(error) {
    this.setState({
      success: false,
      error: true,
      isLoading: false
    });
  }

  // upload JSON data to API endpoint
  async postPcrResults(data, fileInfo) {
    return Auth.currentSession().then(session => {
      this.setState({
        success: false,
        error: false,
        isLoading: true
      });
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          results: data
        }
      }
      return API.post("pcrResultsPublish", "/pcrResultsPublish", myInit)
      .then(res => {
        this.setState({
          success: true,
          error: false,
          numRows: res.numrows
        });
      }).catch(error => {
        console.log("Error in pcrResultsPublish" + error);
        this.setState({
          success: false,
          error: true,
        });
        if (error.response.status === 400) {
          this.setState({
            errDetail: error.response.data.details,
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
         <CustomOAuthButton variant="primary" size="lg">Login to Upload PCR Test Results</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>PCR Test Results CSV Upload</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUploadErrors() {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Your upload was rejected due to one or more errors. Please correct and resubmit.</strong>
        <ul>
        { 
          this.state.errDetail.map((err, i) => (
            <li key={i}><span className="label label-primary">row {err.rownum}</span>&nbsp;{err.errmsg}</li>
          ))
        }
        </ul>
      </div>
    );
  }

  renderCSVUpload() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
    };
    return(
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>PCR Test Results CSV Upload</h2>
              {this.state.success && <div className="alert alert-success" role="alert">Your CSV upload succeeded. <strong>{this.state.numRows} {this.state.numRows === 1 ? "row" : "rows"} uploaded.</strong></div>}
              {this.state.error && this.renderUploadErrors()}
              {/* <div className="alert alert-danger" role="alert">
              Your CSV upload failed.
              </div> */
              }
              {/* <Button variant="primary"> */}
                <CSVReader
                  label="Upload Results CSV"
                  onFileLoaded={this.postPcrResults}
                  onError={this.csvErrors}
                  parserOptions={papaparseOptions}
                />
              {/* </Button> */}
            </div>
          </div>
        </div>
      </div>    
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading)) && this.renderCSVUpload()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
