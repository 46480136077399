import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import { confirmAlert } from 'react-confirm-alert';
import Spinner from 'react-spinkit';
import { FaMinusCircle, FaPlusCircle, FaBan } from 'react-icons/fa';

import 'react-confirm-alert/src/react-confirm-alert.css';

export default class QuickVueForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcode: '',
      testResult: null,
      loading: false,
      submitted: false,
      confirmed: false,
      lookupRes: null,
      error: null,
    };

    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBarcode = this.handleBarcode.bind(this);
    this.handleSampleLookup = this.handleSampleLookup.bind(this);
    this.confirmResult = this.confirmResult.bind(this);
    this.submitResult = this.submitResult.bind(this);
  }

  handleBarcode(event) {
    this.setState({
      barcode: (event.target.value.match(/^.+-.+$/) ? event.target.value.split('-')[1] : event.target.value.replace(/[^UA\-0-9]/g, ""))
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  // confirm result
  confirmResult() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <h4 className="card-title">Confirm Result</h4>
            <p>Are you sure you want to submit the following result?</p>
            <strong>Name:</strong>&nbsp;&nbsp;{this.state.lookupRes.name}<br />
            <strong>NetID:</strong>&nbsp;&nbsp;{this.state.lookupRes.netid}<br />
            <strong>Sample ID:</strong>&nbsp;&nbsp;<code>{this.state.lookupRes.barcode}</code><br />
            <strong>Result:</strong>&nbsp;&nbsp;{this.state.testResult.toUpperCase()}&nbsp;
            {
              this.state.testResult == 'positive' &&
              <FaPlusCircle title="Positive" style={{ color: "red" }} />
            }
            {
              this.state.testResult == 'negative' &&
              <FaMinusCircle title="Negative" style={{ color: "green" }} />
            }
            {
              this.state.testResult == 'invalid' &&
              <FaBan title="Invalid" style={{ color: "orange" }} />
            }
            <br /><br />
            <button className="btn btn-primary mr-2" onClick={() => {
              this.setState({
                confirmed: false,
              }, () => { onClose(); });
            }}>Cancel</button>
            <button
              className="btn btn-hollow-default"
              disabled={this.state.confirmed}
              onClick={() => {
                onClose();
                this.setState({
                  loading: true,
                  confirmed: true,
                }, () => {
                  this.submitResult();
                });
              }}
            >
              Confirm
            </button>
          </div >
        );
      }
    });
  }

  handleSampleLookup(event) {

    // console.log("*** inside submit handler");
    // console.log("*** state = " + JSON.stringify(this.state));
    event.preventDefault();

    this.setState({ error: null, loading: true },
      () => {
        Auth.currentSession().then(session => {
          const token = session.idToken.jwtToken;
          let data = {
            barcode: 'UA79-' + this.state.barcode
          };

          let submission = {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json'
            },
            body: data,
          }
          return API.post("quickVueSampleLookup", "/quickVueSampleLookup", submission)
            .then(result => {
              this.setState({ error: null, loading: false, lookupRes: result, submitted: false });
            })
            .catch((error) => {
              let message = error.response.data.errmsg;
              let display = '';
              if (message === 'invalid barcode') {
                display = <p>You entered an invalid or previously-processed code. Please double check the code on the sample vial and re-enter.</p>
              } else {
                display = <p>An error has occurred. Please our COVID Ambassador Team Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.</p>
              }
              this.setState({ error: display, loading: false, scanned: false, barcode: '', submitted: false });
            });
        }).catch(error => {
          console.log("Error in Auth.currentSession: " + error);
          return [];
        });
      });
  }


  submitResult() {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let data = {
        barcode: 'UA79-' + this.state.barcode,
        result: this.state.testResult
      };
  
      let submission = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }
      return API.post("quickVueResultsPublish", "/quickVueResultsPublish", submission)
        .then(result => {
          this.setState({ error: null, loading: false, submitted: true });
        })
        .catch((error) => {
          let message = error.response.data.error;
          let display = '';
          if (message === 'invalid barcode') {
            display = <p>You entered an invalid or previously-registered code. Please double check the code on your sample vial and re-enter.</p>
          } else {
            display = <p>An error has occurred. Please our COVID Ambassador Team Hotline at 520-848-4045 or <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.</p>
          }
          this.setState({ error: display, loading: false, lookupRes: null, testResult: null, barcode: '', submitted: false, confirmed: false  });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }


  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1>QuickVue Antigen Test Results</h1>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    const {
      barcode,
      loading,
    } = this.state;

    const submitEnabled = (
      !loading &&
      barcode.length
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <h1 className="col-xs-12">
            QuickVue Antigen Test Results
          </h1>
          <p className="col-xs-12 text-azurite">
            Logged in as <strong>{this.props.name} ({this.props.netID})</strong>
          </p>
        </div>

        {
          this.state.error &&
          <div className="callout callout-red col-xs-12">
            <h2 className='mt-0'>Whoops!</h2>
            {this.state.error}
          </div>
        }

        <div className="callout callout-info col-xs-12">
          <p>Please scan the sample QR code or enter the sample ID.</p>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-4">
            <label htmlFor="barcode">Code</label>
            <div className="input-group">
              <span className="input-group-addon" id="barcode-pfx"><code>UA79-</code></span>
              <input type="text" autoFocus className="form-control" id="barcode" name="barcode" value={this.state.barcode} aria-describedby="barcode-pfx" maxLength="12" minLength="7" value={this.state.barcode} onChange={this.handleBarcode} />
            </div>
          </div>
        </div>

        <div className="row mt-4 mb-3">
          <div className="col-xs-12">
            <input disabled={!submitEnabled}
              type="submit"
              value="PROCESS"
              className="btn btn-lg btn-blue"
              onClick={this.handleSampleLookup}
            />
          </div>
        </div>
      </form>
    );
  }

  renderVerify() {
    const {
      loading,
      testResult
    } = this.state;

    const submitEnabled = (
      !loading &&
      ['invalid', 'negative', 'positive'].includes(testResult)
    );

    return (
      <>
        <div className="row">
          <h1 className="col-xs-12">
            QuickVue Antigen Test Results
          </h1>
          <p className="col-xs-12 text-azurite">
            Logged in as <strong>{this.props.name} ({this.props.netID})</strong>
          </p>
        </div>

        <h2 className="mb-1">Select Result</h2>

        <div className="callout callout-info col-xs-12">
          <p>Please select the result for the following sample</p>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-4">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>
                    {this.state.lookupRes.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>NetID</strong>
                  </td>
                  <td>
                    {this.state.lookupRes.netid}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Timestamp</strong>
                  </td>
                  <td>
                    {this.state.lookupRes.create_time}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Sample ID</strong>
                  </td>
                  <td>
                    <code>{this.state.lookupRes.barcode}</code>
                  </td>
                </tr>
              </tbody>
            </table>
            <p></p>
            <div className="input-group mb-3">
              <label>What is the test result? <span className="form-required" style={{ color: 'red' }} title="This field is required.">*</span></label>
              <div className="radio mt-0">
                <label>
                  <input type="radio" name="testResult" value="negative" onChange={this.handleChange} />
                  Negative
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="testResult" value="positive" onChange={this.handleChange} />
                  Positive
                </label>
              </div>
              <div className="radio mb-3">
                <label>
                  <input type="radio" name="testResult" value="invalid" onChange={this.handleChange} />
                  Invalid
                </label>
              </div>
              <button className="btn btn-primary mr-2"
                onClick={() => {
                  this.setState({
                    barcode: '',
                    testResult: null,
                    loading: false,
                    submitted: false,
                    lookupRes: null,
                  });
                }}>Cancel</button>
              <button
                className="btn btn-hollow-default"
                disabled={!submitEnabled}
                onClick={() => {
                    this.confirmResult();
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderConfirmation() {
    return (
      <>
        <h2 className="col-xs-12">QuickVue Antigen Test Results</h2>
        <div className="col-xs-12 d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="3em" height="3em"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path></svg>
          <span className="pl-2 text-size-h3">Confirmed! The following result has been submitted:</span>
        </div>
        <div className="col-xs-12">
          <div className="callout callout-info">
            <strong>Name:</strong>&nbsp;&nbsp;{this.state.lookupRes.name}<br />
            <strong>NetID:</strong>&nbsp;&nbsp;{this.state.lookupRes.netid}<br />
            <strong>Sample ID:</strong>&nbsp;&nbsp;<code>{this.state.lookupRes.barcode}</code><br />
            <strong>Result:</strong>&nbsp;&nbsp;{this.state.testResult.toUpperCase()}&nbsp;
            {
              this.state.testResult == 'positive' &&
              <FaPlusCircle title="Positive" style={{ color: "red" }} />
            }
            {
              this.state.testResult == 'negative' &&
              <FaMinusCircle title="Negative" style={{ color: "green" }} />
            }
            {
              this.state.testResult == 'invalid' &&
              <FaBan title="Invalid" style={{ color: "orange" }} />
            }
          </div>
          <button className="btn btn-primary"
            onClick={() => {
              this.setState({
                barcode: '',
                testResult: null,
                loading: false,
                submitted: false,
                lookupRes: null,
                confirmed: false,
              });
            }}
          >
          ENTER NEXT RESULT
          </button>
        </div>
      </>
    );
  }

  render() {
    return (
      // <div>
      //   {this.state.loading ? this.renderLoading() :
      //     <>
      //       {!this.state.submitted && this.renderForm()}
      //       {this.state.submitted && this.renderConfirmation()}
      //       {/* {this.state.error && this.renderError()} */}
      //     </>
      //   }
      // </div>
      <div>
        {!this.state.lookupRes && !this.state.loading && this.renderForm()}
        {this.state.lookupRes && !this.state.loading && !this.state.submitted && this.renderVerify()}
        {!this.state.loading && this.state.submitted && this.renderConfirmation()}
        {this.state.loading && this.renderLoading()}
      </div>
    );
  }
}
