import React, { createRef, Component } from 'react';
import {Auth} from 'aws-amplify';

// export default class AuthenticatedLink extends Component {
//     constructor(props) {
//         super(props);
//
//         //this.linkRef = createRef();
//
//         this.state = {
//             url: props.url,
//             filename: props.filename,
//             href: null
//         };
//
//         this.handleAction = this.handleAction.bind(this);
//         this.fetchBlob = this.fetchBlob.bind(this);
//     }
//
//     componentDidMount() {
//         this.fetchBlob();
//     }
//
//     componentDidUpdate(prevProps, prevState, snapshot) {
//         if (this.props.url !== prevProps.url) {
//             this.fetchBlob();
//         }
//     }
//
//     fetchBlob() {
//         Auth.currentSession().then( async (session) => {
//             const token = session.idToken.jwtToken;
//
//             const result = await fetch(this.state.url, {
//                 headers: {
//                     Authorization: token,
//                     // 'Content-Type': 'application/json'
//                 }
//             })
//
//             const blob = await result.blob()
//             const href = window.URL.createObjectURL(blob)
//             console.log('href' + href);
//
//             this.setState({
//                 href: href
//             });
//
//             //this.linkRef.current.download = this.state.filename
//             //this.linkRef.current.href = href
//
//             //this.linkRef.current.click()
//         });
//     }
//
//     handleAction(event) {
//
//     }
//
//     // handleAction(event) {
//     //     event.preventDefault();
//     //     event.stopPropagation();
//     //     const url = this.state.url;
//     //     const filename = this.state.filename
//     //
//     //     if (this.linkRef.current.href) {
//     //         return;
//     //     }
//     //
//     //     Auth.currentSession().then( async (session) => {
//     //         const token = session.idToken.jwtToken;
//     //
//     //         const result = await fetch(this.state.url, {
//     //             headers: {
//     //                 Authorization: token,
//     //                 // 'Content-Type': 'application/json'
//     //             }
//     //         })
//     //
//     //         const blob = await result.blob()
//     //         const href = window.URL.createObjectURL(blob)
//     //         console.log('href' + href);
//     //
//     //         this.linkRef.current.download = this.state.filename
//     //         this.linkRef.current.href = href
//     //
//     //         this.linkRef.current.click()
//     //     });
//     // }
//
//     render() {
//         return (
//             <a role='button'
//                href={this.state.href} download={this.state.filename}
//                onClick={this.handleAction}>{this.props.children}</a>
//         );
//     }
// }
//
export function AuthenticatedLink({url, filename, children}) {

    const link = createRef();

    const handleAction = async () => {

        console.log('url' + url);

        if (link.current.href) {
            return
        }

        Auth.currentSession().then(async (session) => {
            const token = session.idToken.jwtToken;

            const result = await fetch(url, {
                headers: {
                    Authorization: token,
                    // 'Content-Type': 'application/json'
                }
            })

            const blob = await result.blob();
            const href = window.URL.createObjectURL(blob);
            console.log('href' + href);

            link.current.download = filename;
            link.current.target = '_blank';
            link.current.href = href;


            link.current.click();
            window.location.reload();
        });


    }

    return (
        <>
            <a role='button' ref={link} onClick={handleAction}>{children}</a>
        </>
    )
}
