import React, { Component } from "react";
import CustomOAuthButton from '../CustomOAuthButton';
import { Auth, API } from 'aws-amplify';
// import Spinner from 'react-spinkit';
// import "../css/Home.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, Space } from 'antd';
//import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
//import 'antd/lib/date-picker/style/css'; // for cs
import { Table } from 'antd';

import moment from 'moment';
import { Navbar, Button } from "react-bootstrap";

import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import { Checkbox } from 'antd';

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import { string } from "prop-types";


const { RangePicker } = DatePicker;



export default class Home extends Component {
    constructor(props) {
        super(props);

        this.data = null;

        this.loaded = false;
        this.dataloaded = false;
        this.negmap = null;
        this.coordmap = null;


        this.state = {
            lng: -110.942342,
            lat: 32.224764,
            zoom: 12,
            dorm: true,
            greek: true,
            highrise: true,
            other: true,
            totalCount: 0,
            totalNegative: 0,
            unknownCount: 0,
            grpsOk: false
        };

        mapboxgl.accessToken = 'pk.eyJ1IjoiZGF0YTdyaWkiLCJhIjoiY2tmNW5lYjVyMG9yZzJwbjR4aThxZG5kbiJ9.MvJwduX-ZLamwVc2M3L43Q';


        this.onChange = this.onChange.bind(this);
        this.onOk = this.onOk.bind(this);
        this.changeSlider = this.changeSlider.bind(this);
        this.filterBy = this.filterBy.bind(this);
        this.onDorms = this.onDorms.bind(this);
        this.onGreeks = this.onGreeks.bind(this);
        this.onHighrise = this.onHighrise.bind(this);
        this.onOther = this.onOther.bind(this);
        this.clickCluster = this.clickCluster.bind(this);
        this.loadMap = this.loadMap.bind(this)

    }

    changeSlider(values, handle, unencoded, tap, positions, noUiSlider) {

        var vala = [moment(values[0], "x"), moment(values[1], "x")]

        this.setState({
            'value': vala,
            'fromv': vala[0].valueOf(),
            'tov': vala[0].valueOf()
        })

        this.filterBy(vala[0].valueOf(), vala[1].valueOf(), this.state.dorm, this.state.greek, this.state.highrise, this.state.other);
    }

    clickCluster(e) {

        var _this = this;

        var features = _this.state.map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id,
            point_count = features[0].properties.point_count,
            clusterSource = _this.state.map.getSource(/* cluster layer data source id */'earthquakes');

        // // Get Next level cluster Children
        // // 
        // clusterSource.getClusterChildren(clusterId, function(err, aFeatures){
        //   
        // });

        // Get all points under a cluster
        clusterSource.getClusterLeaves(clusterId, point_count, 0, function (err, aFeatures) {


            let info = new Map()
            let feats = new Map()

            for (var i = 0; i < aFeatures.length; i++) {
                var ob = aFeatures[i];

                if (info.has(ob.properties.Address)) {
                    info.set(ob.properties.Address, info.get(ob.properties.Address) + 1);
                } else {
                    info.set(ob.properties.Address, 1);
                    feats.set(ob.properties.Address, ob);
                }
            }

            if (info.size > 1 && _this.state.map.getZoom() != 17) {
                var features = _this.state.map.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });

                if (features[0] == undefined)
                    return;


                var clusterId = features[0].properties.cluster_id;
                _this.state.map.getSource('earthquakes').getClusterExpansionZoom(
                    clusterId,
                    function (err, zoom) {
                        if (err) return;

                        _this.state.map.easeTo({
                            center: features[0].geometry.coordinates,
                            zoom: zoom
                        });
                    }
                );
            } else {

                var coordinates = aFeatures[0].geometry.coordinates.slice();

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                var html = ""
                var count = 1;
                var name = "N/A"
                var address = null;

                // if (_this.coordmap.has(coordinates[0] + " " + coordinates[1])) {
                //     count = _this.coordmap.get(coordinates[0] + " " + coordinates[1])
                // }

                count = aFeatures.length;
                
                if (_this.coordadd.has(coordinates[0] + " " + coordinates[1])) {
                    address = _this.coordadd.get(coordinates[0] + " " + coordinates[1])
                }
                if (_this.coordname.has(coordinates[0] + " " + coordinates[1])) {
                    name = _this.coordname.get(coordinates[0] + " " + coordinates[1])
                }

                if (address == null) {

                    for (let [key, value] of info) {
                        var prop = feats.get(key);
                        address = prop.properties.Address;
                        if (address != null)
                            break;
                    }

                }

                //html = count + " " + name + " " + address;


                // for (let [key, value] of info) {

                //     var prop = feats.get(key);
                html += "<b>Name: </b>" + name + " <br> ";
                html += "<b>Address: </b>" + address + "  <br> ";
                if (_this.negmap.has(coordinates[1] + " " + coordinates[0])) {
                    var negatives = _this.negmap.get(coordinates[1] + " " + coordinates[0]);
                    html += "<b>Negative Count: </b>" + negatives + "<br>"
                    html += "<b>Positive Count: </b>" + count + "<br>"
                    html += "<b>Percentage: </b> " + ((count / (count+negatives)) * 100).toFixed(2) + "%" + "<br>"
                    html += "<div class='sep'> </div>"
                } else {
                    html += "<b>Positive Count: </b>" + count + "<br>" + "<div class='sep'> </div>"
                }
                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(
                        html
                    )
                    .addTo(_this.state.map);
            }
        })
    }

    filterBy(from, to, dorms = true, greek = true, highrise = true, other = true) {

        this.setState({
            fromv: from,
            tov: to,
            dorm: dorms,
            greek: greek,
            highrise: highrise,
            other: other
        })

        count = 0;
        var unknownCount = 0;

        for (var i = 0; i < this.data.features.length; i++) {
            var entry = this.data.features[i];

            if (entry.properties.unix_time >= from && entry.properties.unix_time <= to) {

                if (entry.properties.Address.includes("Unknown Address")){
                    unknownCount++;
                }

                if (!dorms && entry.properties.dorm) {
                    continue
                }
                if (!highrise && entry.properties.highrise) {
                    continue
                }
                if (!greek && entry.properties.greek) {
                    continue
                }
                if (!other && entry.properties.other) {
                    continue
                }
                count++;
            }

        }

        this.setState({ totalCount: count, unknownCount: unknownCount })

        Auth.currentSession().then(async session => {
            const token = session.idToken.jwtToken;
            let myInit = {
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            }

            var begin = moment(from).format('YYYY-MM-DD HH:mm:ss')
            var end = moment(to).format('YYYY-MM-DD HH:mm:ss')

            myInit.queryStringParameters = { "negatives": true, "begin": begin, "end": end }

            const negative = await API.get("mapData", "/mapData", myInit);
            this.state.totalNegative = 0;


            this.negmap = new Map()
            for (var i = 0; i < negative.length; i++) {
                var line = negative[i];

                this.state.totalNegative += line.cnt;

                if (this.negmap.has(line.lat + " " + line.long)) {
                    //
                } else {
                    this.negmap.set(line.lat + " " + line.long, line.cnt);
                }
            }
            //Stupid react hack. Probably a better way to do this.
            this.setState({ totalNegative: this.state.totalNegative });
        });


        var f1 = ["all",
            [">=", ["get", "unix_time"], from],
            ["<=", ["get", "unix_time"], to],
        ];

        if (this.loaded) {

            try {
                this.state.map.removeLayer("clusters");
                this.state.map.removeLayer("cluster-count");
                this.state.map.removeLayer("unclustered-point");
                this.state.map.removeLayer("greek");
                this.state.map.removeLayer("highrise");
                this.state.map.removeLayer("dorm");
                this.state.map.removeLayer("single-count");
                this.state.map.removeSource("earthquakes");
                this.state.map.removeSource('q2');
            } catch (e) {

            }
        } else {
            this.loaded = true;
        }

        var count = 0;

        if (dorms)
            count++;

        if (highrise)
            count++;

        if (greek)
            count++;

        if (other)
            count++

        if (count == 1) {

            if (dorms)
                f1.push(["==", ["get", "dorm"], true])

            if (highrise)
                f1.push(["==", ["get", "highrise"], true])

            if (greek)
                f1.push(["==", ["get", "greek"], true])

            if (other)
                f1.push(["==", ["get", "other"], true])
        } else if (count > 1) {
            f1.push(["any"
            ]);
            if (dorms)
                f1[3].push(["==", ["get", "dorm"], true])

            if (highrise)
                f1[3].push(["==", ["get", "highrise"], true])

            if (greek)
                f1[3].push(["==", ["get", "greek"], true])

            if (other)
                f1[3].push(["==", ["get", "other"], true])

        }


        this.state.map.addSource('earthquakes', {
            type: 'geojson',
            data:
                this.data,
            filter: f1,
            cluster: true,
            clusterMaxZoom: 25, // Max zoom to cluster points on
            clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
        });

        this.state.map.addSource('q2', {
            type: 'geojson',
            // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
            // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
            data:
                this.data,
            filter: f1
        });

        // Add a symbol layer
        this.state.map.addLayer({
            'id': 'greek',
            'type': 'symbol',
            'source': 'q2',
            'filter': ["==", ["get", "greek"], true],
            'minzoom': 14,
            'layout': {
                'icon-image': 'custom-marker',
                'icon-offset': [500, 0],
                'icon-size': 0.08
            }
        });

        this.state.map.addLayer({
            'id': 'highrise',
            'type': 'symbol',
            'source': 'q2',
            'filter': ["==", ["get", "highrise"], true],
            'minzoom': 14,
            'layout': {
                'icon-image': 'highrise',
                'icon-offset': [500, 0],
                'icon-size': 0.08
            }
        });

        this.state.map.addLayer({
            'id': 'dorm',
            'type': 'symbol',
            'source': 'q2',
            'filter': ["==", ["get", "dorm"], true],
            'minzoom': 14,
            'layout': {
                'icon-image': 'dorm',
                'icon-offset': [500, 0],
                'icon-size': 0.08
            }
        });


        this.state.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'earthquakes',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#ffffe0',
                    5,
                    '#C0D48F',
                    10,
                    '#91C771',
                    15,
                    '#58B955',
                    35,
                    '#38AA5A',
                    45,
                    '#1D9A6C',
                    60,
                    '#0EA986',
                    75,
                    '#00B9A9',
                    100,
                    '#00B9A9',
                    133,
                    '#00BCC8',
                    166,
                    '#00A6D7',
                    200,
                    '#00A6D7',
                    250,
                    '#00A6D7',
                    300,
                    '#00A6D7'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    5,
                    30,
                    100,
                    30
                ]
            }
        });

        this.state.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'earthquakes',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
            // paint: {
            //     "text-color": "#D3D3D3"
            //   }
        });

        this.state.map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'earthquakes',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#ffffe0',
                'circle-radius': 15,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        });

        this.state.map.addLayer({
            id: 'single-count',
            type: 'symbol',
            source: 'earthquakes',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'text-field': '1',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });


        var _this = this;

        this.state.map.on('click', 'unclustered-point', function (e) {


            var coordinates = e.features[0].geometry.coordinates.slice();
            var dorm = e.features[0].properties.dorm;
            var dorm_name = e.features[0].properties.dorm_name;
            var address = e.features[0].properties.Address;


            // Ensure that if the map is zoomed out such that
            // multiple copies of the feature are visible, the
            // popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            var negatives = 0;
            negatives = _this.negmap.get(coordinates[1].toFixed(6) + " " + coordinates[0].toFixed(6));

            if (negatives === undefined){
                negatives = 0;
            }

        
            var html ='<b>Name: </b>' + dorm_name + '<br><b>Address: </b>' + address + "<br> <b>Positive Count: </b>1<br>"
            html += "<b>Negative Count: </b>" + negatives + "<br>"
            html += "<b>Percentage: </b> " + ((1 / (negatives+1)) * 100).toFixed(2) + "%" + "<br>"
            html += "<div class='sep'> </div>"

            new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(html
                )
                .addTo(_this.state.map);
        });


        this.state.map.on('click', 'clusters', this.clickCluster);
    }

    componentDidUpdate(prevProps) {
        let validGroup = false;
        let allowedGroups = [
            'arizona.edu:services:enterprise:ctrds:testing-map:access'
        ];

        if (this.props.groups !== prevProps.groups) {
            if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
                return;
            }

            // see if any of allowed groups are in our group set
            for (let grp of allowedGroups) {
                if (this.props.groups.includes(grp)) {
                    validGroup = true;
                    break;
                }
            }

            if (validGroup) {
                this.setState({ grpsOk: true });
            }
        }
    }

    componentDidMount() {

        // function sleep(ms) {
        //     return new Promise(resolve => setTimeout(resolve, ms));
        // }

        //this.loadMap();
    }

    loadMap(){
        var footer = document.getElementById("footer_site");
        footer.style.visibility = "hidden"

        var _this = this;
        // wait for a paint before running scrollHeight dependent code.
        window.requestAnimationFrame(function () {

            if (_this.slider == undefined)
                return;

            var map = new mapboxgl.Map({
                container: _this.mapContainer,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [_this.state.lng, _this.state.lat],
                zoom: _this.state.zoom,
                maxZoom: 17
            });

            var geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl
            });


            document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

            map.loadImage(
                '/greek.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('custom-marker', image);
                });

            map.loadImage(
                '/highrise.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('highrise', image);
                });

            map.loadImage(
                '/dorm.png',
                function (error, image) {
                    if (error) throw error;
                    map.addImage('dorm', image);
                });


            _this.setState({
                "map": map
            });

            Auth.currentSession().then(async session => {
                const token = session.idToken.jwtToken;
                let myInit = {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json'
                    }
                }
                const data = await API.get("mapData", "/mapData", myInit);

                var dorms = ["550 N HIGHLAND AVE", "515 N TYNDALL AVE", "910 E 5TH ST TUCSON AZ", "1717 E SPEEDWAY BLVD", "1018 S CAMPUS DR", "1003 OLIVE RD", "602 N HIGHLAND AVE", "822 E 5TH ST", "1009 James E. Rogers Way", "610 N HIGHLAND AVE", "1101 E MABEL ST", "1440 E 4TH ST", "922 E 4TH ST", "825 E 5TH ST", "500 N HIGHLAND AVE", "1000 N PARK AVE", "1031 E JAMES E ROGERS", "1557 E 6TH ST", "1340 E 1ST ST", "601 N HIGHLAND AVE", "621 N HIGHLAND AVE", "575 N HIGHLAND AVE", "1121 E 4TH ST"]
                var dorm_names = ["APACHE-SANTA CRUZ", "ARBOL DE LA VIDA", "Arizona-Sonora", "Babcock", "Cochise", "Coconino", "Colonia de la Paz", "Coronado", "Gila", "Graham-Greenlee", "Honors Village", "Hopi", "Kaibab-Huachuca", "La Aldea Graduate Hall", "Likins", "Manzanita-Mohave", "Maricopa", "Navajo-Pinal", "Pima", "Posada San Pedro", "Pueblo de la Cienega", "Villa Del Puente", "Yavapai"]
                var greek = ["1775 E 1st St", "1443 E 1st St", "1071 N Mountain Ave", "1339 E 1st St", "1420 E 1st St", "1448 E 1st St", "1535 E 1st St", "906 N First Ave", "1050 N Mountain Ave", "1435 E Second St", "1745 E 2nd St", "1801 E 1st St", "380 N Highland Ave", "1035 N Mountain Ave", "1525 E Drachman St", "1449 N Cherry Ave", "1605 E 1st St", "1511 E 1st St", "1588 E 1st St", "1614 E 1st St", "1115 E Helen St", "1423 E First Street", "1509 E 2nd St", "1621 E 1st St", "1701 E 1st St"]
                var highrise = ["1023 N Tyndall Ave", "950 N Tyndall Ave", "1020 N Tyndall Ave", "1011 N Tyndall Ave", "900 E Second St", "850 E Speedway Blvd", "1029 N Park Ave", "550 N 5th", "55 N Park", "1000 E 22nd", "350 E Congress St", "774 E Seneca St"]
                var greek_names = ["Delta Gamma ", "Gamma Phi Beta", "Kappa Alpha Order", "Kappa Alpha Theta", "Kappa Kappa Gamma", "Phi Delta Theta", "Phi Gamma Delta", "Phi Kappa Tau", "Pi Beta Phi", "Pi Kappa Alpha ", "Pi Kappa Phi", "Sigma Alpha Mu", "Sigma Chi", "Sigma Kappa", "Sigma Phi Epsilon ", "Sigma Tau Gamma", "Tau Kappa Epsilon", "Theta Delta Chi", "Theta Xi", "Zeta Beta Tau "]
                var highrise_names = ["Urbane", "Aspire", "Sol y Luna", "Hub", "OLIV", "Hub at Speedway ", "Sol", "The District", "The Mark", "The Retreat", "The Cadence", "The Compound"]

                _this.coordmap = new Map()
                _this.coordname = new Map()
                _this.coordadd = new Map()
                var min_date = 999599095880000;
                var max_date = 0;
                _this.state.totalCount = data.features.length;
                for (var i = 0; i < data.features.length; i++) {

                    var b = data.features[i];
                    var c = b.properties.Result_Date;
                    var d = moment(c).valueOf()

                    b.properties.unix_time = d;
                    b.properties.dorm_name = "none"
                    b.properties.greek = false;
                    b.properties.dorm = false;
                    b.properties.highrise = false;
                    b.properties.other = true;

                    if (d < min_date)
                        min_date = d;

                    if (d > max_date)
                        max_date = d;

                    for (var j = 0; j < dorms.length; j++) {
                        if (b.properties.Address.toLowerCase().includes(dorms[j].toLowerCase())) {
                            b.properties.dorm = true;
                            b.properties.other = false;
                            b.properties.dorm_name = dorm_names[j]
                            break;
                        }
                    }

                    for (var j = 0; j < greek.length; j++) {
                        if (b.properties.Address.toLowerCase().includes(greek[j].toLowerCase())) {
                            b.properties.greek = true;
                            b.properties.other = false;
                            b.properties.dorm_name = greek_names[j];
                            break;
                        }
                    }

                    for (var j = 0; j < highrise.length; j++) {
                        if (b.properties.Address.toLowerCase().includes(highrise[j].toLowerCase())) {
                            b.properties.highrise = true;
                            b.properties.other = false;
                            b.properties.dorm_name = highrise_names[j]
                            break;
                        }
                    }

                    // Late Long Hashmap
                    var geom = b.geometry.coordinates;
                    var long = geom[0];
                    var lat = geom[1];

                    if (_this.coordmap.has(long + " " + lat)) {
                        _this.coordmap.set(long + " " + lat, _this.coordmap.get(long + " " + lat) + 1);
                    } else {
                        _this.coordmap.set(long + " " + lat, 1);
                    }

                    if (_this.coordadd.has(long + " " + lat)) {
                        //
                    } else {
                        if (b.properties.dorm_name != "none") {
                            _this.coordname.set(long + " " + lat, b.properties.dorm_name)
                            _this.coordadd.set(long + " " + lat, b.properties.Address)
                        }
                    }
                }

                _this.data = data;

                var d = new Date();
                d.setUTCHours(0,0,0,0);
                var today = +d
                today = today + 25200000
                var tomorrow = today + 86400000

                _this.setState(
                    {
                        'min': min_date,
                        'max': max_date,
                        'fromv': today,
                        'tov': tomorrow
                    }
                )

                map.on('load', function () {

                    _this.filterBy(today, tomorrow)
                });
            });
        });
    }

    onDorms(value) {
        var dorms = value.target.checked;
        this.filterBy(this.state.fromv, this.state.tov, dorms, this.state.greek, this.state.highrise, this.state.other);
    }
    onGreeks(value) {
        var greek = value.target.checked;
        this.filterBy(this.state.fromv, this.state.tov, this.state.dorm, greek, this.state.highrise, this.state.other);
    }
    onHighrise(value) {
        var highrise = value.target.checked;
        this.filterBy(this.state.fromv, this.state.tov, this.state.dorm, this.state.greek, highrise, this.state.other);
    }
    onOther(value) {
        var other = value.target.checked;
        this.filterBy(this.state.fromv, this.state.tov, this.state.dorm, this.state.greek, this.state.highrise, other);
    }

    onChange(value, dateString) {

        if (value == null || dateString == null)
            return;

        if (value[0].valueOf() > this.state.max)
            return;

        if (this.slider.noUiSlider != undefined)
            this.slider.noUiSlider.destroy()


        noUiSlider.create(this.slider, {
            start: [value[0].valueOf(), value[1].valueOf()],
            connect: true,
            behaviour: 'drag',
            step: 86400000,
            range: {
                'min': this.state.min,
                'max': this.state.max
            }
        });

        //this.slider.on('end', this.changeSlider);
        this.slider.noUiSlider.on('end', this.changeSlider);

        this.setState({
            'fromv': value[0].valueOf(),
            'tov': value[0].valueOf()
        });

        this.filterBy(value[0].valueOf(), value[1].valueOf());

    }

    onOk(value) {

    }

    renderDormForm() {

        if (!this.dataloaded){
            this.dataloaded = true;
            console.log("Loading Map Data")
            this.loadMap();
        }
        return (
            <div>
                <div ref={el => this.mapContainer = el} className="mapContainer" />
                <div className="keyContainer">
                    <div id="geocoder" className="geocoder"></div>
                    <Space direction="vertical" size={12} className="dateContainer">
                        <RangePicker ref={el => this.rangePicker = el}
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm"
                            onChange={this.onChange}
                            onOk={this.onOk}
                            value={this.state.value}
                        />
                        <Checkbox style={{ position: "relative", background: "white", padding: "2px" }} defaultChecked={true} onChange={this.onDorms}>Dorms</Checkbox>
                        <Checkbox style={{ position: "relative", background: "white", padding: "2px" }} defaultChecked={true} onChange={this.onGreeks}>Greeks</Checkbox>
                        <Checkbox style={{ position: "relative", background: "white", padding: "2px" }} defaultChecked={true} onChange={this.onHighrise}>Highrises</Checkbox>
                        <Checkbox style={{ position: "relative", background: "white", padding: "2px" }} defaultChecked={true} onChange={this.onOther}>Other</Checkbox>
                    </Space>
                    <div className="totalCount">
                        <b>Showing Results from</b><br></br>{moment(this.state.fromv).format('YYYY-MM-DD HH:mm:ss')} <br></br><b>to </b><br></br>{moment(this.state.tov).format('YYYY-MM-DD HH:mm:ss')}
                        <hr></hr>
                        <b>Positives: </b>{this.state.totalCount}
                        <br>
                        </br>
                        <b>Negatives: </b>{this.state.totalNegative}
                        <hr></hr>
                        <b>Unknown Location Positives:</b><br></br>
                        {this.state.unknownCount}
                    </div>
                    <div className="sliderContainer">
                        <div ref={el => this.slider = el}></div>
                    </div>
                </div>
            </div >
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h3>Test Smart Map</h3>
                <p>Log-in with your NetID.</p>
                <CustomOAuthButton variant="primary" size="lg">LOGIN</CustomOAuthButton>
            </div>
        );
    }

    renderUnauthorized() {
        return (
            <div className="lander">
                <h3>Test Smart Map</h3>
                <p>Log-in with your NetID</p>
                <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {(this.props.authn === 'signedIn' && this.props.authz && this.state.grpsOk) && this.renderDormForm()}
                {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
                {(this.props.authn !== 'signedIn') && this.renderLander()}
            </div>
        );
    }
}
