import React, { Component, createRef } from "react";
import TableauReport from 'tableau-react';
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class IndicatorDashboardTableauReact extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      ticket: null,
      error: false,
      gotWidth: false,
      tableauDivWidth: 0,
    };
    this.getTableauTicket = this.getTableauTicket.bind(this);
    this.tableauDivRef = createRef();
  }

  async componentDidUpdate(prevProps) {
    if (this.tableauDivRef.current && this.tableauDivRef.current.offsetWidth !== this.state.tableauDivWidth && !this.state.gotWidth) {
      this.setState({
        tableauDivWidth: this.tableauDivRef.current.offsetWidth,
        gotWidth: true,
      }, () => {
        console.log("*** state after componentDidUpdate: ", this.state);
      });
    }
    if (this.props.authn === 'signedIn' && this.props.authz !== prevProps.authz && this.props.authz) {
      this.setState({
        isLoading: false,
      });
      console.log("*** I am here in componentDidUpdate");
      this.getTableauTicket();
    }
  }

  async componentDidMount() {
    // check if we were redirected from Cognito
    this.setState({
      isLoading: this.props.location.search.startsWith("?code=") ? true : false,
      tableauDivWidth: this.tableauDivRef.current ? this.tableauDivRef.current.offsetWidth : 0,
      gotWidth: this.tableauDivRef.current ? true : false,
    }, () => {
      console.log("*** state after componentDidMount: ", this.state);
    });
    if (this.props.authn === 'signedIn' && this.props.authz) {
      console.log("*** I am here in componentDidMount");
      this.getTableauTicket();
    }
  }

  // get token from Tableau
  async getTableauTicket() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        }
      }
      return API.get("tableauEmbedTicket", "/tableauEmbedTicket", myInit)
      .then(res => {
        this.setState({
          ticket: res.ticket,
        }, () => {
          console.log("*** ticket = " + this.state.ticket);
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      this.setState({
        isLoading: false,
        error: true,
      });
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>COVID-19 Campus Indicators Dashboard</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to view dashboard</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>COVID-19 Campus Indicators Dashboard</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>COVID-19 Campus Indicators Dashboard</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderError() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>COVID-19 Campus Indicators Dashboard</h2>
              <div className="alert alert-danger" role="alert">Error retrieving dashboard.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDashboard() {
    return(
      <div>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>COVID-19 Campus Indicators Dashboard</h2>
            </div>
          </div>
          <div className="row">
            <div className="col mb-4">
              <div ref={this.tableauDivRef} style={{border: '2px solid rgb(219, 219, 219)'}}>
                {
                  this.state.ticket &&
                  <TableauReport
                    url='https://bi.arizona.edu/t/UA/views/COVID-19CampusStatusIndicators/CampusIndicators'
                    token={this.state.ticket}
                    // filters={filters}
                    // parameters={parameters}
                    options={{width: this.state.tableauDivWidth - 10, height: window.innerWidth > 768 ? 900 : 1200}} // vizCreate options
                    // Overwrite default query params
                    // defaults to '?:embed=yes&:comments=no&:toolbar=yes&:refresh=yes'
                    query="?:embed=yes&:comments=no&:customViews=yes&:refresh=yes&:showShareOptions=false&:toolbar=no"
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>    
    );
  }

  render() {
    return (
      <div className="Home">
        {this.state.isLoading && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && !this.state.isLoading && this.state.error)) && this.renderError()}
        {(this.props.authn === 'signedIn' && this.props.authz && !this.state.isLoading) && this.renderDashboard()}
        {(this.props.authn === 'signedIn' && !this.props.authz && !this.state.isLoading) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn' && !this.state.isLoading) && this.renderLander()}
        {/* {(this.props.authn !== 'signedIn' && this.state.isLoading) && this.renderLoading()} */}
      </div>
    );
  }
}
