import React, { Component } from "react";
import {Form} from "react-bootstrap";
import moment from "moment";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import { AuthenticatedLinkBase64 } from "../components/AuthenticatedLinkBase64";
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from "react-csv";
import config from "../config";

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

export default class GroupResults extends Component {
  constructor(props) {
    super(props);
    let now_str = moment().format("YYYY-MM-DD");
    this.state = {
      startDate: now_str,
      endDate: now_str,
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false
    };
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  onStartDateChange(event) {
    this.setState({
      startDate: event.target.value
    });
  }

  onEndDateChange(event) {
    this.setState({
      endDate: event.target.value
    });
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:testing-populations:rotc:groupermanagers',
      'arizona.edu:services:enterprise:ctrds:testing-populations:athletes:groupermanagers',
      'arizona.edu:services:enterprise:ctrds:testing-populations:dancers:groupermanagers',
      'arizona.edu:services:enterprise:ctrds:testing-populations:club-sports:groupermanagers'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({csvGroups: this.props.groups});
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getResults from componentDidUpdate");
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getResults from componentDidMount");
    // await this.getResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  reportCellFormatter(cell, row) {
    if (
      row.test_type === "PCR" && 
      (
        row.status === 4 ||
        row.status === 5
      )
    ) {
      return (
        <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/pcrLetterDownload?barcode=" + cell} filename={row.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64>
      );
    }
    if (
      ['QV Antigen', 'Antigen'].includes(row.test_type) && row.status === 5
    ) {
      return (
        <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/agLetterDownload?barcode=" + cell} filename={row.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64>
      );
    }    
    return(
      <span>&nbsp;</span>
    );
  }

  nameCellFormatter(cell, row) {
    return (
      <>
      {cell}
      { row.barcode.startsWith('UA89-') && !row.symptoms && <span className="label" style={{ backgroundColor: '#1E5288' }}>CTA</span> }
      { row.barcode.startsWith('UA89-') && row.symptoms && <span className="label" style={{ backgroundColor: '#AE10ED' }}>CTAS</span> }
      </>
    );
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({ isLoading: true });
    // console.log("*** inside getResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          from: this.state.startDate,
          to: this.state.endDate
        }
      }
      return API.get("groupResults", "/groupResults", myInit)
      .then(res => {
        if (Object.keys(res).length === 0 && res.constructor === Object) {
          this.setState({
            noResults: true,
            results: [],
            isLoading: false
          });
        } else {
          let _results = [];
          let id = 0;
          for (const [ key, value ] of Object.entries(res)) {
            // no tests
            if (value.tests.length === 0) {
              _results.push({
                id: ++id,
                name: value.full_name,
                emplid: value.emplid,
                phone: '',
                netid: key,
                groups: value.groups,
                dorm_name: '',
                test_type: 'None',
                test_result: 'N/A',
                status: '',
                status_time: '',
                collection_time: '',
                barcode: '',
                symptoms: false,
              });
            } else {
              let hasResult = false;
              let _tests = value.tests;
              for (let i = 0; i < _tests.length; i++) {
                if (_tests[i].test_type.length > 0) {
                  hasResult = true;
                  break;
                }
              }
              // if person has actual results, filter out empty results
              if (hasResult) {
                _tests = _tests.filter(
                  (t) => {
                    if (t.test_type.length > 0) {
                      return true;
                    }
                    return false;
                  }
                );
              } else {
                // filter out all but first occurrence of empty results
                _tests = [_tests[0]];
              }
              _results.push(
                ..._tests.map(
                  (t) => (
                    {
                      id: ++id,
                      name: value.full_name,
                      emplid: value.emplid,
                      phone: t.phone,
                      netid: key,
                      groups: value.groups,
                      dorm: t.dorm_name,
                      test_type: t.test_type ? (t.barcode.startsWith('UA79-') ? 'QV Antigen' : t.test_type) : 'None',
                      test_result: t.result ? t.result : 'N/A',
                      status: t.status ? t.status : 'No Tests',
                      status_time: t.status_time,
                      collection_time: t.collection_time,
                      barcode: t.barcode,
                      symptoms: t.symptoms
                    }
                  )
                )
              )
            }
          }
          this.setState({
            results: _results,
            noResults: false,
            isLoading: false
          });
        }
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <CustomOAuthButton variant="primary" size="lg">Login to View Test Results</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>View Test Results</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>View Test Results</h2>
              <h3>There are no test results to view.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateSelect() {
    return (
      <div className="row">
        <h3>View Test Results</h3>
        { 
          this.state.noResults &&
          <div className='alert alert-warning'>There are no test results in that date range.</div>
        }
        <Form onSubmit={this.onSubmit}>
          <div className='alert alert-info'>Date range is compared to result date.</div>
          <Form.Group className="col-md-6" controlId="formStartDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control type="date" placeholder="yyyy-mm-dd" onChange={this.onStartDateChange} defaultValue={this.state.startDate} />
          </Form.Group>

          <Form.Group className="col-md-6" controlId="formEndDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control type="date" placeholder="yyyy-mm-dd" onChange={this.onEndDateChange} defaultValue={this.state.endDate} />
          </Form.Group>

          <div className="col-md-2">
            <button className={`btn btn-primary`} onClick={this.getResults}>View Results</button>
          </div>
        </Form>
      </div>
    );
  }

  renderResults() {
    let columns = [
      {
        dataField: 'name',
        text: 'Name',
        formatter: this.nameCellFormatter,
        sort: true
      },
      {
        dataField: 'emplid',
        text: 'EMPLID'
      },
      {
        dataField: 'phone',
        text: 'Phone'
      },
      {
        dataField: 'netid',
        text: 'NetID',
        sort: true
      },
      {
        dataField: 'groups',
        text: 'Groups',
        sort: true
      },
      {
        dataField: 'dorm',
        text: 'Dorm',
        sort: true
      },
      {
        dataField: 'test_type',
        text: 'Test Type',
        sort: true
      },
      {
        dataField: 'collection_time',
        text: 'Collection Time',
        sort: true
      },
      {
        dataField: 'test_result',
        text: 'Test Result',
        sort: true
      },
      {
        dataField: 'status',
        text: 'Last Status',
        sort: true
      },
      {
        dataField: 'status_time',
        text: 'Last Status Time',
        sort: true
      },
      {
        dataField: 'barcode',
        text: 'Report',
        formatter: this.reportCellFormatter
      }
    ];
    const defaultSorted = [
      {
        dataField: 'status_time',
        order: 'desc'
      },
      {
        dataField: 'collection_time',
        order: 'desc'
      },
      {
        dataField: 'netid',
        order: 'asc'
      }
    ];

    return (
      <div>
        <div>
       </div>
        <BootstrapTable
          classes="table-responsive"
          keyField='id'
          data={ this.state.results }
          columns={ columns }
          caption={
            <div>
              <div className="alert alert-success text-white" role="alert"><strong>Results from {this.state.startDate} through {this.state.endDate}.</strong></div>
              <div className="callout callout-info">
                <p class="text-size-h3">Status Code Legend</p>
                <ol>
                  <li>
                    Vial with swab received at sample collection facility
                  </li>
                  <li>
                    Sample received at lab
                  </li>
                  <li>
                    Result received from lab
                  </li>
                  <li>
                    Result sent to results portal
                  </li>
                  <li>
                    Additional alerts sent for positive result
                  </li>
                  <li>
                    Sample rejected, sent to patient
                  </li>
                  <li>
                    Borderline positive &mdash; re-test required
                  </li>
                </ol>
                <p class="text-size-h3">Label Legend</p>
                <table style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label" style={{ backgroundColor: '#1E5288' }}>CTA</span></td>
                    <td>Cats TakeAway Testing <strong>without</strong> symptoms</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label" style={{ backgroundColor: '#AE10ED' }}>CTAS</span></td>
                    <td>Cats TakeAway Testing <strong>with</strong> symptoms</td>
                  </tr>
                </table>
              </div>
              <CSVLink
                data={
                  this.state.results.map(
                    (r) => (
                      {
                        name: r.name,
                        emplid: r.emplid,
                        phone: r.phone,
                        netid: r.netid,
                        groups: r.groups,
                        dorm: r.dorm_name,
                        test_type: r.test_type,
                        collection_time: r.collection_time,
                        test_result: r.test_result,
                        status: r.status,
                        status_time: r.status_time,
                      }
                    )
                  )
                }
                filename={"results_" + this.state.startDate.replace('-', '') + "_" + this.state.endDate.replace('-', '') + ".csv"}
                className="btn btn-primary"
                target="_blank" rel="noopener noreferrer">
                Download CSV
              </CSVLink>
            </div>
          }
          defaultSorted={ defaultSorted }
          hover
          striped
          condensed
        />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length === 0)) && this.renderDateSelect()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
