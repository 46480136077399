import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import moment from "moment";
import axios from 'axios';
import Spinner from 'react-spinkit';
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OffCampusGuardianConsent from "../components/OffCampusGuardianConsent";
import { stateOptions, testingLocationOptions, testingTypeOptions, testingResultOptions } from '../components/OffCampusPosResultChoices';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class OffCampusPosUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // grpsOk: false,
      test_type: '',
      test_result: '',
      testing_location: '',
      collection_date: null,
      result_date: null,
      covidSymptoms: null,
      phone: '',
      dorm_name: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      under18: false,
      consented: false,
      cc_code: null,
      file0: null,
      file1: null,
      signature: false,
      submitting: false,
      submitted: false,
      error: null,
      isLoading: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setCollectionDate = this.setCollectionDate.bind(this);
    this.setResultDate = this.setResultDate.bind(this);
    this.setPhoneNumber = this.setPhoneNumber.bind(this);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.authn === 'signedIn' && this.props.authz !== prevProps.authz && this.props.authz) {
      this.setState({
        isLoading: false,
      });
      this.lookupEmplid();
    }
  }

  async componentDidMount() {
    // check if we were redirected from Cognito
    this.setState({
      isLoading: this.props.location.search.startsWith("?code=") ? true : false,
    });
    if (this.props.authn === 'signedIn' && this.props.authz) {
      this.lookupEmplid();
    }
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleFileUpload(event, index) {
    this.setState({ ['file' + index]: event.target.files[0] })
  }

  setPhoneNumber(value, countryData) {
    this.setState({ phone: value });
  }

  setCollectionDate(date) {
    this.setState({ collection_date: date });
  }

  setResultDate(date) {
    this.setState({ result_date: date });
  }

  async lookupEmplid() {
    this.setState({
      isLoading: true
    }, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          },
          queryStringParameters: {
            emplid: session.idToken.payload['custom:emplid']
          }
        }
        return API.get("offCampusQuery", "/offCampusQuery", myInit)
          .then(res => {
            let full_name = res.name;
            let netid = res.netid;
            let eppa = res.affiliation;
            let under18 = res.under18;
            let consented = res.consented;
            let cc_code = res.hasOwnProperty('cc_code') ? res.cc_code : null;
            let dorm_name = '';
            let dorm_address = '';
            let dorm_city = '';
            let dorm_state = '';
            let dorm_zip = '';
            if (res.dorm_info) {
              dorm_name = res.dorm_info.dorm_name;
              dorm_address = res.dorm_info.dorm_address;
              dorm_city = res.dorm_info.dorm_city;
              dorm_state = res.dorm_info.dorm_state;
              dorm_zip = res.dorm_info.dorm_zip;
            }
            this.setState({
              isLoading: false,
              error: null,
              full_name: full_name,
              netid: netid,
              eppa: eppa,
              dorm_name: dorm_name,
              address1: dorm_address,
              city: dorm_city,
              state: dorm_state,
              zip: dorm_zip,
              under18: under18,
              consented: consented,
              cc_code: cc_code,
            });
          });
      }).catch(error => {
        this.setState({
          isLoading: false,
          error: 'retrieving bio/demo information failed'
        });
        console.log("Error in Auth.currentSession: " + error);
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
      submitting: true,
    }, () => {
      Auth.currentSession().then(session => {
        let uuid = uuidv4();
        const token = session.idToken.jwtToken;
        const data = {
          netid: this.props.netID,
          test_type: this.state.test_type,
          test_result: this.state.test_result,
          collection_timestamp: moment(this.state.collection_date).format('YYYY-MM-DD HH:mm:ss'),
          result_timestamp: moment(this.state.result_date).format('YYYY-MM-DD HH:mm:ss'),
          release_date: this.state.dorm_name.length > 0 ? moment(this.state.collection_date).add(10, 'd').format('YYYY-MM-DD') : moment(this.state.collection_date).add(5, 'd').format('YYYY-MM-DD'),
          dorm_name: this.state.dorm_name,
          address1: this.state.address1,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          phone: this.state.phone,
          testing_location: this.state.testing_location,
          txid: uuid,
          symptomatic: this.state.covidSymptoms === 'yes' ? true : false,
        };

        let myInit = {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          },
          body: data,
        }

        return API.post("offCampusResultPublish", "/offCampusResultPublish", myInit)
          .then(() => {
            let uploads = [];

            for (let i = 0; i < 2; i++) {
              if (this.state['file' + i]) {
                uploads.push(this.uploadFile(this.state['file' + i], token, uuid));
              }
            }

            if (uploads.length) {
              Promise.all(uploads)
                .then(response => {
                  this.setState({ error: null, submitted: true });
                })

            } else {
              this.setState({ error: null, submitted: true });
            }
          })
          .catch((error) => {
            console.log(error);
            var display = 'There was an error submitting your request. Please make sure you answered all questions and try again.'
            this.setState({ error: display, submitting: false });
          })
          .finally(() => {
            this.setState({
              isLoading: false,
            });
          })
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
      });
    })
  }

  async uploadFile(file, token, uuid) {
    const data = {
      txid: uuid,
      fileName: file.name,
      fileType: file.type,
    };

    let submission = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      },
      body: data,
    }

    // console.log(data);

    return API.post("offCampusPositiveResultUploadSig", "/offCampusPositiveResultUploadSig", submission)
      .then(result => {

        const url = result['url'];
        const formData = new FormData();

        formData.append('key', result['key']);
        formData.append('AWSAccessKeyId', result['AWSAccessKeyId']);
        formData.append('x-amz-security-token', result['x-amz-security-token']);
        formData.append('policy', result['policy']);
        formData.append('signature', result['signature']);
        formData.append('file', file)

        return axios.post(url, formData)
          .catch(error => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Off-Campus Positive Result Upload</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to submit result</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Off-Campus Positive Result Upload</h1>
        <div className="alert alert-danger" role="alert">You do not have permission to use this application.</div>
      </div>
    );
  }

  renderSubmitted() {
    return (
      <>
        <h2 className="col-xs-12">Off-Campus Positive Result Upload</h2>
        <div className="col-xs-12">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="3em" height="3em"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path></svg>
          <p className="my-4">Thank you for submitting your off-campus positive test result!</p>
          <p><strong>Important Note:</strong> You will now be restricted from taking on-campus tests, including TakeAway tests, for 60 days from the date of your positive result. If you need to test sooner, please contact the COVID Ambassador Team at <a href="mailto:covidhelp@arizona.edu">covidhelp@arizona.edu</a>.</p>
          <p>If you need a letter for a class or activity, please call Campus Health at 520-621-9202.</p>
        </div>
      </>
    );
  }

  renderForm() {
    const {
      test_type,
      test_result,
      testing_location,
      collection_date,
      result_date,
      phone,
      submitting,
      signature,
      consented,
      file0,
      file1,
      address1,
      city,
      state,
      zip,
      covidSymptoms,
    } = this.state;

    const submitEnabled = (
      !submitting &&
      test_type.length &&
      test_result.length &&
      testing_location.length &&
      collection_date &&
      result_date &&
      phone.length > 10 &&
      result_date >= collection_date &&
      (signature || consented) &&
      address1.length &&
      city.length &&
      state.length &&
      zip.length &&
      covidSymptoms !== null &&
      (file0 || file1)
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col col-md-8 col-md-offset-2">
            <h2>
              Off-Campus Positive Result Upload
            </h2>
          </div>
        </div>

        {this.state.under18 && !this.state.consented ?
          <OffCampusGuardianConsent
            netID={this.props.netID}
            name={this.props.name}
            consentCode={this.state.cc_code}
            link='https://redcap.uahs.arizona.edu/surveys/?s=RE4ML3NAJXYA3RTM'
            testType='offcampus_pos'>
          </OffCampusGuardianConsent>
          :
          <>
            <div className="row">
              <div className="col col-md-8 col-md-offset-2">
                <div className="callout callout-info">
                  Thank you for uploading your positive COVID-19 test result! This helps us track COVID-19 among our campus community.
                  <br /><br />
                  Please only upload results from off-campus testing locations. You do not need to upload results from Campus Health or Test All Test Smart.
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-2 col-md-offset-2">
                <div className="form-group">
                  <label tabIndex="0" htmlFor="test_type">
                    Test Type
                    <span className="text-red" title="required">*</span>
                  </label>
                  <select
                    value={this.state.test_type}
                    onChange={this.handleChange}
                    name='test_type'
                    id='test_type'
                    className='form-control'>
                    {testingTypeOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
              <div className="col col-md-2">
                <div className="form-group">
                  <label htmlFor="test_result">
                    Test Result
                    <span className="text-red" title="required">*</span>
                  </label>
                  <select
                    value={this.state.test_result}
                    onChange={this.handleChange}
                    name='test_result'
                    id='test_result'
                    className='form-control'>
                    {testingResultOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
              <div className="col col-md-4">
                <div className="form-group">
                  <label htmlFor="testing_location">
                    Test Location
                    <span className="text-red" title="required">*</span>
                  </label>
                  <select
                    value={this.state.testing_location}
                    onChange={this.handleChange}
                    name='testing_location'
                    id='testing_location'
                    className='form-control'>
                    {testingLocationOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="form-group">
                  <label htmlFor="collection_date">
                    Test Collection Date (date you took the test)
                    <span className="text-red" title="required">*</span>
                  </label>
                  <br />
                  <DatePicker
                    id="collection_date"
                    selected={this.state.collection_date}
                    onChange={date => this.setCollectionDate(date)}
                    minDate={new Date().setDate(new Date().getDate() - 14)} // no more than 14 days ago
                    maxDate={new Date()}
                  // placeholderText="Select end date"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="form-group">
                  <label htmlFor="result_date">
                    Test Result Date
                    <span className="text-red" title="required">*</span>
                  </label>
                  <br />
                  <DatePicker
                    id="result_date"
                    selected={this.state.result_date}
                    onChange={date => this.setResultDate(date)}
                    minDate={new Date().setDate(new Date().getDate() - 14)}  // no more than 14 days ago
                    maxDate={new Date()}
                  // placeholderText="Select end date"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="form-group">
                  <label style={{ fontWeight: 400 }}>
                    Do you have any symptoms of COVID-19?
                    <span className="text-red" title="required">*</span>
                    <ul>
                      <li>Fever or chills</li>
                      <li>Cough</li>
                      <li>Shortness of breath or difficulty breathing</li>
                      <li>Fatigue</li>
                      <li>Muscle or body aches</li>
                      <li>Headache</li>
                      <li>New loss of taste or smell</li>
                      <li>Sore throat</li>
                      <li>Congestion or runny nose</li>
                      <li>Nausea or vomiting</li>
                      <li>Diarrhea</li>
                    </ul>
                  </label>
                  <div class="radio mt-0">
                    <label htmlFor="covidSymptomsYes">
                      <input
                        type="radio"
                        name="covidSymptoms"
                        id="covidSymptomsYes"
                        checked={this.state.covidSymptoms === "yes"}
                        onChange={this.handleChange}
                        value="yes"
                      />
                      Yes
                    </label>
                  </div>
                  <div class="radio">
                    <label htmlFor="covidSymptomsNo">
                      <input
                        type="radio"
                        name="covidSymptoms"
                        id="covidSymptomsNo"
                        checked={this.state.covidSymptoms === "no"}
                        onChange={this.handleChange}
                        value="no"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="form-group">
                  <label htmlFor="phone">
                    Phone number (in case we need to contact you about your test result)
                    <span className="text-red" title="required">*</span>
                  </label>
                  <br></br>
                  <PhoneInput
                    country={'us'}
                    value={this.state.phone}
                    onChange={this.setPhoneNumber}
                    countryCodeEditable={false}
                    inputClass="form-control"
                    placeholder="please enter your phone number"
                    inputProps={{ id: 'phone', name: 'phone' }}
                  />
                  <small className="mb-1">Please provide a US number if you have one.</small>
                </div>
              </div>
            </div>
            {
              !this.state.dorm_name &&
              <>
                <div className="row mt-3">
                  <div className="col col-md-8 col-md-offset-2">
                    <label className="form-group mt-3">Current Address (for contact tracing purposes)</label>
                    <div className="mt-1 form-group">
                      <label>Street Address<span className="text-red">*</span></label>
                      <input
                        className="form-control"
                        type="text"
                        name="address1"
                        maxLength="100"
                        value={this.state.address1}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-md-4 col-md-offset-2">
                    <div className="form-group">
                      <label>City<span className="text-red">*</span></label>
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        maxLength="50"
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col col-md-2">
                    <div className="form-group">
                      <label>
                        State
                        <span className="text-red">*</span>
                      </label>
                      <select
                        value={this.state.state}
                        onChange={this.handleChange}
                        name='state'
                        className='form-control'
                      >
                        {stateOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col col-md-2">
                    <div className="form-group">
                      <label>
                        Zip Code
                        <span className="text-red">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="zip"
                        value={this.state.zip}
                        maxLength="5"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="form-group">
                  <label>
                    Please upload a copy of your positive COVID test result. If the result is from a pharmacy or lab, please ensure that the document clearly shows your name, the date of your test, the location of your test (e.g., Walgreens, CVS, etc.), and the test result. If the result is an at home test, please show the test itself. You may upload up to 2 files.
                    <span className="text-red" title="required">*</span>
                  </label>
                  <input type="file"
                    className="mt-1"
                    onChange={(e) => this.handleFileUpload(e, 0)}>
                  </input>
                  <input type="file"
                    className="mt-1"
                    onChange={(e) => this.handleFileUpload(e, 1)}>
                  </input>
                </div>
              </div>
            </div>
            {
              !this.state.under18 &&
              <div className="row mt-3">
                <div className="col col-md-8 col-md-offset-2">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <h3 className="panel-title">
                        Authorization for the Release of Coronavirus (COVID-19) Test Results
                      </h3>
                    </div>
                    <div className="panel-body">
                      <p><strong>Please carefully read and sign this authorization for the release of information:</strong></p>
                      <p>
                        By signing below, I (or my legally authorized representative on my behalf) am authorizing the University of Arizona (University) to release information pertaining to my COVID-19 test results received by the University to the individuals or units as described in this authorization.
                      </p>
                      <p>
                        The purpose of the collection of these results is to create information that can be used to help promote my and the campus' health and safety.
                      </p>
                      <p>
                        I authorize my name, demographics, contact information, COVID-19 test result, and other information pertaining to my COVID-19 test, to be released as outlined below:
                        <ul className="mt-2">
                          <li>
                            <strong>Campus Health</strong> for purposes of coordination of care and treatment.
                          </li>
                          <li>
                            <strong>Housing and Residential Life</strong> for students who live in on-campus housing only: for purposes of isolation and quarantine procedures and protocols.
                          </li>
                          <li>
                            <strong>SAFER</strong> (Student Aid for Field Epidemiology Response) for Pima County residents only: for purposes of contact tracing.
                          </li>
                          <li>
                            <strong>Facilities Management:</strong> disclosure of building and room number only for purposes of cleaning and disinfection protocols and situational awareness in responding to building alarms.
                          </li>
                          <li>
                            <strong>University Personnel:</strong> identifiable and de-identified information will be provided to the University's COVID-19 Data Governance Group, Public Health Advisory Coronavirus Team (PHACT), Incident Command System (ICS), and the COVID-19 Response Team for the purpose of campus community health monitoring and COVID-19 response efforts, including but not limited to, providing COVID-19 exposure notifications and informing community testing and/or sampling methods.
                          </li>
                        </ul>
                      </p>
                      <p>
                        I, or my legally authorized representative, have the right to revoke this authorization at any time by submitting a written notice of revocation to the Test, Trace, and Treat team at t3@arizona.edu. However, I understand that my records may have already been released. The information disclosed per this authorization may be subject to redisclosure and no longer protected by applicable federal or state laws.
                      </p>
                      <p>
                        <strong>EXPIRATION DATE:</strong> This authorization is good for one year from the date of signature.
                      </p>
                      <hr></hr>
                      <div className="input-group">
                        <label htmlFor="signature" className="text-red">
                          <input
                            name="signature"
                            id="signature"
                            type="checkbox"
                            checked={this.state.signature}
                            onChange={this.handleCheck} />&nbsp;&nbsp;<span><strong>Check the box to sign *</strong></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="row my-3">
              <div className="col col-md-8 col-md-offset-2">
                <input
                  disabled={!submitEnabled}
                  type="submit"
                  value="Submit"
                  className="btn btn-lg btn-blue">
                </input>
                {this.state.error &&
                  <p className="text-red mt-4">
                    <strong>Error: {this.state.error}</strong>
                  </p>
                }
              </div>
            </div>
          </>
        }
      </form>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Off-Campus Positive Result Upload</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {/* {(this.props.authn === 'signedIn' && this.props.authz && this.state.grpsOk && !this.state.submitted) && this.renderForm()} */}
        {this.state.isLoading && this.renderLoading()}
        {(this.props.authn === 'signedIn' && this.props.authz && !this.state.isLoading && !this.state.submitting && !this.state.submitted) && this.renderForm()}
        {/* {(this.props.authn === 'signedIn' && this.props.authz && this.state.grpsOk && this.state.submitted) && this.renderSubmitted()} */}
        {(this.props.authn === 'signedIn' && !this.state.isLoading && this.state.submitted) && this.renderSubmitted()}
        {/* {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()} */}
        {(this.props.authn !== 'signedIn' && !this.state.isLoading) && this.renderLander()}
      </div>
    );
  }
}
