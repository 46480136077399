import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import { CSVLink } from "react-csv";
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class PascCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      pascResults: [],
      grpsOk: false
    };
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:positive-antigen-sample-curator:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {
      this.setState({ csvGroups: this.props.groups });
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ isLoading: true, grpsOk: true });
        await this.getDormResults(this.props.groups);
        this.setState({ isLoading: false });
      }
    }
  }

  // download JSON data from API endpoint
  async getDormResults(groups) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("pascCsvData", "/pascCsvData", myInit)
        .then(res => {
          if (res.rows.length === 0) {
            this.setState({
              noResults: true,
              pascResults: []
            });
          } else {
            this.setState({
              pascResults: res.rows,
              noResults: false
            });
          }
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <CustomOAuthButton variant="primary" size="lg">Login to Download Borderlines CSV</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Borderlines CSV Download</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Borderlines CSV Download</h2>
              <h3>There are no results to download.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCSVDownload() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Borderlines CSV Download</h2>
              <CSVLink
                data={this.state.pascResults}
                filename={"borderlines.csv"}
                className="btn btn-primary btn-lg"
                target="_blank" rel="noopener noreferrer">
                Download CSV
              </CSVLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults)) && this.renderCSVDownload()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
