import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import { AuthenticatedLinkBase64 } from "../components/AuthenticatedLinkBase64";
import { DatePicker } from 'antd';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter, textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import moment from "moment";
import config from "../config";

import "../css/Home.css";

const { RangePicker } = DatePicker;

const testTypeOptions = {
  'ANTIGEN': 'ANTIGEN',
  'PCR': 'PCR'
};

export default class PosResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      posResults: [],
      grpsOk: false,
      resultLetters: false,
      campusHealth: false,
      firstSearch: true,
      startDate: moment().subtract(1, 'weeks'),
      endDate: moment()
    };
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.downloadCellFormatter = this.downloadCellFormatter.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let resultLetters = false;
    let campusHealth = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm',
      'arizona.edu:services:enterprise:ctrds:pos-results-dump-all'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({csvGroups: this.props.groups});
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          if (grp === 'arizona.edu:services:enterprise:ctrds:pos-results-dump-all') {
            resultLetters = true;
            campusHealth = true;
          }
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, resultLetters: resultLetters, campusHealth: campusHealth });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getPosResults from componentDidUpdate");
        // await this.getPosResults(this.props.groups);
        // this.setState({ isLoading: false });
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getPosResults from componentDidMount");
    // await this.getPosResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  handleDateRangeChange(value, dateString) {
    if (value === null) {
      this.setState({
        startDate: null,
        endDate: null
      })
    } else {
      this.setState({
        startDate: value[0],
        endDate: value[1]
      });
    };
  }

  downloadCellFormatter(cell, row) {
    if (this.state.resultLetters && row.testType === "PCR") {
      return (
        <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/pcrLetterDownload?barcode=" + row.barcode} filename={row.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64>
      );
    } else if (this.state.resultLetters && row.testType === "ANTIGEN" && !["BORDERLINE POSITIVE", "2ND BORDERLINE POSITIVE"].includes(row.testResult)) {
      return (
        <AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/agLetterDownload?barcode=" + row.barcode} filename={row.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64>
      );
    } else {
      return (
        <></>
      );
    }
  }

  netidCellFormatter(cell) {
    return cell.split('@')[0];
  }

  nameCellFormatter(cell, row) {
    return (
      <>
      {cell}
      { row.vax && <span className="label label-success">VX</span> }
      { row.prevTats && <span className="label label-default">TATS</span> }
      { row.prevChs && <span className="label label-default">CH</span> }
      { row.prevOff && <span className="label label-default">OFF</span> }
      { row.barcode.startsWith('UA89-') && !row.symptoms && <span className="label" style={{ backgroundColor: '#1E5288' }}>CTA</span> }
      { row.barcode.startsWith('UA89-') && row.symptoms && <span className="label" style={{ backgroundColor: '#AE10ED' }}>CTAS</span> }
      { row.maricopa && <span className="label label-danger">MC</span> }
      { row.dormRetest && <span className="label" style={{ backgroundColor: '#ff5d00' }}>DR</span> }
      </>
    );
  }

  testTypeCellFormatter(cell, row) {
    if (row.barcode.startsWith('UA79-')) {
      return 'QV ANTIGEN';
    }
    return testTypeOptions[cell];
  }

  // download JSON data from API endpoint
  async getPosResults(groups) {
    // console.log("*** inside getPosResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      this.setState({isLoading: true},
        () => {
          const token = session.idToken.jwtToken;
          let myInit = { // OPTIONAL
            headers: {
              Authorization: token
            },
            queryStringParameters: {
              'start_date': this.state.startDate.format('YYYY-MM-DD'),
              'end_date': this.state.endDate.format('YYYY-MM-DD')
            }
          }
          if (groups.includes('arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm')) {
            myInit['queryStringParameters']['dorm'] = '1'
          }
          return API.get("posResultsDump", "/posResultsDump", myInit)
          .then(res => {
            if (res.results.length === 0) {
              this.setState({
                noResults: true,
                posResults: [],
                isLoading: false,
                firstSearch: false,
              });
            } else {
              let _results = res.results.map((r, i) => {
                r.index = i;
                return r;
              });
              this.setState({
                posResults: _results,
                noResults: false,
                isLoading: false,
                firstSearch: false
              });
            }
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <CustomOAuthButton variant="primary" size="lg">Login to Download Positive Test Results</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Positive Test Results</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Positive Test Results</h2>
              <h3>There are no positive test results to download.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateRange() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-md-8 col-md-offset-2">
              <h2>Positive Test Results</h2>              
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-2 col-md-offset-2">
              <div className="form-group">
                <label htmlFor="testDate">Date Range&nbsp;</label>
                <RangePicker ref={el => this.rangePicker = el}
                  format="YYYY-MM-DD"
                  defaultValue={[this.state.startDate, this.state.endDate]}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </div>
            <div className="col-md-1 d-flex align-self-baseline">
              <button className="btn btn-primary btn-sm" onClick={() => this.getPosResults(this.props.groups)}>Search</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPosResults() {
    const groupSelectOptions = {
      'Athletes': 'Athletes',
      'Club Sports': 'Club Sports',
      'Dancers': 'Dancers',
      'ROTC': 'ROTC'
    };

    const testResultOptions = {
      'POSITIVE': 'POSITIVE',
      'DETECTED (POSITIVE)': 'DETECTED (POSITIVE)',
      'BORDERLINE POSITIVE': 'BORDERLINE POSITIVE',
      '2ND BORDERLINE POSITIVE': '2ND BORDERLINE POSITIVE'
    };

    const dormSelectOptions = {
      'Apache-Santa Cruz': 'Apache-Santa Cruz',
      'Arbol de la Vida': 'Arbol de la Vida',
      'Arizona-Sonora': 'Arizona-Sonora',
      'Babcock': 'Babcock',
      'Cochise': 'Cochise',
      'Coconino': 'Coconino',
      'Colonia de la Paz': 'Colonia de la Paz',
      'Coronado': 'Coronado',
      'Gila': 'Gila',
      'Graham-Greenlee': 'Graham-Greenlee',
      'Honors Village': 'Honors Village',
      'Hopi': 'Hopi',
      'Kaibab-Huachuca': 'Kaibab-Huachuca',
      'La Aldea Graduate Hall': 'La Aldea Graduate Hall',
      'Likins': 'Likins',
      'Manzanita-Mohave': 'Manzanita-Mohave',
      'Maricopa': 'Maricopa',
      'Navajo-Pinal': 'Navajo-Pinal',
      'Pima': 'Pima',
      'Posada San Pedro': 'Posada San Pedro',
      'Pueblo de la Cienega': 'Pueblo de la Cienega',
      'Villa Del Puente': 'Villa Del Puente',
      'Yavapai': 'Yavapai',
      'Yuma': 'Yuma'
    };

    let columns = [
      {
        dataField: 'index',
        text: "#",
        sort: true,
        headerStyle: {width: '50px'}
      },
      {
        dataField: 'name',
        text: 'Name',
        filter: textFilter(),
        formatter: this.nameCellFormatter,
        sort: true
      },
      {
        dataField: 'emplid',
        text: 'EMPLID',
        filter: textFilter()
      },
      {
        dataField: 'phone',
        text: 'Phone'
      },
      {
        dataField: 'email',
        text: 'NetID',
        filter: textFilter(),
        formatter: this.netidCellFormatter,
        sort: true
      },
      {
        dataField: 'dormName',
        text: 'Dorm',
        formatter: cell => dormSelectOptions[cell],
        filter: selectFilter({
          options: dormSelectOptions
        }),
        sort: true
      },
      {
        dataField: 'groups',
        text: 'Groups',
        sort: true,
        formatter: cell => groupSelectOptions[cell],
        filter: selectFilter({
          options: groupSelectOptions
        }),
        hidden: !this.state.campusHealth
      },
      {
        dataField: 'testType',
        text: 'Test Type',
        sort: true,
        formatter: this.testTypeCellFormatter,
        filter: selectFilter({
          options: testTypeOptions
        })
      },
      {
        dataField: 'testResult',
        text: 'Test Result',
        sort: true,
        formatter: cell => testResultOptions[cell],
        filter: selectFilter({
          options: testResultOptions
        })
      },
      {
        dataField: 'testDatetime',
        text: 'Result Date/Time',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'collectionDatetime',
        text: 'Collection Date/Time',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'releaseDate',
        text: 'Release Date (Est.)',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'dummyField1',
        text: 'Ltr',
        align: 'center',
        formatter: this.downloadCellFormatter,
        hidden: !this.state.resultLetters,
        sort: false,
        headerStyle: {width: '50px'}
      }
    ];
    const defaultSorted = [
      {
        dataField: 'index',
        order: 'asc'
      }
    ];
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <h2>Positive Test Results</h2>              
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="testDate">Date Range&nbsp;</label>
                <RangePicker ref={el => this.rangePicker = el}
                  format="YYYY-MM-DD"
                  defaultValue={[this.state.startDate, this.state.endDate]}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </div>
            <div className="col-md-1 d-flex align-self-baseline">
              <button className="btn btn-primary btn-sm" onClick={() => this.getPosResults(this.props.groups)}>Search</button>
            </div>
          </div>
          <div className="row">
            <div className="table-responsive">
              <div className="callout callout-info">
                <p class="text-size-h3">Label Legend</p>
                <table style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label label-success">VX</span></td>
                    <td>positive result at least 2 weeks after final vaccination dose</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label label-default">TATS</span></td>
                    <td>individual has previous Test All Test Smart positive result</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label label-default">CH</span></td>
                    <td>individual has previous Campus Health positive result</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label label-default">OFF</span></td>
                    <td>individual has previous off-campus positive result</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label" style={{ backgroundColor: '#1E5288' }}>CTA</span></td>
                    <td>Cats TakeAway Testing <strong>without</strong> symptoms</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label" style={{ backgroundColor: '#AE10ED' }}>CTAS</span></td>
                    <td>Cats TakeAway Testing <strong>with</strong> symptoms</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label label-danger">MC</span></td>
                    <td>individual is student or employee in Maricopa County</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'right', width: '50px'}}><span className="label" style={{ backgroundColor: '#ff5d00' }}>DR</span></td>
                    <td>dorm retest result</td>
                  </tr>
                </table>
              </div>
              <BootstrapTable
                keyField='id'
                data={this.state.posResults}
                columns={columns}
                defaultSorted={defaultSorted}
                filter={ filterFactory() }
                hover
                striped
                classes="table-responsive"
                bootstrap4
              />
              {/* <table className="table-striped table-dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>EMPLID</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Dorm</th>
                    { this.state.campusHealth && <th>Groups</th> }
                    <th>Test Type</th>
                    <th>Test Result</th>
                    <th>Result Date/Time</th>
                    <th>Collection Date/Time</th>
                    <th>Release Date (Est.)</th>
                    {this.state.resultLetters && <th>Report</th>}
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.posResults.map((r, i) => (
                      <tr>
                        <td>{i+1}</td>
                        <td>{r.name}{r.multi && <sup style={{color: "red"}}>&#42;</sup>}</td>
                        <td>{r.emplid}</td>
                        <td>{r.phone}</td>
                        <td>{r.email}</td>
                        <td>{r.dormName}</td>
                        { this.state.campusHealth && r.hasOwnProperty('groups') && <td>{r.groups}</td> }
                        <td>{r.testType}</td>
                        <td>{r.testResult}</td>
                        <td>{r.testDatetime}</td>
                        <td>{r.collectionDatetime}</td>
                        <td>{r.releaseDate}</td>
                        { this.state.resultLetters && r.testType === "PCR" && <td style={{textAlign: "center"}}><AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/pcrLetterDownload?barcode=" + r.barcode} filename={r.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64></td> }
                        { this.state.resultLetters && r.testType === "ANTIGEN" && !["BORDERLINE POSITIVE", "2ND BORDERLINE POSITIVE"].includes(r.testResult) && <td style={{ textAlign: "center" }}><AuthenticatedLinkBase64 url={config.apiGatewayStack2.URL + "/agLetterDownload?barcode=" + r.barcode} filename={r.emplid + ".pdf"}><i className="ua-brand-download"></i></AuthenticatedLinkBase64></td>}
                        { this.state.resultLetters && r.testType !== "PCR" && r.testType !== "ANTIGEN" && <td></td>}
                        { this.state.resultLetters && r.testType === "ANTIGEN" && ["BORDERLINE POSITIVE", "2ND BORDERLINE POSITIVE"].includes(r.testResult) && <td></td>}
                      </tr>
                    ))
                  }
                </tbody>
              </table> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults && !this.state.firstSearch)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.firstSearch)) && this.renderDateRange()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && !this.state.firstSearch)) && this.renderPosResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
