import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import moment from "moment";
import { FaWifi } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { ReactComponent as SwabIcon } from '../images/swab_test.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import "../css/FixedTableHeader.css";
import "../css/Home.css";

const statusClassMap = {
  'OPEN': 'text-azurite',
  'APPROVED': 'text-leaf',
  'DENIED': 'text-chili',
  'VERIFIED': 'text-leaf',
  'NOT VERIFIED': 'text-chili'
};

const remoteStatusMap = {
  'OPEN': 'OPEN',
  'APPROVED': 'VERIFIED',
  'DENIED': 'NOT VERIFIED'
};

const reasonMap = {
  'MED': 'Medical',
  'DIS': 'Disability',
  'REM': 'Learning Remotely',
  'REL': 'Sincerely Held Religious Belief',
  'OTH': 'Special Circumstance',
  'TOTAL': 'Total'
};

export default class Accommodations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noRequests: false,
      requests: [],
      grpsOk: false,
      addResult: false,
      status: null,
      wifiAllowed: null,
      antigenAllowed: null,
      notes: null,
      referGroup: 'default',
      reviewRequest: {},
      referRequest: {},
      isReview: false,
      isRefer: false,
      showStats: false,
      stats: {},
      files: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleWifiAllowedChange = this.handleWifiAllowedChange.bind(this);
    this.handleAntigenAllowedChange = this.handleAntigenAllowedChange.bind(this);
    this.getRequests = this.getRequests.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.resolveRequest = this.resolveRequest.bind(this);
    this.referRequest = this.referRequest.bind(this);
    this.prepReviewData = this.prepReviewData.bind(this);
    this.prepReferData = this.prepReferData.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;

    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:testing-exemptions:medical:admins',
      'arizona.edu:services:enterprise:ctrds:testing-exemptions:disability:admins',
      'arizona.edu:services:enterprise:ctrds:testing-exemptions:religious:admins',
      'arizona.edu:services:enterprise:ctrds:testing-exemptions:remote:admins',
      'arizona.edu:services:enterprise:ctrds:testing-exemptions:special:admins'
    ];

    if (this.props.groups !== prevProps.groups) {
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        await this.getRequests(this.props.groups);
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // handle wifi allowed change
  handleWifiAllowedChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === 'T') {
      this.setState({ antigenAllowed: 'F'});
    }
  }

  // handle antigen allowed change
  handleAntigenAllowedChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === 'T') {
      this.setState({ wifiAllowed: 'F'});
    }
  }

  // prepare info for request review form
  prepReviewData(id, status, name, netid, reqType, reqTime, endDate) {
    this.setState({
      reviewRequest: {
        id: id,
        status: status,
        name: name,
        netid: netid,
        reqType: reqType,
        reqTime: reqTime,
        endDate: endDate
      },
      status: ['APPROVED', 'VERIFIED'].includes(status) ? 'APPROVED' : 'DENIED',
      isReview: true
    });
  }

  // prepare info for request refer form
  prepReferData(id, name, netid, reqType, reqTime, endDate) {
    this.setState({
      referRequest: {
        id: id,
        name: name,
        netid: netid,
        reqType: reqType,
        reqTime: reqTime,
        endDate: endDate
      }
    }, () => {
      this.setState({
        isRefer: true
      })
    });
  }

  // download JSON data from API endpoint
  async getRequests() {
    this.setState({ isLoading: true}, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          }
        }
        return API.get("testExemptionRequestList", "/testExemptionRequestList", myInit)
        .then(res => {
          if (res.requests.length === 0) {
            this.setState({
              isLoading: false,
              noRequests: true,
              requests: [],
              stats: res.stats
            });
          } else {
            this.setState({
              isLoading: false,
              noRequests: false,
              stats: res.stats,
              requests: res.requests
            });
          }
        });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }

  // get file data for request
  async getFiles(uuid) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        body: {
          uuid: uuid
        }
      }
      return API.post("testExemptionListFiles", "/testExemptionListFiles", myInit)
      .then(res => {
        if (res.files.length > 0) {
          this.setState({
            files: res.files,
          });
        }
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  // resolve request
  async resolveRequest() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        body: {
          req_id: this.state.reviewRequest.id,
          status: this.state.status,
          wifi_allowed: this.state.wifiAllowed === 'T' ? true : false,
          antigen_allowed: this.state.antigenAllowed === 'T' ? true : false,
          notes: this.state.notes
        }
      }
      return API.post("testExemptionResolve", "/testExemptionResolve", myInit)
      .then(res => {
        if (this.state.status === 'APPROVED') {
          toast.success("Request approved");
        } else {
          toast.warning("Request denied");
        }
        this.setState({
          status: null,
          wifiAllowed: null,
          antigenAllowed: null,
          notes: null,
          reviewRequest: {},
          isReview: false,
          isLoading: true
        }, () => {
          this.getRequests();
        });
      });
    }).catch(error => {
      console.log("Error: " + error);
      toast.error(error.response.data.errmsg);
      this.setState({
        status: null,
        wifiAllowed: null,
        antigenAllowed: null,
        notes: null,
        reviewRequest: {},
        isReview: false
      });
    });
  }

  // refer request
  async referRequest() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        body: {
          req_id: this.state.referRequest.id,
          new_reason: this.state.referGroup,
          notes: this.state.notes
        }
      }
      return API.post("testExemptionRefer", "/testExemptionRefer", myInit)
      .then(res => {
        toast.success(<div>Request referred to <strong>{reasonMap[this.state.referGroup]}</strong> review group</div>);
        this.setState({
          isRefer: false,
          isLoading: true,
          status: null,
          notes: null,
          referGroup: 'default',
          referRequest: {}
        }, () => {
          this.getRequests();
        });
      });
    }).catch(error => {
      console.log("Error: " + error);
      toast.error(error.response.data.errmsg);
      this.setState({
        isRefer: false,
        status: null,
        notes: null,
        referGroup: 'default',
        referRequest: {}
      });
    });
  }

  // display text explanation for request
  displayReason(reason) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <h4 className="card-title">Request Reason</h4>
            <p>{reason}</p>
            <button className="btn btn-primary"onClick={onClose}>CLOSE</button>
          </div>
        );
      }
    });
  }

  // display notes for request
  displayNotes(notes) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <h4 className="card-title">Notes</h4>
            <ul>
            {
              notes.split('~~~').map((n, i) => (
                <li key={`note-${i}`}>
                  {n}
                </li>
              ))
            }
            </ul>
            <button className="btn btn-primary"onClick={onClose}>CLOSE</button>
          </div>
        );
      }
    });
  }

  // display files available for download
  async displayFiles(uuid) {
    await this.getFiles(uuid);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <div className="card-title">
              <h4>
                Files
              </h4>
              <h5><em>(Links expire after 30 minutes)</em></h5>
            </div>
            <table>
              <tbody>
                {
                  this.state.files.map((f, i) => (
                    <tr key={`file-${i}`}>
                      <td>
                        {f.filename}
                      </td>
                      <td>
                        <a href={f.url}><i className="ua-brand-download text-blue"></i></a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <button className="btn btn-primary" onClick={onClose}>CLOSE</button>
          </div>
        );
      }
    });
  }

  // display request referral form
  renderReferForm() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col col-md-8 cold-md-offset-2">
              <h2>Accommodations Admin</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h3>
                <strong>Refer Request</strong>
              </h3>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-6 col-md-offset-3">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Requester:</strong></td>
                    <td>{this.state.referRequest.name} ({this.state.referRequest.netid})</td>
                  </tr>
                  <tr>
                    <td><strong>Accommodation Type:</strong></td>
                    <td>{this.state.referRequest.reqType}</td>
                  </tr>
                  <tr>
                    <td><strong>Request Date:</strong></td>
                    <td>{moment(this.state.referRequest.reqTime).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Accommodation End Date:</strong></td>
                    <td>{moment(this.state.referRequest.endDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Action:</strong></td>
                    <td><span className="text-azurite"><strong>REFER</strong></span></td>
                  </tr>
                </tbody>
              </table>
              <form>
                <div className="form-group">
                  <label>
                    Select group to refer request to:
                    <span className="text-red">*</span>
                  </label>
                  <select
                    value={this.state.referGroup}
                    onChange={this.handleChange}
                    name='referGroup'
                    className='form-control'>
                    <option key='default' value='default'>Select referral group...</option>
                    {
                      ['MED','DIS','REL','OTH'].filter(key => key !== this.state.referRequest.reqType).map((key, idx) => {
                        return (<option key={`refer-${idx}`} value={key}>{reasonMap[key]}</option>);
                      })
                    }
                  </select>
                </div>                
                <div className="form-group">
                  <label htmlFor="notes"><em>Optional</em> &mdash; Enter a note related to this referral</label>
                  <input type="text" className="form-control" name="notes" placeholder="Enter note..." onChange={this.handleChange}/>
                </div>
              </form>
              <button 
                className="btn btn-default mr-2"
                onClick={
                  () => {
                    this.setState({
                      isRefer: false,
                      status: null,
                      notes: null,
                      referGroup: 'default',
                      referRequest: {}
                    }) 
                  }
                }
              >
                Cancel
              </button>
              <button 
                className="btn btn-primary"
                disabled={this.state.referGroup === 'default' ? true : false}
                onClick={this.referRequest}
              >SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // display request review form
  renderReviewForm() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col col-md-8 cold-md-offset-2">
              <h2>Accommodations Admin</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h3>
                <strong>Review Request</strong>
              </h3>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-6 col-md-offset-3">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Requester:</strong></td>
                    <td>{this.state.reviewRequest.name} ({this.state.reviewRequest.netid})</td>
                  </tr>
                  <tr>
                    <td><strong>Accommodation Type:</strong></td>
                    <td>{this.state.reviewRequest.reqType}</td>
                  </tr>
                  <tr>
                    <td><strong>Request Date:</strong></td>
                    <td>{moment(this.state.reviewRequest.reqTime).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Accommodation End Date:</strong></td>
                    <td>{moment(this.state.reviewRequest.endDate).format('MMM D, YYYY')}</td>
                  </tr>
                  <tr>
                    <td><strong>Action:</strong></td>
                    <td><span className={statusClassMap[this.state.reviewRequest.status]}><strong>{this.state.reviewRequest.status}</strong></span></td>
                  </tr>
                </tbody>
              </table>
              <form>
                {
                  this.state.reviewRequest.status === "APPROVED" &&            
                  <div className="input-group">
                    <label>UA WiFi Access
                      <span className="text-red">*</span>
                    </label>
                    <div className="radio">
                      <label>
                        <input type="radio" name="wifiAllowed" value="T" checked={this.state.wifiAllowed === "T"} onChange={this.handleWifiAllowedChange}/>
                        Approved &mdash; <strong>UA WiFi access until the end date of the accommodation</strong>
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="wifiAllowed" value="F" checked={this.state.wifiAllowed === "F"} onChange={this.handleWifiAllowedChange}/>
                        Approved &mdash; <strong>Must test to access UA WiFi</strong>
                      </label>
                    </div>
                  </div>
                }
                {
                  this.state.reviewRequest.status === "APPROVED" &&            
                  <div className="input-group">
                    <label>Antigen Tests
                      <span className="text-red">*</span>
                    </label>
                    <div className="radio">
                      <label>
                        <input type="radio" name="antigenAllowed" value="T" checked={this.state.antigenAllowed === "T"} onChange={this.handleAntigenAllowedChange}/>
                        Approve <strong>with</strong> Antigen Tests
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                        <input type="radio" name="antigenAllowed" value="F" checked={this.state.antigenAllowed === "F"} onChange={this.handleAntigenAllowedChange}/>
                        Approve <strong>without</strong> Antigen Tests
                      </label>
                    </div>
                  </div>
                }
                <div className="form-group">
                  <label htmlFor="notes"><em>Optional</em> &mdash; Enter a note related to this referral</label>
                  <input type="text" className="form-control" name="notes" placeholder="Enter note..." onChange={this.handleChange}/>
                </div>
              </form>
              <button 
                className="btn btn-default mr-2"
                onClick={
                  () => {
                    this.setState({
                      isReview: false,
                      status: null,
                      notes: null,
                      wifiAllowed: null,
                      antigenAllowed: null,
                      reviewRequest: {}
                    }) 
                  }
                }
              >
                Cancel
              </button>
              <button 
                className="btn btn-primary"
                disabled={(this.state.reviewRequest.status === 'APPROVED' && this.state.wifiAllowed === null) ? true : false}
                onClick={this.resolveRequest}
              >SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Accommodations Admin</h1>
        <p>This page is for reviewing Reasonable Accommodation / 100% Remote Learning Declaration requests.</p>
        <CustomOAuthButton variant="primary" size="lg">Accommodations Admin Login</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Accommodations Admin</h1>
        <p>This page is for reviewing Reasonable Accommodation / 100% Remote Learning Declaration requests.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Accommodations Admin</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoRequests() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Accommodations Admin</h2>
              <h3>There are no Reasonable Accommodation / 100% Remote Learning Declaration requests.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRequestList() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col col-md-6 margin-align-middle">
              <p className="text-size-h2 margin-zero-top margin-zero-bottom" style={{ fontFamily: '"PT Serif", serif', color: '#49595e'}}>Accommodations Admin&nbsp;&nbsp;
              <button type="button" className="btn btn-sm btn-primary margin-zero-top margin-zero-bottom" onClick={() => { this.getRequests(); }}>
                <i className="btn-icon ua-brand-refresh">&nbsp;</i>
              </button>
              &nbsp;&nbsp;
              <button
                style={{ fontFamily: '"Open Sans", sans-serif' }}
                type="button" 
                className="btn btn-success margin-zero-top margin-zero-bottom"
                onClick={() => { this.setState({ showStats: !this.state.showStats }) }}
              >
                {
                  this.state.showStats ?
                    'Hide Stats'
                  :
                    'Show Stats'
                }
              </button>
              </p>
            </div>
          </div>
          {
            this.state.showStats &&
            <div className="row" >
              <div className="col col-md-12">
                <table className="table-striped table-dark">
                  <thead>
                    <tr>
                      <th>Accommodation Type</th>
                      <th><center>Approved</center></th>
                      <th><center>Denied</center></th>
                      <th><center>Pending</center></th>
                      <th><center>Total Requested</center></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ['MED', 'DIS', 'REM', 'REL', 'OTH', 'TOTAL'].map((r, i) => (
                        <tr key={`stat-${i}`}>
                          <td>
                            {reasonMap[r]}
                          </td>
                          <td>
                            <center>{this.state.stats[r].approved}</center>
                          </td>
                          <td>
                            <center>{this.state.stats[r].denied}</center>
                          </td>
                          <td>
                            <center>{this.state.stats[r].open}</center>
                          </td>
                          <td>
                            <center>{this.state.stats[r].requested}</center>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
          <div className="row">
            <div className="col col-md-12">
              <div>
                <table className="table-striped table-dark table-hover">
                  <thead>
                      <tr>
                        <th className="fixed-header">#</th>
                        <th className="fixed-header">Status</th>
                        <th className="fixed-header">Submission Time</th>
                        <th className="fixed-header">Last Update Time</th>
                        <th className="fixed-header">Name</th>
                        <th className="fixed-header">EMPLID</th>
                        <th className="fixed-header">Phone</th>
                        <th className="fixed-header">NetID</th>
                        <th className="fixed-header">Affiliation</th>
                        <th className="fixed-header">City/State</th>
                        <th className="fixed-header">Request Type</th>
                        <th className="fixed-header">Reason</th>
                        <th className="fixed-header">End Date</th>
                        <th className="fixed-header">Files</th>
                        <th className="fixed-header">Notes</th>
                        <th className="fixed-header">Review / Reviewed By</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.requests.map((r, i) => (
                        <tr key={r.id}>
                          <td>{i+1}</td>
                          {
                            r.reason === 'REM' ?
                              <td><strong><span className={statusClassMap[r.status]}>{remoteStatusMap[r.status]}</span></strong></td>
                            :
                              <td>
                                <strong>
                                  <span className={statusClassMap[r.status]}>{r.status}
                                  {
                                    r.status === 'APPROVED' && r.wifi_allowed &&
                                      <>
                                      &nbsp;
                                      <IconContext.Provider value={{ color: "#001D48" }}>
                                        <FaWifi title="WiFi Access Approved" />
                                      </IconContext.Provider>
                                      </>
                                  }
                                  {
                                    r.status === 'APPROVED' && r.antigen_allowed &&
                                      <>
                                      &nbsp;
                                      <SwabIcon width="32px" height="32px" title="Antigen Nasal Swab Testing Approved" style={{ color: "#001D48" }} />
                                      </>
                                  }
                                  </span>
                                </strong>
                              </td>
                          }
                          <td>{r.create_timestamp}</td>
                          <td>{r.last_update_timestamp}</td>
                          <td>{r.name}</td>
                          <td>{r.emplid}</td>
                          <td>{r.phone}</td>
                          <td>{r.netid}</td>
                          <td>
                            {
                              r.affil === 'Student' ?
                                  r.dorm_name === null ?
                                    'Off-Campus Student'
                                  :
                                    'On-Campus Student'
                                :
                                  r.affil
                            }
                          </td>
                          <td>{r.city}, {r.state}</td>
                          <td>{r.reason_txt}</td>
                          {
                            r.reason !== 'REM' ?
                              <td><center><a onClick={() => this.displayReason(r.explanation)}><i className="ua-brand-search text-chili"></i></a></center></td>
                            :
                              <td></td>
                          }
                          <td>{r.exempt_end_date}</td>
                          {
                            r.has_files ?
                              <td><center><a onClick={() => this.displayFiles(r.file_uuid)}><i className="ua-brand-download text-chili"></i></a></center></td>
                            :
                              <td></td>
                          }
                          {
                            r.notes && r.notes.length ?
                              <td><center><a onClick={() => this.displayNotes(r.notes)}><i className="ua-brand-search text-chili"></i></a></center></td>
                            :
                              <td></td>
                          }
                          {
                            r.status === "OPEN" ?
                              <td>
                                <div className="btn-group">
                                  <button type="button" className="btn btn-hollow-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Options <span className="caret"></span>
                                  </button>
                                  {
                                    r.reason !== "REM" ?
                                      <ul className="dropdown-menu">
                                        <li><a onClick={ () => this.prepReviewData(r.id, 'APPROVED', r.name, r.netid, r.reason_txt, r.create_timestamp, r.exempt_end_date) }>APPROVED</a></li>
                                        <li><a onClick={ () => this.prepReviewData(r.id, 'DENIED', r.name, r.netid, r.reason_txt, r.create_timestamp, r.exempt_end_date) }>DENIED</a></li>
                                        <li><a onClick={ () => this.prepReferData(r.id, r.name, r.netid, r.reason_txt, r.create_timestamp, r.exempt_end_date) }>REFER</a></li>
                                      </ul>
                                    :
                                      <ul className="dropdown-menu">
                                        <li><a onClick={ () => this.prepReviewData(r.id, 'VERIFIED', r.name, r.netid, r.reason_txt, r.create_timestamp, r.exempt_end_date) }>VERIFIED</a></li>
                                        <li><a onClick={ () => this.prepReviewData(r.id, 'NOT VERIFIED', r.name, r.netid, r.reason_txt, r.create_timestamp, r.exempt_end_date) }>NOT VERIFIED</a></li>
                                      </ul>
                                  }
                                </div>
                              </td>
                            :
                              <td>{r.reviewer_netid}</td>
                          }
                        </tr>
                      ))
                    }
                    </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noRequests)) && this.renderNoRequests()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noRequests && !this.state.isRefer && !this.state.isReview)) && this.renderRequestList()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.isRefer)) && this.renderReferForm()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.isReview)) && this.renderReviewForm()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
