import React, { Component } from "react";
import { API, Auth } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import CustomOAuthButton from '../CustomOAuthButton';
import 'react-toastify/dist/ReactToastify.css';

import "../css/Home.css";

export default class SampleInvalidate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      apiCode: '',
      barcode: '',
      username: '',
      password: '',
      staffNetid: null,
      netidLogin: false,
      loggedIn: false,
      loginFailed: false
    };

    this.getAPICodeStatic = this.getAPICodeStatic.bind(this);
    this.getAPICodeCognito = this.getAPICodeCognito.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteTestSample = this.deleteTestSample.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:label-print:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        Auth.currentUserInfo().then(res => {
          // console.log(res);
          if (res.attributes.hasOwnProperty('custom:netid')) {
            this.setState({ staffNetid: res.attributes['custom:netid'] });
          }
        });

        this.setState(
          { netidLogin: true },
          async () => { 
            await this.getAPICodeCognito();
          }
        );
      } else {
        this.setState({ loggedIn: false, netidLogin: true });
      }
    }
    if (this.state.setupComplete !== prevState.setupComplete) {
      console.log("*** focus on netid from componentDidUpdate");
      this.netidInput.current.focus();
    }
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  // get API token for label data API
  async getAPICodeStatic(event) {
    // console.log("*** inside getAPICode")
    // console.log(groups);
    let myInit = { // OPTIONAL
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        username: this.state.username,
        password: this.state.password
      }
    };
    API.post("labelPrintAuthzStatic", "/labelPrintAuthzStatic", myInit)
      .then(res => {
        this.setState({
          apiCode: res.apiCode,
          username: '',
          password: '',
          loginFailed: false,
          loggedIn: true,
        });
      }).catch(error => {
        console.log("Error in calling labelPrintAuthzStatic: " + error);
        this.setState({
          loginFailed: true
        });
      });
    event.preventDefault();
  }

  // get API token for label data API, using Cognito Auth
  async getAPICodeCognito() {
    console.log("*** inside getAPICodeCognito")
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      };
      return API.get("labelPrintAuthz", "/labelPrintAuthz", myInit)
        .then(res => {
          this.setState({
            apiCode: res.apiCode,
            username: '',
            password: '',
            loginFailed: false,
            loggedIn: true,
          });
        }).catch(error => {
          console.log("Error in calling labelPrintAuthz: " + error);
          this.setState({
            loginFailed: true
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // hit sampleCollectionDelete endpoint to delete test sample record
  async deleteTestSample() {
    let myInit = {
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        apiCode: this.state.apiCode,
        barcode: this.state.barcode,
        staff_netid: (this.state.staffNetid === null) ? 'self-check-in' : this.state.staffNetid,
      }
    };
    let barcode = this.state.barcode;
    return API.post("sampleCollectionDelete", "/sampleCollectionDelete", myInit)
      .then(() => {
        this.setState({
          barcode: ''
        }, () => {
          toast.success(<div>Invalidated barcode <strong>{barcode}</strong>.</div>);
        });
      })
      .catch(err => {
        console.log(err);
        toast.error(<div>Error: <strong>{err.response.data.errmsg}</strong></div>);
        console.log("*** error from sampleCollectionDelete: " + err.response.data.errmsg);
        this.setState({
          barcode: ''
        });
      });
  }

  renderLogin() {
    return (
      <>
        <div className="row">
          <div className="col col-md-offset-2 col-md-8 col-sm-12 col-xs-12">
            <h1>Test All Test Smart Sample Invalidation</h1>
            <div className="callout callout-info">
              <strong>Login Required</strong>
              <br/><br/>
              If you have a UA NetID, please use the NetID Login. Otherwise, please login with the shared staff username/password using the form below.
              <br/><br/>
              <CustomOAuthButton variant="primary" size="lg">NetID Login</CustomOAuthButton>
            </div>
            {
              this.state.loginFailed ?
                <div className="alert alert-danger" role="alert">Invalid username or password</div>
                :
                <div className="alert alert-warning" role="alert">Login here <strong>ONLY</strong> if you do not have a NetID</div>
            }
          </div>
        </div>
        <div className="row mb-3">
          <div className="col col-md-offset-3 col-md-6 col-sm-12 col-xs-12">
            <form>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input type="text" className="form-control" name="username" id="username" placeHolder="Enter username" value={this.state.username} onChange={this.handleChange} />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" name="password" id="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
              </div>
              <button type="submit" className="btn btn-red" onClick={this.getAPICodeStatic}>Submit</button>
            </form>
          </div>
        </div>
      </>
    );
  }

  renderForm() {
    return (
      <>
        <div className="row">
          <div className="col col-md-offset-2 col-md-8">
            <h1>Test All Test Smart Sample Invalidation</h1>
            <div className="alert alert-info" role="alert">Please enter the barcode of the sample you wish to invalidate, then click <strong>INVALIDATE</strong>.</div>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-md-offset-2 col-md-8">
            <div className="input-group">
              <label htmlFor="barcode">
                Test Sample Barcode
              </label>
              <div className="d-flex align-self-end">
                <input type="text" className="form-control" name="barcode" value={this.state.barcode} onChange={this.handleChange} placeholder="Enter barcode value of sample..." />
                <span className="input-group-btn">
                  <button className="btn btn-red" onClick={this.deleteTestSample} disabled={this.state.barcode === ''}>Invalidate</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Self Check-In</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          style={{ width: "500px" }}
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        { this.state.netidLogin && !this.state.loggedIn && this.renderUnauthorized() }
        { this.state.loggedIn && this.renderForm()}
        { !this.state.loggedIn && !this.state.netidLogin && this.renderLogin() }
      </div>
    );
  }
}