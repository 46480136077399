import React, { Component } from "react";
import moment from "moment";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

export default class TarResults extends Component {
  constructor(props) {
    super(props);
    let now_str = moment().format("YYYY-MM-DD");
    this.state = {
      testDate: now_str,
      testType: 'AG',
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false,
      isAdmin: false,
      summary: {},
      updateRequest: {},
      isUpdate: false,
      updateSubmit: false,
      testResult: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.getResults = this.getResults.bind(this);
    this.updateCellFormatter = this.updateCellFormatter.bind(this);
    this.updateSample = this.updateSample.bind(this);
    this.prepUpdateData = this.prepUpdateData.bind(this);
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let adminGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:test-aging-report:allowed-users'
    ];
    let adminGroups = [
      'arizona.edu:services:enterprise:ctrds:qs2-publish-admin'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({ csvGroups: this.props.groups });
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      // see if any of admin groups are in our group set
      for (let grp of adminGroups) {
        if (this.props.groups.includes(grp)) {
          adminGroup = true;
        }
      }
      
      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getResults from componentDidUpdate");
      }
      if (adminGroup) {
        this.setState({ isAdmin: true });
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getResults from componentDidMount");
    // await this.getResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({
      isLoading: true,
      noResults: false,
      results: [],
      summary: {}
    });
    // console.log("*** inside getResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          date: this.state.testDate,
          test_type: this.state.testType
        }
      }
      return API.post("tarResults", "/tarResults", myInit)
        .then(res => {
          if (Object.keys(res).length === 0 && res.constructor === Object) {
            this.setState({
              noResults: true,
              results: [],
              summary: {},
              isLoading: false
            });
          } else {
            let _results = [];
            let id = 0;
            for (const [key, value] of Object.entries(res.results)) {
              _results.push({
                id: ++id,
                barcode: value.barcode,
                status: value.status,
                collection_time: value.collection_time,
                status_time: value.status_time,
                elapsed: value.elapsed,
              })
            }
            this.setState({
              results: _results,
              summary: res.summary,
              noResults: false,
              isLoading: false
            });
          }
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // update sample stuck in status 2
  async updateSample() {
    this.setState({
      updateSubmit: true
    }, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          },
          body: {
            'vialId': this.state.updateRequest.vialId,
            'testType': 'SARS',
            'results': this.state.testResult
          }
        };
        return API.post("qs2publishAdmin", "/qs2publishAdmin", myInit)
        .then(res => {
          toast.success("Sample " + this.state.updateRequest.vialId + " updated.");
          let _results = [ ...this.state.results ];
          let _updatedResults = _results.map(r => {
            if (this.state.testResult === 'negative') {
              r.status = 4;
            } else {
              r.status = 3
            }
            return r;
          });
          this.setState({
            updateRequest: {},
            isUpdate: false,
            updateSubmit: false,
            testResult: null,
            results: _updatedResults
          });
        });
      }).catch(error => {
        console.log("Error: " + error);
        toast.error("Unable to update sample " + this.state.updateRequest.vialId + ".");
        this.setState({
          updateRequest: {},
          isUpdate: false,
          updateSubmit: false,
          testResult: null,
        });
      });
    });
  }

  // prepare info for update review form
  prepUpdateData(barcode, collectionTime, curStatus) {
    this.setState({
      updateRequest: {
        vialId: barcode,
        curStatus: curStatus,
        collectionTime: collectionTime
      },
      isUpdate: true
    });
  }

  // display sample update form
  renderUpdateForm() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col col-md-8 cold-md-offset-2">
              <h2>View Test Aging Report</h2>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <h3>
                <strong>Update Antigen Sample</strong>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-6 col-md-offset-3">
              <div className="callout callout-warning">
                <strong>NOTE:</strong>&nbsp;Only use this to update samples for which results have been generated, but have not been successfully published by Quidel's LIMS interface.
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-6 col-md-offset-3">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Vial ID:</strong></td>
                    <td>{this.state.updateRequest.vialId}</td>
                  </tr>
                  <tr>
                    <td><strong>Collection Time:</strong></td>
                    <td>{this.state.updateRequest.collectionTime}</td>
                  </tr>
                  <tr>
                    <td><strong>Current Status:</strong></td>
                    <td>{this.state.updateRequest.curStatus}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="input-group">
                        <label>Choose Test Result <span className="form-required" style={{ color: 'red' }} title="This field is required.">*</span></label>
                        <div className="radio">
                          <label>
                            <input type="radio" name="testResult" value="negative" onChange={this.handleChange} />
                            Negative
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input type="radio" name="testResult" value="positive" onChange={this.handleChange} />
                            Positive
                          </label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <button 
                className="btn btn-default mr-2"
                disabled={this.state.updateSubmit}
                onClick={
                  () => {
                    this.setState({
                      isUpdate: false,
                      testResult: null,
                      updateRequest: {}
                    }) 
                  }
                }
              >
                Cancel
              </button>
              <button 
                className="btn btn-primary"
                disabled={this.state.updateSubmit || this.state.testResult === null}
                onClick={this.updateSample}
              >SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>View Test Aging Report</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to View Test Aging Report</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>View Test Aging Report</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>View Test Aging Report</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateTestSelectForm() {
    return (
      <form>
        <div className="row">
          <div className="col col-md-4 form-group">
            <label htmlFor="testDate">Test Date</label>
            <input className="form-control" type="date" id="testDate" name="testDate" placeholder="yyyy-mm-dd" onChange={this.handleChange} defaultValue={this.state.testDate} />
          </div>
        </div>
        <div className="row">
          <div className="col col-md-2 form-group">
            <label htmlFor="testType">Test Type</label>
            <select className="form-control" name="testType" value={this.state.testType} onChange={this.handleChange}>
              <option defaultValue value="AG">AG</option>
              <option value="PCR">PCR</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col col-md-2">
            <button className={`btn btn-primary`} onClick={this.getResults}>View Results</button>
          </div>
        </div>
      </form>
    )
  }

  renderDateTestSelect() {
    return (
      <>
      <div className="row">
        <h3>View Test Aging Report</h3>
        {
          this.state.noResults &&
          <div className='alert alert-warning'>There are no test results for that date.</div>
        }
        </div>
        <div className="row">
          <div className='alert alert-info'>Selected date is compared to test collection date.</div>
        </div>
        { this.renderDateTestSelectForm()}
      </>
    );
  }

  updateCellFormatter(cell, row) {
    return (
      <>
      <span>{cell}</span>
      {
        this.state.testType === 'AG' && row.status === 2 && this.state.isAdmin &&
        <span>
          &nbsp;&nbsp;<button className="btn btn-primary btn-xs" onClick={ () => this.prepUpdateData(row.barcode, row.collection_time, row.status) }>Update</button>
        </span>
      }
      </>
    )
  }

  renderResults() {
    let columns = [
      {
        dataField: 'barcode',
        text: 'Vial ID',
        formatter: this.updateCellFormatter,
        sort: true
      },
      {
        dataField: 'status',
        text: 'Current Status',
        sort: true,
        headerAttrs: {
          title: 'Current Status',
          'data-tip': '',
          'data-for': 'status'
        }
      },
      {
        dataField: 'collection_time',
        text: 'Collection Time',
        sort: true
      },
      {
        dataField: 'status_time',
        text: 'Last Update Time',
        sort: true
      },
      {
        dataField: 'elapsed',
        text: 'Time Elapsed',
        sort: true
      }
    ];
    const defaultSorted = [
      {
        dataField: 'barcode',
        order: 'asc'
      }
    ];

    return (
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col">
            <a href="/tar"><span className="h2 pseudo-link">Test Aging Report</span></a>&nbsp;&nbsp;
            <button type="button" className="btn btn-primary" onClick={() => { this.getResults(); }}>
              <i className="btn-icon ua-brand-refresh">&nbsp;</i>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="alert alert-success text-white" role="alert"><strong>{this.state.testType} tests for {this.state.testDate}</strong></div>
          <h3>Summary Statistics</h3>
          <div className="table-responsive">
            <table className="table-striped">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Total Tests</th>
                  <th style={{ textAlign: 'center' }}>Tests Completed</th>
                  <th style={{ textAlign: 'center' }}>Tests Not Completed</th>
                  <th style={{ textAlign: 'center' }}>Average Turn Around Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.total}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.complete}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.not_complete}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.average}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <BootstrapTable
            classes="table-responsive"
            keyField='id'
            data={this.state.results}
            columns={columns}
            defaultSorted={defaultSorted}
            hover
            striped
            condensed
          />
          <ReactTooltip place="top" type="dark" effect="float" id="status">
            <p>Status Code Legend</p>
            <ol>
              <li>Vial with swab received at sample collection facility</li>
              <li>Sample received at lab</li>
              <li>Result received from lab</li>
              <li>Result sent to results portal</li>
              <li>Additional alerts sent for positive result</li>
              <li>Sample rejected, sent to patient</li>
              <li>Borderline positive &mdash; re-test required</li>
            </ol>
          </ReactTooltip>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults && !this.state.isUpdate)) && this.renderDateTestSelect()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && !this.state.isUpdate && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && !this.state.isUpdate && this.state.results.length === 0)) && this.renderDateTestSelect()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.isUpdate)) && this.renderUpdateForm()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
