import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";
import "../css/TestTracking.css";

export default class TestTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: null,
      grpsOk: false,
      files: [],
    };
    this.getResults = this.getResults.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:tats-running-total'
    ];

    if (this.props.groups !== prevProps.groups) {
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
        this.getResults();
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({
      isLoading: true,
      results: null,
      files: [],
    }, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = { // OPTIONAL
          headers: {
            Authorization: token
          }
        }
        return API.get("tatsRunningTotals", "/tatsRunningTotals", myInit)
          .then(res1 => {
            return API.get("tatsRunningTotalsSnapshotsList", "/tatsRunningTotalsSnapshotsList", myInit)
              .then(res2 => {
                this.setState({
                  results: res1,
                  files: res2.files,
                  isLoading: false,
                });
              });
          });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }

  // get signed S3 download URL for file and download in new tab
  async downloadFile(filedate) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          date: filedate,
        }
      }
      return API.post("tatsRunningTotalsSnapshotsDownloadUrl", "/tatsRunningTotalsSnapshotsDownloadUrl", myInit)
        .then(res => {
          window.open(res.url, "_blank");
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>TATS Test Tracking &mdash; Today and Past 7 Days</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to view stats</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>TATS Test Tracking &mdash; Today and Past 7 Days</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>TATS Test Tracking &mdash; Today and Past 7 Days</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResults() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3">
          <div className="col margin-align-middle">
            <span className="h2 margin-align-middle pl-0">TATS Test Tracking &mdash; Today and Past 7 Days</span>&nbsp;&nbsp;
            <button type="button" className="btn btn-sm btn-primary" onClick={() => { this.getResults(); }}>
              <i className="btn-icon ua-brand-refresh">&nbsp;</i>
            </button>
          </div>
        </div>
        <div className="callout callout-info">
          <p>
            <strong>Important Notes</strong>
          </p>
          <ul>
            <li>This page does not include test results from Campus Health.</li>
            <li>Where this page shows scanned tests (rather than results), the scanned test numbers will not always match the dashboard, as the dashboard shows results only.</li>
            <li>The Past 7 Days does not include today.</li>
          </ul>
        </div>
        <div className="row">
          <h3>TATS Tests Scanned</h3>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-bordered-tt">
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup span="4"></colgroup>
              <colgroup span="4"></colgroup>
              <thead>
                <tr>
                  <td rowSpan="2"></td>
                  <th colSpan="4" scope="colgroup" className="text-center">TODAY</th>
                  <th colSpan="4" scope="colgroup" className="text-center">PAST 7 DAYS</th>
                </tr>
                <tr>
                  <th scope="col">Dorm Students</th>
                  <th scope="col">Off-Campus Students</th>
                  <th scope="col">Employees</th>
                  <th scope="col">Total</th>
                  <th scope="col">Dorm Students</th>
                  <th scope="col">Off-Campus Students</th>
                  <th scope="col">Employees</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(this.state.results.scanned).map((r, idx) => {
                    return (
                      <tr key={`scan-result-${idx}`}>
                        <th scope="row">{r}</th>
                        <td >{this.state.results.scanned[r].dorm_today}</td>
                        <td>{this.state.results.scanned[r].off_today}</td>
                        <td>{this.state.results.scanned[r].employee_today}</td>
                        <td>{this.state.results.scanned[r].total_today}</td>
                        <td>{this.state.results.scanned[r].dorm_past}</td>
                        <td>{this.state.results.scanned[r].off_past}</td>
                        <td>{this.state.results.scanned[r].employee_past}</td>
                        <td>{this.state.results.scanned[r].total_past}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <h3>TATS Positive Results</h3>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-bordered-tt">
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup span="4"></colgroup>
              <colgroup span="4"></colgroup>
              <thead>
                <tr>
                  <td rowSpan="2"></td>
                  <th colSpan="4" scope="colgroup" className="text-center">TODAY</th>
                  <th colSpan="4" scope="colgroup" className="text-center">PAST 7 DAYS</th>
                </tr>
                <tr>
                  <th scope="col">Dorm Students</th>
                  <th scope="col">Off-Campus Students</th>
                  <th scope="col">Employees</th>
                  <th scope="col">Total</th>
                  <th scope="col">Dorm Students</th>
                  <th scope="col">Off-Campus Students</th>
                  <th scope="col">Employees</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(this.state.results.positives).map((r, idx) => {
                    return (
                      <tr key={`pos-result-${idx}`}>
                        <th scope="row">{r}</th>
                        <td >{this.state.results.positives[r].dorm_today}</td>
                        <td>{this.state.results.positives[r].off_today}</td>
                        <td>{this.state.results.positives[r].employee_today}</td>
                        <td>{this.state.results.positives[r].total_today}</td>
                        <td>{this.state.results.positives[r].dorm_past}</td>
                        <td>{this.state.results.positives[r].off_past}</td>
                        <td>{this.state.results.positives[r].employee_past}</td>
                        <td>{this.state.results.positives[r].total_past}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="row d-flex">
          <div className="col col-md-6">
            <h3>TATS Scans by Test Site</h3>
          </div>
          <div className="d-flex col col-md-6 align-self-end justify-content-end">
            <div className="dropdown pb-2">
              <button className="btn btn-primary dropdown-toggle" type="button" id="snapshotDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                Download Snapshot
                <span className="caret"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="snapshotDropdown">
                {
                  this.state.files.map((f, idx) => <li key={`snapshot${idx}`}><a onClick={() => { this.downloadFile(f); }}>{f}</a></li>)
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-bordered-tt">
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup>
                <col></col>
              </colgroup>
              <thead>
                <tr>
                  <th scope="colgroup" className="text-center">SITE</th>
                  <th scope="colgroup" className="text-center">AG TEST SCANS TODAY</th>
                  <th scope="colgroup" className="text-center">QV AG TEST SCANS TODAY</th>
                  <th scope="colgroup" className="text-center">PCR TEST SCANS TODAY</th>
                  <th scope="colgroup" className="text-center">TOTAL TEST SCANS TODAY</th>
                  <th scope="colgroup" className="text-center">AG TEST SCANS PAST 7 DAYS</th>
                  <th scope="colgroup" className="text-center">QV AG TEST SCANS PAST 7 DAYS</th>
                  <th scope="colgroup" className="text-center">PCR TEST SCANS PAST 7 DAYS</th>
                  <th scope="colgroup" className="text-center">TOTAL TEST SCANS PAST 7 DAYS</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(this.state.results.scans_by_site).map((r, idx) => {
                    return (
                      <tr key={`site-result-${idx}`}>
                        <th scope="row">{r}</th>
                        <td>{this.state.results.scans_by_site[r].today.AG}</td>
                        <td>{this.state.results.scans_by_site[r].today['QV AG']}</td>
                        <td>{this.state.results.scans_by_site[r].today.PCR}</td>
                        <td>{this.state.results.scans_by_site[r].today.Total}</td>
                        <td>{this.state.results.scans_by_site[r].past.AG}</td>
                        <td>{this.state.results.scans_by_site[r].past['QV AG']}</td>
                        <td>{this.state.results.scans_by_site[r].past.PCR}</td>
                        <td>{this.state.results.scans_by_site[r].past.Total}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <h3>TATS Dorm Testing and Positives</h3>
          <div className="table-responsive">
            <table className="table table-striped table-bordered table-bordered-tt">
              <colgroup>
                <col></col>
              </colgroup>
              <colgroup span="2"></colgroup>
              <colgroup span="2"></colgroup>
              <thead>
                <tr>
                  <td rowSpan="2"></td>
                  <th colSpan="2" scope="colgroup" className="text-center">TODAY</th>
                  <th colSpan="2" scope="colgroup" className="text-center">PAST 7 DAYS</th>
                </tr>
                <tr>
                  <th scope="col">Positives</th>
                  <th scope="col">Test Scans</th>
                  <th scope="col">Positives</th>
                  <th scope="col">Test Scans</th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(this.state.results.dorms).map((r, idx) => {
                    return (
                      <tr key={`dorm-result-${idx}`}>
                        <th scope="row">{r}</th>
                        <td >{this.state.results.dorms[r].pos_today}</td>
                        <td>{this.state.results.dorms[r].scans_today}</td>
                        <td >{this.state.results.dorms[r].pos_past}</td>
                        <td>{this.state.results.dorms[r].scans_past}</td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.results !== null)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
