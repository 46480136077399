import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { dateFilter } from 'react-bootstrap-table2-filter';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

export default class DormIsolationRetestRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false,
      pos_cnt: 0,
      pos_retest_cnt: 0,
      neg_retest_cnt: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:dorm-iso-rpt'
    ];

    if (this.props.groups !== prevProps.groups) {
      this.setState({ csvGroups: this.props.groups });
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false },
          () => { this.getResults() });
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  locationCellFormatter(cell) {
    const loc_map = {
      'TATS': 'TATS',
      'CHS': 'Campus Health',
      'OFF': 'Off-Campus'
    };
    return loc_map[cell];
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({
      isLoading: true,
      noResults: false,
      results: [],
    }, () => {
      // console.log("*** inside getResults:")
      // console.log(groups);
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = { // OPTIONAL
          headers: {
            Authorization: token
          }
        }
        return API.get("dormIsolationRetestList", "/dormIsolationRetestList", myInit)
          .then(res => {
            if (res.records.length === 0) {
              this.setState({
                noResults: true,
                results: [],
                isLoading: false
              });
            } else {
              for (let i=0; i<res.records.length; i++) {
                let rec = res.records[i];
                rec['id'] = i++;
              }
              this.setState({
                results: res.records,
                pos_cnt: res.pos_cnt,
                pos_retest_cnt: res.pos_retest_cnt,
                neg_retest_cnt: res.neg_retest_cnt,
                noResults: false,
                isLoading: false
              });
            }
          });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Isolation Testing Report</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to View Isolation Testing Report</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Isolation Testing Report</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Isolation Testing Report</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResults() {
    let columns = [
      {
        dataField: 'full_name',
        text: 'Name',
        sort: false
      },
      {
        dataField: 'emplid',
        text: 'EMPLID',
        sort: false
      },
      {
        dataField: 'phone',
        text: 'Phone',
        sort: false
      },
      {
        dataField: 'netid',
        text: 'NetID',
        sort: false
      },
      {
        dataField: 'email',
        text: 'UA Email',
        sort: false
      },
      {
        dataField: 'dorm_name',
        text: 'Dorm',
        sort: false
      },
      {
        dataField: 'groups',
        text: 'Groups',
        sort: false
      },
      {
        dataField: 'retest_date',
        text: 'Retest Date',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'retest_result',
        text: 'Retest Result',
        sort: false
      },
      {
        dataField: 'collection_date',
        text: 'Collection Date',
        sort: true,
        filter: dateFilter()
      },
      {
        dataField: 'test_loc',
        text: 'Test Location',
        sort: false,
        formatter: this.locationCellFormatter
      },  
      {
        dataField: 'retest_allow_date',
        text: 'Retest Allowed Date',
        sort: false
      },  
      {
        dataField: 'release_date',
        text: 'Release Date',
        sort: true,
        filter: dateFilter()
      }, 
    ];
    const defaultSorted = [
      {
        dataField: 'collection_date',
        order: 'desc'
      }
    ];

    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 d-flex">
          <div className="col col-md-6">
            <a href="/isolation"><span className="h2 pseudo-link">Isolation Testing</span></a>&nbsp;&nbsp;
            <button type="button" className="btn btn-primary" onClick={() => { this.getResults(); }}>
              <i className="btn-icon ua-brand-refresh">&nbsp;</i>
            </button>
          </div>
        </div>
        <div className="row">
          <h3>Summary Statistics for Last 10 Days</h3>
          <div className="table-responsive">
            <table className="table-striped">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Positive On-Campus Students</th>
                  <th style={{ textAlign: 'center' }}>Positive Retests</th>
                  <th style={{ textAlign: 'center' }}>Negative Retests</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.pos_cnt}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.pos_retest_cnt}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.neg_retest_cnt}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <BootstrapTable
            classes="table-responsive"
            keyField='id'
            data={this.state.results}
            columns={columns}
            defaultSorted={defaultSorted}
            filter={ filterFactory() }
            hover
            striped
            condensed
          />
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Isolation Testing Report</h2>
              <h3>There are no results at this time.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
