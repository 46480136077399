import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import BootstrapTable from 'react-bootstrap-table-next';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

export default class IncompleteSamples extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false
    };

    this.getResults = this.getResults.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:inc-samples-rpt'
    ];

    if (this.props.groups !== prevProps.groups) {
      this.setState({csvGroups: this.props.groups});
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false },
          () => { this.getResults(); } );
      }
    }
  }

  async componentDidMount() {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:inc-samples-rpt',
    ];

    if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
      return;
    }

    // see if any of allowed groups are in our group set
    for (let grp of allowedGroups) {
      if (this.props.groups.includes(grp)) {
        validGroup = true;
      }
    }

    if (validGroup) {
      this.setState({ grpsOk: true, isLoading: false },
        () => { this.getResults(); });
    }
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({ isLoading: true });
    // console.log("*** inside getResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("incompleteSamplesDump", "/incompleteSamplesDump", myInit)
      .then(res => {
        this.setState({
          results: res.records,
          isLoading: false,
          noResults: res.records.length === 0
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>TATS Incomplete Samples</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to View Incomplete Samples</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>TATS Incomplete Samples</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>TATS Incomplete Samples</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>TATS Incomplete Samples</h2>
              <h3>There are no incomplete test samples to view.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  testTypeCellFormatter(cell, row) {
    if (row.vial_id.startsWith('UA79-')) {
      return 'QV AG';
    }
    return cell;
  }

  renderResults() {
    let columns = [
      {
        dataField: 'full_name',
        text: 'Name'
      },
      {
        dataField: 'netid',
        text: 'NetID'
      },
      {
        dataField: 'test_type',
        text: 'Test Type',
        formatter: this.testTypeCellFormatter
      },
      {
        dataField: 'takeaway',
        text: 'TakeAway'
      },
      {
        dataField: 'collection_time',
        text: 'Collection Date/Time',
        sort: true
      },
      {
        dataField: 'last_update_time',
        text: 'Last Update Date/Time'
      },
      {
        dataField: 'status',
        text: 'Sample Status',
        sort: true
      },
      {
        dataField: 'vial_id',
        text: 'Vial ID'
      }
    ];
    const defaultSorted = [
      {
        dataField: 'collection_time',
        order: 'asc'
      }
    ];

    return (
      <div>
        <div>
          <h2>TATS Incomplete Samples</h2>
        </div>
        <BootstrapTable
          classes="table-responsive"
          keyField='vial_id'
          data={ this.state.results }
          columns={ columns }
          defaultSorted={ defaultSorted }
          hover
          striped
          condensed
        />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
