import React, {Component} from "react";
import {Form} from "react-bootstrap";
import moment from "moment";
import config from "../config";
import { AuthenticatedLink } from "../components/AuthenticatedLink";

export default class ExportAdminPcr extends Component {
    constructor(props) {
        super(props);

        let now_str = moment().format("YYYY-MM-DD");

        this.state = {
            startDate: now_str,
            endDate: now_str,
            processing: false,
            downloadUrl: '',
            csvFilename: ''
        }

        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
    }

    onStartDateChange(event) {
        this.setState({
            startDate: event.target.value
        }, () => {
            this.updateUrl();
        });


    }

    onEndDateChange(event) {
        this.setState({
            endDate: event.target.value
        }, () => {
            this.updateUrl();
        });

    }

    updateUrl() {
        //event.preventDefault();
        //event.stopPropagation();

        const base_url = config.apiGateway.URL + "/downloadCsv?";

        let queryArr = ["azdhs=1", "test_type=PCR", "results_positive=1", "results_negative=1"];

        const fromDate = this.state.startDate;
        if (fromDate.length > 0 && fromDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            queryArr.push('from=' + fromDate);
        }

        const toDate = this.state.endDate;
        if (toDate.length > 0 && toDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            queryArr.push('to=' + this.state.endDate);
        }


        let queryStr = queryArr.join('&');

        this.setState({
            downloadUrl: base_url + queryStr,
            csvFilename: 'azdhs_' + fromDate + '_' + toDate+ '.csv'
        }, () => {
            console.log(this.state);
            this.forceUpdate();
        });

    }

    componentDidMount() {
        this.updateUrl();
    }

    renderUnauthorized() {
        return (<p>You do not have the appropriate permissions to access this page.</p>);
    }

    renderAdminHome() {
        return (
            <div className="row">
                <h3>Export PCR Test Results</h3>
                <Form onSubmit={this.onSubmit}>
                    <div className='alert alert-info'>Date range is compared to result review date.</div>
                    <Form.Group className="col-md-6" controlId="formStartDate">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" placeholder="yyyy-mm-dd" onChange={this.onStartDateChange} defaultValue={this.state.startDate}/>
                    </Form.Group>

                    <Form.Group className="col-md-6"  controlId="formEndDate">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" placeholder="yyyy-mm-dd" onChange={this.onEndDateChange} defaultValue={this.state.endDate}/>
                    </Form.Group>

                    { this.state.downloadUrl.length > 0  &&
                        <div className="col-md-2">
                            <AuthenticatedLink url={this.state.downloadUrl}  filename={this.state.csvFilename} >
                                Download CSV
                            </AuthenticatedLink>
                        </div>
                    }

                </Form>
            </div>
        );
    }

    render() {
        return (
            <div className="ExportAdmin container">
                {this.props.isPcrAdmin && this.renderAdminHome()}
                {(!this.props.isPcrAdmin || this.props.authn !== 'signedIn' || !this.props.authz) && this.renderUnauthorized()}
            </div>
        );

    }
}