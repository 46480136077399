import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";

export default class VaccineExemptionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      reqComplete: false,
      grpsOk: false
    };
    this.reqVaccinationsRpt = this.reqVaccinationsRpt.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:vaccinations-report'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({ csvGroups: this.props.groups });
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true });
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getVaccineResults from componentDidMount");
    // await this.getVaccineResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // request vaccinations report
  async reqVaccinationsRpt(groups) {
    this.setState({isLoading: true});
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("vaccinationsRptReq", "/vaccinationsRptReq", myInit)
        .then(() => {
          this.setState({
            isLoading: false,
            reqComplete: true
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }
  
  renderLander() {
    return (
      <div className="lander">
        <h1>Vaccine Exemption Report Download</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to Request Vaccine Exemption CSV</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Vaccine Exemption Report Download</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Vaccine Exemption Report Request</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCSVReqResult() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Vaccine Exemption Report Request</h2>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="3em" height="3em"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"></path></svg>
              <div className="callout callout-info">
                <strong>CSV Request Submitted</strong>
                <br/><br/>
                Your CSV download request has been submitted. You will receive an email notification from <strong>covidhelp@arizona.edu</strong> with a download link once your CSV is ready (typically less than 5 minutes).
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCSVRequest() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Vaccine Exemption Report Request</h2>
              <button className="btn btn-large btn-primary" onClick={this.reqVaccinationsRpt}>Request CSV</button>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.reqComplete)) && this.renderCSVRequest()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.reqComplete)) && this.renderCSVReqResult()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
