import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import CustomOAuthButton from './CustomOAuthButton';
import PageMenu from "./components/PageMenu";
import Amplify, {Auth, Hub, API} from 'aws-amplify';
import config from "./config";
import {authz} from "./authorization";
import Routes from "./Routes";
import t3logo from './images/t3logo.png';

const oauth = {
  domain: config.cognito.DOMAIN,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: config.cognito.REDIRECT_SIGNIN.replace(/\/+$/, "") + document.location.pathname.replace(/\/+$/, "") + "/",
  redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
  responseType: 'code' // or token
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "query",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "audit",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "authz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "posResultsDump",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "saferResultsDump",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "search",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "pcrResultsPublish",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "pcrLetterDownload",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "sampleCollectionSites",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "sampleCollectionAuthz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "sampleCollectionCreate",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "sampleCollectionDelete",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "sampleCollectionStats",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "covidwatchCode",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "covidwatchCodeTest",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "groupResults",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "campusHealthUploadStage",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "campusHealthUploadComplete",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "mapData",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "labelPrintAuthz",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "labelPrintAuthzStatic",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "labelPrintGenBarcode",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "dormComplianceResultsDump",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "tarResults",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "pascResults",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "pascAction",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "pascCsvData",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "dormTestingResults",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "pac12Results",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusResultsList",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusResultsListFiles",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusQuery",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusResultPublish",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusResultDelete",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "testExemptionRequestList",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "testExemptionListFiles",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "testExemptionResolve",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "testExemptionRefer",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "studentComplianceRptReq",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "vaccinationsUploadStage",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "vaccinationsUploadComplete",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "saferCcnResultsDump",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "saferCcnListFiles",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "saferCcnGetFileUrl",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "wifiBlockGetStats",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "wifiBlockLookup",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "vaccinationsRptReq",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "immunityRptReq",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "modernaTrialLogin",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "rotcComplianceRptReq",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "campusHealthResultsDump",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "convocationAdminAuthz",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "convocationCheckAdmin",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "catcardPhotoLookupAdmin",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "offCampusNegativeResultPublish",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "offCampusPositiveResultUploadSig",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "offCampusNegativeResultUploadSig",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "offCampusNegativeResultListFiles",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },      
      {
        name: "offCampusNegativeResultList",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },      
      {
        name: "offCampusNegativeResultAdminUpdate",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "breakthruRptReq",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "qs2publishAdmin",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "studVaccRptReq",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "studentAthleteVaxRptReq",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
      {
        name: "tatsRunningTotals",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "tatsRunningTotalsSnapshotsList",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "tatsRunningTotalsSnapshotsDownloadUrl",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "saferResultsDumpReq",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "incompleteSamplesDump",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "dormIsolationRetestList",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "quickVueSampleLookup",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "quickVueResultsPublish",
        endpoint: config.apiGatewayStack3.URL,
        region: config.apiGatewayStack3.REGION
      },
      {
        name: "tableauEmbedTicket",
        endpoint: config.apiGatewayStack2.URL,
        region: config.apiGatewayStack2.REGION
      },
    ]
  }
});
// Auth.configure({
//   oauth: oauth,
//   region: config.cognito.REGION,
//   userPoolId: config.cognito.USER_POOL_ID,
//   userPoolWebClientId: config.cognito.APP_CLIENT_ID
// });

class App extends Component {
  constructor(props) {
    super(props);
    this.signOut = this.signOut.bind(this);
    this.refreshAuthNZ = this.refreshAuthNZ.bind(this);
    this.updateGroups = this.updateGroups.bind(this);
    this.state = {
      authn: 'loading',
      authz: false,
      isAdmin: false,
      isPcrAdmin: false,
      showSubMenu: false,
      netID: '',
      name: '',
      groups: []
    };
    // let the Hub module listen on Auth events
    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {

      const adminGrouperGroup = config.admin.GROUPER_GROUP;

      const pcrAdminGrouperGroup = config.pcradmin.GROUPER_GROUP;

      const { attributes } = user;

      let isAdmin = false;

      let isPcrAdmin = false;

      if (attributes && attributes["custom:stars_ismemberof"] && attributes["custom:stars_ismemberof"].length > 0) {
        if (attributes["custom:stars_ismemberof"].includes(adminGrouperGroup)) {
          isAdmin = true;
        }
        if (attributes["custom:stars_ismemberof"].includes(pcrAdminGrouperGroup)) {
          isPcrAdmin = true;
        }
      }

      authz().then(res => {
        Auth.currentUserInfo().then(res2 => {
          this.setState({
            authn: 'signedIn',
            authz: res,
            netID: res2.attributes['custom:netid'],
            name: res2.attributes['name']
          });
          if (res2.attributes.hasOwnProperty('custom:stars_ismemberof')) {
            this.updateGroups(res2.attributes['custom:stars_ismemberof']);
          }
        });
       // console.log('signed in as: ' + JSON.stringify(user));
      });
    }).catch(e => {
      console.log(e);
      this.refreshAuthNZ({
        data: {
          authn: 'signIn',
          authz: false,
          isAdmin: false,
          isPcrAdmin: false,
          netID: '',
          name: ''
        }
      });
      this.updateGroups('');
//      console.log('not signed in: ' + e.response);
    });
  }

  onAuthEvent(payload) {
    // The Auth module will emit events when user signs in, signs out, etc
    // console.log("*** auth payload:");
    // console.log(JSON.stringify(payload));
    switch (payload.event) {
      case 'signIn':
        // Auth.currentUserInfo().then(res => {
        //   console.log("*** current user info");
        //   console.log(res);
        //   if (res.attributes.hasOwnProperty('custom:stars_ismemberof')) {
        //     if (res.attributes['custom:stars_ismemberof'].includes('arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm')) {
        //       console.log("*** arizona.edu:services:enterprise:ctrds:pos-results-dump-dorm");
        //     } else {
        //       console.log("*** foo!");
        //     }
        //   }
        // });
        authz().then(res => {
          Auth.currentUserInfo().then(res2 => {
            this.setState({
              authn: 'signedIn',
              authz: res,
              netID: res2.attributes['custom:netid'],
              name: res2.attributes['name']
            });
            if (res2.attributes.hasOwnProperty('custom:stars_ismemberof')) {
              this.updateGroups(res2.attributes['custom:stars_ismemberof']);
            }
          });
        // console.log('signed in as: ' + JSON.stringify(user));
        });
        // audit LOGIN event
        Auth.currentSession().then(session => {
          const token = session.idToken.jwtToken;
          let auditObj = {
            type: 'LOGIN'
          };
          let myInit = { // OPTIONAL
            headers: {
              Authorization: token,
              'Content-Type': 'application/json'
            },
            body: auditObj
          };
          return API.post("audit", "/audit", myInit);
        }).catch(error => {
          console.log("Error in Auth.currentSession: " + error);
          return [];
        });
        break;
      case 'signIn_failure':
        this.refreshAuthNZ({
          data: {
            authn: 'signIn',
            authz: false,
            isAdmin: false,
            isPcrAdmin: false,
            netID: '',
            name: ''  
          }
        });
        this.updateGroups('');
        //          console.log('not signed in');
        //          console.log("!!!! state = " + JSON.stringify(this.state));
        break;
      default:
        break;
    }
  }

  signOut() {
    this.refreshAuthNZ({
      data: {
        authn: 'signIn',
        authz: false,
        isAdmin: false,
        isPcrAdmin: false,
        netID: '',
        name: ''
      }
    });
    this.updateGroups('');
    Auth.signOut({ global: true });
  }

  // refresh user state
  refreshAuthNZ(res) {
    // console.log(res);
    this.setState({
      authn: res.data.authn,
      authz: res.data.authz,
      isAdmin: res.data.isAdmin,
      isPcrAdmin: res.data.isPcrAdmin,
      netID: res.data.netID,
      name: res.data.name
    });
  }

  // refresh user groups
  updateGroups(groups) {
    let isAdmin = false;
    let isPcrAdmin = false;
    let showSubMenu = false;
    const adminGrouperGroup = config.admin.GROUPER_GROUP;
    const pcrAdminGrouperGroup = config.pcradmin.GROUPER_GROUP;
    // console.log("*** groups:");
    if (groups.includes(adminGrouperGroup)) {
      isAdmin = true;
    }
    if (groups.includes(pcrAdminGrouperGroup)) {
      isPcrAdmin = true;
    }
    if (groups.length > 0) {
      groups.slice(1, -1).split(', ').forEach(grp => {
        if (grp in config.submenu) {
          showSubMenu = true;
        }
      });
    }
    this.setState({
      groups: groups,
      isAdmin: isAdmin,
      isPcrAdmin: isPcrAdmin,
      showSubMenu: showSubMenu
    });
    // console.log(this.state);
  }

  render() {
    const { authn } = this.state;
    let topDivClasses = "App";
    if (document.location.pathname.startsWith('/posresults') || document.location.pathname.startsWith('/groupresults') || document.location.pathname.startsWith('/pasc') || document.location.pathname.startsWith('/offcampus') || document.location.pathname.startsWith('/accommodations') || document.location.pathname.startsWith('/chupload') || document.location.pathname.startsWith('/neg-result-admin') || document.location.pathname.startsWith('/isolation')) {
      topDivClasses += " container-fluid";
    } else {
      topDivClasses += " container";
    }
    return (
      authn !== 'loading' &&
      <div className={topDivClasses}>
        <Navbar>
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-xs-8 col-sm-4 my-2">
                <a href="https://www.arizona.edu/test-trace-treat" title="Test All Test Smart | Home" target="_blank" rel="noopener noreferrer" className="remove-external-link-icon"><img src={t3logo} style={{maxWidth: '200px'}} alt="Test Trace Treat | Home"/></a>
              </div>
              {
                (this.state.showSubMenu && !document.location.pathname.startsWith('/sample-inv') && !document.location.pathname.startsWith('/checkin') && !document.location.pathname.startsWith('/checkin-test') && !document.location.pathname.startsWith('/event')) ?
                  <div className="col-md-6 col-xs-3 col-sm-4">
                    <div className="d-flex justify-content-md-center justify-content-xs-center justify-content-sm-center">
                      <PageMenu
                        groups={this.state.groups}
                        submenu={config.submenu}>
                      </PageMenu>
                    </div>
                  </div>
                  :
                  <></>
              }
              {
                !document.location.pathname.startsWith('/labelprint') && 
                !document.location.pathname.startsWith('/sample-inv') &&
                !document.location.pathname.startsWith('/checkin') &&
                !document.location.pathname.startsWith('/checkin-test') && 
                !document.location.pathname.startsWith('/checkin-stats') &&
                !document.location.pathname.startsWith('/event') &&
                <div className={`col-md-1 ${this.state.showSubMenu ? "col-md-offset-2" : "col-md-offset-8"} col-sm-4 ${this.state.showSubMenu ? "" : "col-sm-offset-4"} col-xs-4`}>
                  <div className="d-flex justify-content-md-end justify-content-sm-end justify-content-xs-end">
                    {authn === 'signIn' && <CustomOAuthButton>Login</CustomOAuthButton>}
                    {authn === 'signedIn' && <button className="btn btn-primary" onClick={this.signOut}>Logout</button>}
                  </div>
                </div>
              }
              {
                (document.location.pathname.startsWith('/checkin') || document.location.pathname.startsWith('/checkin-test') || document.location.pathname.startsWith('/checkin-stats') || document.location.pathname.startsWith('/sample-inv') || document.location.pathname.startsWith('/event')) &&
                authn === 'signedIn' &&
                <div className="col-md-1 col-md-offset-8 col-sm-4 col-sm-offset-4 col-xs-4">
                  <div className="d-flex justify-content-md-end justify-content-sm-end justify-content-xs-end">
                    <button className="btn btn-primary" onClick={this.signOut}>Logout</button>
                  </div>
                </div>
              }
            </div>
          </div>
        </Navbar>
        <Routes childProps={{authn: this.state.authn, authz: this.state.authz, isAdmin: this.state.isAdmin, isPcrAdmin: this.state.isPcrAdmin, groups: this.state.groups, netID: this.state.netID, name: this.state.name}} />
      </div>
    );
  }
}

export default App;
