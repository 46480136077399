import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
// import { CSVLink } from "react-csv";
import CustomOAuthButton from '../CustomOAuthButton';

import "../css/Home.css";
import { FaBorderNone } from "react-icons/fa";

export default class SaferCcn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      posResults: [],
      posResultsUrl: null,
      archiveFiles: [],
      archiveFilename: null,
      downloadUrl: null,
      grpsOk: false
    };
    this.getDownloadUrl = this.getDownloadUrl.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:safer-results-dump'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({csvGroups: this.props.groups});
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          break;
        }
      }

      if (validGroup) {
        this.setState({ isLoading: true, grpsOk: true });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getPosResults from componentDidUpdate");
        await this.getPosResults(this.props.groups);
        await this.getArchiveFiles();
        this.setState({ isLoading: false });
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getPosResults from componentDidMount");
    // await this.getPosResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // download JSON data from API endpoint
  async getPosResults(groups) {
    // console.log("*** inside getPosResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("saferCcnResultsDump", "/saferCcnResultsDump", myInit)
      .then(res => {
        this.setState({
          posResultsUrl: res.url
        });
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // download list of archive files
  async getArchiveFiles() {
    // console.log("*** inside getPosResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("saferCcnListFiles", "/saferCcnListFiles", myInit)
      .then(res => {
        if (res.files.length === 0) {
          this.setState({
            archiveFiles: []
          });
        } else {
          this.setState({
            archiveFiles: res.files
          });
        }
      });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  // get pre-signed URL for file download
  async getDownloadUrl(filename) {
    this.setState({
      downloadUrl: null,
      archiveFilename: null
    }, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = { // OPTIONAL
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          },
          body: {
            filename: filename
          }
        }
        return API.post("saferCcnGetFileUrl", "/saferCcnGetFileUrl", myInit)
        .then(res => {
          if (res.url.length > 0) {
            this.setState({
              downloadUrl: res.url,
              archiveFilename: filename
            });
          }
        });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    })
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>SAFER Classroom Case Results CSV Download</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to Download Classroom Case Results</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>SAFER Classroom Case Results CSV Download</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>SAFER Classroom Case Results CSV Download</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>SAFER Classroom Case Results CSV Download</h2>
              <h3>There are no results to download.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCSVDownload() {
    return(
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>SAFER Classroom Case Results CSV Download</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-md-offset-2">
              <a role="button" className="btn btn-primary btn-lg" href={this.state.posResultsUrl}>Download CSV</a>
            </div>
            {/* <div className="col-md-4 col-md-offset-2">
              <CSVLink
                data={this.state.posResults}
                filename={"safer-ccn-results.csv"}
                className="btn btn-primary btn-lg"
                target="_blank" rel="noopener noreferrer">
                Download CSV
              </CSVLink>
            </div> */}
            <div className="btn-group">
              <button type="button" className="btn btn-lg btn-hollow-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Archived Files <span className="caret"></span>
              </button>
              <ul className="dropdown-menu">
                  {
                    this.state.archiveFiles.map((file, idx) => {
                      return (
                        <li key={idx}><a onClick={() => this.getDownloadUrl(file.filename)}>{file.date}</a></li>
                      )
                    })
                  }
              </ul>
            </div>
          </div>
          {
            this.state.downloadUrl &&
            <div className="row mt-2">
              <div className="col-md-4 col-md-offset-6">
                <span class="label label-muted">{this.state.archiveFilename}</span>
                <a href={this.state.downloadUrl}><i title="this link is valid for 5 minutes" className="ua-brand-download"></i></a>
              </div>
            </div>
          }
        </div>
      </div>    
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.posResultsUrl)) && this.renderCSVDownload()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
