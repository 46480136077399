import React, { Component } from "react";
import CustomOAuthButton from '../CustomOAuthButton';
import QuickVueForm from './QuickVueForm';

import "../css/Home.css";

export default class QuickVue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grpsOk: false
        };
    }

    async componentDidUpdate(prevProps) {
        let validGroup = false;
        let allowedGroups = [
            'arizona.edu:services:enterprise:ctrds:quickvue'
        ];

        if (this.props.groups !== prevProps.groups) {
            if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
                return;
            }

            // see if any of allowed groups are in our group set
            for (let grp of allowedGroups) {
                if (this.props.groups.includes(grp)) {
                    validGroup = true;
                    break;
                }
            }

            if (validGroup) {
                this.setState({ grpsOk: true });
            }
        }
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>QuickVue Antigen Test Results</h1>
                <CustomOAuthButton variant="primary" size="lg">Login to Submit QuickVue Test Results</CustomOAuthButton>
            </div>
        );
    }

    renderUnauthorized() {
        return (
            <div className="lander">
                <h1>QuickVue Antigen Test Results</h1>
                <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
            </div>
        );
    }

    renderForm() {
        console.log("*** props = " + JSON.stringify(this.props));
        return (
            <QuickVueForm
                netID={this.props.netID}
                name={this.props.name}>
            </QuickVueForm>
        )
    }

    render() {
        return (
            <div className="Home">
                {(this.props.authn !== 'signedIn') && this.renderLander()}
                {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
                {(this.props.authn === 'signedIn' && this.props.authz && this.state.grpsOk) && this.renderForm()}
            </div>
        );
    }
}
