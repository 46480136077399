import React, { Component } from "react";

export default class PageMenu extends Component {

    render() {
        let groups = this.props.groups.slice(1, -1).split(', ');
        return (

            <div className="btn-group">
                <button type="button" className="btn btn-hollow-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Page Menu <span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                    <li key="999"><a href="/">My Test Results</a></li>
                    <li key="9999" role="separator" className="divider"></li>
                    {
                        groups.filter(grp => grp in this.props.submenu)
                            .map(grp => {
                                return this.props.submenu[grp].map(item => {
                                    return (
                                        <li key={item.idx}><a href={item.uri}>{item.menu_item}</a></li>
                                    )
                                }

                                )
                            })
                    }
                </ul>
            </div>
        );
    }
}