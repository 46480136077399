import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import ReactTooltip from 'react-tooltip';
import moment from "moment";

import "../css/Home.css";

export default class PascResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      showStatus2: false,
      results: [],
      updates: {},
      grpsOk: false,
      total: 0,
      aggWaitTime: 0,
      errmsg: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.getResults = this.getResults.bind(this);
    this.calcAvgWaitTime = this.calcAvgWaitTime.bind(this);
  }

  // form fields onChange handler
  handleChange(event) {
    let fields = event.target.name.split('-');
    const fieldValue = event.target.value;
    if (fields[0] === 's_co') {
      this.setState(prevState => ({
        updates: {
          ...prevState.updates,
          [fields[1]]: {
            ...prevState.updates[fields[1]],
            s_co: fieldValue
          }
        }
      }));
    } else if (fields[0] === 'op') {
      if (event.target.value !== 'default') {
        if (event.target.value === 'RELNEG') {
          this.setState(prevState => ({
            updates: {
              ...prevState.updates,
              [fields[1]]: {
                ...prevState.updates[fields[1]],
                op: fieldValue,
                s_co: 0
              }
            }
          }));
        } else {
          this.setState(prevState => ({
            updates: {
              ...prevState.updates,
              [fields[1]]: {
                ...prevState.updates[fields[1]],
                op: fieldValue
              }
            }
          }));
        }
      }
    }
  }

  handleCheck(event) {
    const target = event.target;
    const value = target.checked
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async handleUpdate(idx, barcode, status) {
    let op = this.state.updates[idx].op;
    let s_co = parseFloat(this.state.updates[idx].s_co).toFixed(2);
    if (((s_co > 0 && s_co < 1000) || status === 2) && op !== 'default') {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = { // OPTIONAL
          headers: {
            Authorization: token
          },
          body: {
            op: op,
            barcode: barcode,
            s_co: s_co
          }
        }
        return API.post("pascAction", "/pascAction", myInit)
          .then(() => {
            let _results = [...this.state.results];
            let _updates = {};
            const toDelete = new Set([barcode]);
            const delEntry = _results.filter(r => toDelete.has(r.barcode))[0];
            const _aggWaitTime = this.state.aggWaitTime - delEntry.elapsed_secs;
            const _total = this.state.total - 1; 
            _results = _results.filter(r => !toDelete.has(r.barcode));
            _results.forEach((r, idx) => {
              _updates[idx.toString()] = {
                s_co: 0,
                op: 'default'
              };
            });
            this.setState({
              results: _results,
              noResults: _results.length === 0,
              updates: _updates,
              aggWaitTime: _aggWaitTime,
              total: _total
            });
          })
          .catch(error => {
            console.log(error);
            this.setState({ errmsg: error.response.data.error });
          })
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    }
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:positive-antigen-sample-curator:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {
      this.setState({ csvGroups: this.props.groups });
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ isLoading: true, grpsOk: true });
        await this.getResults();
        this.setState({ isLoading: false });
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({
      isLoading: true,
      noResults: false,
      results: [],
      updates: {},
      errmsg: null
    });
    // console.log("*** inside getResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        }
      }
      return API.get("pascResults", "/pascResults", myInit)
        .then(res => {
          if (res.results.length === 0) {
            this.setState({
              noResults: true,
              results: [],
              aggWaitTime: 0,
              total: 0,
              isLoading: false
            });
          } else {
            let _updates = {};
            let _aggWaitTime = 0;
            let _total = 0;
            res.results.forEach((r, idx) => {
              _updates[idx.toString()] = {
                s_co: 0,
                op: 'default'
              };
              _aggWaitTime += r.elapsed_secs;
              _total += 1;
            });
            this.setState({
              results: res.results,
              noResults: false,
              isLoading: false,
              aggWaitTime: _aggWaitTime,
              total: _total,
              updates: _updates
            });
          }
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  calcAvgWaitTime() {
    let avgWaitTime = moment.duration(Math.round(this.state.aggWaitTime / this.state.total) * 1000);
    return avgWaitTime.days() + " days, " + avgWaitTime.hours().toString().padStart(2, '0') + ":" + avgWaitTime.minutes().toString().padStart(2, '0') + ":" + avgWaitTime.seconds().toString().padStart(2, '0');
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Positive Antigen Sample Curator</h1>
        <CustomOAuthButton variant="primary" size="lg">Positive Antigen Sample Curator Login</CustomOAuthButton>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Positive Antigen Sample Curator</h2>
              <h3>There are no pending positive test results.</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Positive Antigen Sample Curator</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Positive Antigen Sample Curator</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResults() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3">
          <div className="col col-md-3 margin-align-middle">
            <span className="h3 margin-align-middle">Positive Antigen Sample Curator</span>&nbsp;&nbsp;
            <button type="button" className="btn btn-sm btn-primary" onClick={() => { this.getResults(); }}>
              <i className="btn-icon ua-brand-refresh">&nbsp;</i>
            </button>
          </div>
          <div className="col col-md-4 margin-align-middle">
            <span className="text-size-h5 text-chili"><strong>Total Waiting</strong></span><span className="badge">{this.state.total}</span>
            <span className="text-size-h5 text-chili ml-3"><strong>Average Wait Time</strong></span><span className="badge">{this.calcAvgWaitTime()}</span>
          </div>
        </div>
        <div className="row mb-1">
          <label htmlFor="showStatus2">Show samples with status 2</label>
          &nbsp;&nbsp;
          <span style={{ verticalAlign: 'middle' }}>
            <input
              name="showStatus2"
              id="showStatus2"
              type="checkbox"
              checked={this.state.showStatus2}
              onChange={this.handleCheck}
            />
          </span>
        </div>
        {
          this.state.errmsg &&
          <div className="row mb-2">
            <div className="col">
              <div className="alert alert-danger" role="alert">Error updating result: {this.state.errmsg}</div>
            </div>
          </div>
        }
        <div className="row">
          <div className="table-responsive">
            <table className="table-striped table-dark  ">
              <thead>
                <tr>
                  <th>Barcode</th>
                  <th>Collection Time</th>
                  <th>Result Time</th>
                  <th>Time in Queue</th>
                  <th>NetID</th>
                  <th>Name</th>
                  <th>Affiliation</th>
                  <th>Residence</th>
                  <th>Prior Results</th>
                  <th>S/CO Ratio</th>
                  <th>Action</th>
                  <th>Update</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.results.filter(r => r.status === 2 && !this.state.showStatus2 ? false : true).map((r, idx) => {
                    return (
                      <tr key={`pasc-result-${idx}`}>
                        <td>{r.barcode}{r.status === 2 && <>&nbsp;<span class="label label-info">2</span></>}</td>
                        <td>{r.collection_time}</td>
                        <td>{r.status_time}</td>
                        <td>{r.elapsed_display}</td>
                        <td>{r.netid}</td>
                        <td>{r.name}</td>
                        <td>{r.affil}</td>
                        <td>{r.dorm !== null ? r.dorm : 'Off-Campus'}</td>
                        <td>
                          {
                            r.prior_results.length === 0 ?
                              <span>&nbsp;</span>
                              :
                              <>
                                <span data-tip='' data-for={`priors-${idx}`}><i style={{ color: '#8B0015' }} className="ua-brand-search"></i></span>
                                <ReactTooltip place="top" type="dark" effect="float" id={`priors-${idx}`}>
                                  <p>Prior Results</p>
                                  <ol>
                                    {
                                      r.prior_results.map((p, idx2) => (
                                        <li key={`priors-${idx}-item${idx2}`}>{p.result_date + ": " + p.test_type + " (" + p.result + ")"}</li>
                                      ))
                                    }
                                  </ol>
                                </ReactTooltip>
                              </>
                          }
                        </td>
                        <td><input type="number" min="1" max="1000" step="0.01" value={this.state.updates[idx.toString()].s_co} name={`s_co-${idx}`} disabled={this.state.updates[idx.toString()].op === 'RELNEG'} onChange={this.handleChange} /></td>
                        <td>
                          <select name={`op-${idx}`} value={this.state.updates[idx.toString()].op} onChange={this.handleChange}>
                            <option defaultValue value="default">Choose an action</option>
                            <option value="RELPOS">Release Positive Result</option>
                            <option value="RELINV">Set to invalid and release result</option>
                            <option value="RETEST">Require Retest</option>
                            { r.status === 2 && <option value="RELNEG">Release Negative Result</option> }
                          </select>
                        </td>
                        <td><button className="btn btn-sm btn-primary" name={`upd-${idx}`} onClick={e => { this.handleUpdate(e.target.name.split('-')[1], r.barcode, r.status) }} disabled={(this.state.updates[idx.toString()].op !== 'RELNEG' && this.state.updates[idx.toString()].s_co === 0) || this.state.updates[idx.toString()].op === 'default'}>Update</button></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
