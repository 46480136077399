export const stateOptions = [
    '', 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export const testingLocationOptions = [
    { value: '', display: 'Select a testing location' },
    { value: 'ATH', display: 'At Home Test' },
    { value: 'CSH', display: 'County/State Health Department' },
    { value: 'CVS', display: 'CVS' },
    { value: 'WGN', display: 'Walgreens' },
    { value: 'URG', display: 'Urgent Care' },
    { value: 'HOS', display: 'Hospital' },
    { value: 'OTH', display: 'Other' }
];

export const testingTypeOptions = [
    { value: '', display: 'Select a type'},
    { value: 'AG', display: 'Antigen' },
    { value: 'PCR', display: 'PCR' }
];

export const testingResultOptions = [
    { value: '', display: 'Select a result'},
    { value: 'Positive', display: 'Positive' }
];
