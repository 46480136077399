import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import moment from "moment";
import { stateOptions, testingLocationOptions, testingTypeOptions, testingResultOptions } from './Choices';


export default class OffCampusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      netid: '',
      emplid: '',
      emplidError: false,
      full_name: '',
      eppa: '',
      test_type: '',
      test_result: '',
      collection_timestamp: '',
      result_timestamp: '',
      release_date: '',
      dorm_name: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      testing_location: '',
      submitted: false,
      error: null,
      loading: false,
      today: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateReleaseDate = this.updateReleaseDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.lookupEmplid = this.lookupEmplid.bind(this);
  }

  componentDidMount() {
    let now_str = moment().format("YYYY-MM-DD");
    this.setState({
      netid: '',
      emplid: '',
      emplidError: false,
      full_name: '',
      eppa: '',
      test_type: '',
      test_result: '',
      collection_timestamp: now_str,
      result_timestamp: now_str,
      release_date: now_str,
      dorm_name: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      testing_location: '',
      submitted: false,
      error: null,
      loading: false,
      today: now_str,
    });
  }

  handleChange(event) {
    const target = event.target.name;
    this.setState({ [event.target.name]: event.target.value },
      () => {
        if (target === 'collection_timestamp') {
          let _releaseDate = this.state.dorm_name ? moment(this.state.collection_timestamp).add(10, 'd').format('YYYY-MM-DD') : moment(this.state.collection_timestamp).add(5, 'd').format('YYYY-MM-DD');
          this.updateReleaseDate(_releaseDate);
        }
      }
    );
  }

  updateReleaseDate(date) {
    this.setState({
      release_date: date
    });
  }

  async lookupEmplid() {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          emplid: this.state.emplid
        }
      }
      return API.get("offCampusQuery", "/offCampusQuery", myInit)
        .then(res => {
          let full_name = res.name;
          let netid = res.netid;
          let eppa = res.affiliation;
          let dorm_name = '';
          let dorm_address = '';
          let dorm_city = '';
          let dorm_state = '';
          let dorm_zip = '';
          let release_date = moment(this.state.collection_timestamp).add(5, 'd').format('YYYY-MM-DD');
          if (res.dorm_info) {
            dorm_name = res.dorm_info.dorm_name;
            dorm_address = res.dorm_info.dorm_address;
            dorm_city = res.dorm_info.dorm_city;
            dorm_state = res.dorm_info.dorm_state;
            dorm_zip = res.dorm_info.dorm_zip;
            release_date = moment(this.state.collection_timestamp).add(10, 'd').format('YYYY-MM-DD')
          }
          this.setState({
            emplidError: false,
            full_name: full_name,
            netid: netid,
            eppa: eppa,
            dorm_name: dorm_name,
            address1: dorm_address,
            city: dorm_city,
            state: dorm_state,
            zip: dorm_zip,
            release_date: release_date
          });
        });
    }).catch(error => {
      this.setState({
        emplidError: true
      });
      console.log("Error in Auth.currentSession: " + error);
    });
  }

  handleSubmit(event) {
    Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      const data = {
        netid: this.state.netid,
        test_type: this.state.test_type,
        test_result: this.state.test_result,
        collection_timestamp: this.state.collection_timestamp + ' 00:00:00',
        result_timestamp: this.state.result_timestamp + ' 00:00:00',
        release_date: this.state.release_date,
        dorm_name: this.state.dorm_name,
        address1: this.state.address1,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        testing_location: this.state.testing_location
      };

      let myInit = {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json'
        },
        body: data,
      }

      return API.post("offCampusResultPublish", "/offCampusResultPublish", myInit)
        .then(() => {
            this.props.handleResultAdd();
        })
        .catch((error) => {
          console.log("*** error " + JSON.stringify(error))
        });
    }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
    });
    event.preventDefault();
  }

  renderForm() {
    const { 
      netid,
      full_name,
      eppa,
      test_type,
      test_result,
      collection_timestamp,
      result_timestamp,
      release_date,
      address1,
      city,
      state,
      zip,
      testing_location
    } = this.state;

    const submitEnabled = (
      netid.length &&
      full_name.length &&
      eppa.length &&
      test_type.length &&
      test_result.length &&
      collection_timestamp.length &&
      result_timestamp.length &&
      release_date &&
      address1.length &&
      city.length &&
      state.length &&
      zip.length &&
      testing_location.length &&
      result_timestamp >= collection_timestamp &&
      release_date >= collection_timestamp
    );

    return (
        <>
          <div className="row">
            <div className="col col-md-8 col-md-offset-2">
              <h2>
                Off-Campus Test Result Submission
              </h2>
            </div>
          </div>
        {
          !this.state.full_name &&
          <>
          <div className="row">
            <div className="col col-md-4 col-md-offset-2">
              <div className="input-group">
                <label htmlFor="emplid">Enter patient's EMPLID (student or employee ID) to begin</label>
                <input type="text" id="emplid" name="emplid" className="form-control" placeholder="EMPLID" onChange={this.handleChange} />
                <div className="d-flex">
                  <span className="input-group-btn align-self-end">
                    <button className="btn btn-default" type="button" onClick={this.lookupEmplid}>Lookup</button>
                  </span>
                </div>
              </div>
              {this.state.emplidError ?
                <small className="mb-1 text-red">EMPLID not found</small> :
                <>
                </>
              }
            </div>
          </div>
          <div className="row mb-4">
            <div className="col col-md-8 col-md-offset-2">
              <button className="btn btn-lg btn-default mt-4" onClick={this.props.handleResultCancel}>Cancel</button>
            </div>
          </div>
          </>
        }
        {
          this.state.full_name &&
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col col-md-4 col-md-offset-2">
                <div>
                  <span className="label label-primary">Name</span>{this.state.full_name}
                </div>
                <div>
                  <span className="label label-primary">Affiliation</span>{this.state.eppa}
                </div>
                {this.state.dorm_name &&
                  <div>
                    <span className="label label-primary">Dorm</span>{this.state.dorm_name}
                  </div>
                }
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-2 col-md-offset-2">
                <div className="form-group">
                  <label>
                    Test Type
                    <span className="text-red">*</span>
                  </label>
                  <select
                    value={this.state.test_type}
                    onChange={this.handleChange}
                    name='test_type'
                    className='form-control'>
                    {testingTypeOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
              <div className="col col-md-2">
                <div className="form-group">
                  <label>
                    Test Result
                    <span className="text-red">*</span>
                  </label>
                  <select
                    value={this.state.test_result}
                    onChange={this.handleChange}
                    name='test_result'
                    className='form-control'>
                    {testingResultOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
              <div className="col col-md-4">
                <div className="form-group">
                  <label>
                    Test Location
                    <span className="text-red">*</span>
                  </label>
                  <select
                    value={this.state.testing_location}
                    onChange={this.handleChange}
                    name='testing_location'
                    className='form-control'>
                    {testingLocationOptions.map((tt) => <option key={tt.value} value={tt.value}>{tt.display}</option>)}
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col col-md-8 col-md-offset-2">
                <div className="row d-flex justify-content-md-between">
                  <div className="col col-md-4">
                    <div className="form-group">
                      <label>
                        Collection Date
                        <span className="text-red">*</span>
                      </label>
                      <input className="form-control" type="date" name="collection_timestamp" placeholder="yyyy-mm-dd" onChange={this.handleChange} value={this.state.collection_timestamp} />
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-group">
                      <label>
                        Result Date
                        <span className="text-red">*</span>
                      </label>
                      <input className="form-control" type="date" name="result_timestamp" placeholder="yyyy-mm-dd" onChange={this.handleChange} value={this.state.result_timestamp} />
                    </div>
                  </div>
                  <div className="col col-md-4">
                    <div className="form-group">
                      <label>
                        Release Date
                        <span className="text-red">*</span>
                      </label>
                      <input 
                        className="form-control"
                        type="date"
                        name="release_date"
                        value={this.state.release_date}
                        placeholder="yyyy-mm-dd"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-8 col-md-offset-2">
                <label className="form-group mt-3">Patient Address</label>
                <div className="mt-1 form-group">
                  <label>Street Address<span className="text-red">*</span></label>
                  <input
                    className="form-control"
                    type="text"
                    name="address1"
                    maxLength="100"
                    value={this.state.address1}
                    onChange={this.handleChange}
                    disabled={this.state.dorm_name ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-4 col-md-offset-2">
                <div className="form-group">
                  <label>City<span className="text-red">*</span></label>
                  <input
                    className="form-control"
                    type="text"
                    name="city"
                    maxLength="50"
                    value={this.state.city}
                    onChange={this.handleChange}
                    disabled={this.state.dorm_name ? true : false}
                  />
                </div>
              </div>
              <div className="col col-md-2">
                <div className="form-group">
                  <label>
                    State
                  <span className="text-red">*</span>
                  </label>
                  <select
                    value={this.state.state}
                    onChange={this.handleChange}
                    name='state'
                    className='form-control'
                    disabled={this.state.dorm_name ? true : false}>
                    {stateOptions.map((option) => <option key={option} value={option}>{option}</option>)}
                  </select>
                </div>
              </div>
              <div className="col col-md-2">
                <div className="form-group">
                  <label>
                    Zip Code
                  <span className="text-red">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="zip"
                    value={this.state.zip}
                    maxLength="5"
                    onChange={this.handleChange}
                    disabled={this.state.dorm_name ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col col-md-8 col-md-offset-2">
                <input disabled={!submitEnabled} type="submit" value="submit" className="btn btn-lg btn-blue mt-4 mr-1" />
                <button className="btn btn-lg btn-default mt-4" onClick={this.props.handleResultCancel}>Cancel</button>
              </div>
            </div>
          </form>
        }
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderForm()}
      </div>
    );
  }
}