import React, { Component } from "react";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import { FaTrashAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import OffCampusForm from '../components/OffCampusForm';
import CustomOAuthButton from '../CustomOAuthButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "../css/FixedTableHeader.css";
import "../css/OffCampus.css";
import "../css/Home.css";

export default class OffCampus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      noResults: false,
      results: [],
      files: [],
      grpsOk: false,
      housingOnly: false,
      addResult: false,
      listStartDate: moment().subtract(1, 'months').toDate(),
      listEndDate: moment().toDate(),
      listNetid: null,
    };
    this.deleteResult = this.deleteResult.bind(this);
    this.addResult = this.addResult.bind(this);
    this.handleResultAdd = this.handleResultAdd.bind(this);
    this.handleResultCancel = this.handleResultCancel.bind(this);
    this.handleListDatesChange = this.handleListDatesChange.bind(this);
    this.getResults = this.getResults.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let housingOnly = false;

    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:offcampus-results:allowed-users',
      'arizona.edu:services:enterprise:ctrds:offcampus-results:housing-users',
    ];

    if (this.props.groups !== prevProps.groups) {
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
          if (grp === "arizona.edu:services:enterprise:ctrds:offcampus-results:housing-users") {
            housingOnly = true;
          }
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, housingOnly: housingOnly });
        await this.getResults(this.props.groups);
      }
    }
  }

  async componentDidMount() {
    if (this.props.authn !== 'signedIn') {
      return;
    }
  }

  // download JSON data from API endpoint
  async getResults(groups) {
    this.setState({ isLoading: true}, () => {
      return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          },
          queryStringParameters: {
            'startDate': moment(this.state.listStartDate).format('YYYY-MM-DD'),
            'endDate': moment(this.state.listEndDate).format('YYYY-MM-DD')
          }
        };
        if (this.state.listNetid) {
          myInit.queryStringParameters['netid'] = this.state.listNetid;
        }
        return API.get("offCampusResultsList", "/offCampusResultsList", myInit)
        .then(res => {
          if (res.results.length === 0) {
            this.setState({
              isLoading: false,
              noResults: true,
              results: []
            });
          } else {
            this.setState({
              isLoading: false,
              results: res.results,
              noResults: false
            });
          }
        });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }

  // delete result
  deleteConfirm(rec) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <h4 className="card-title">Confirm Deletion</h4>
            <p>Please confirm that that you wish to delete the following record:</p>
            <strong>Name:</strong>&nbsp;{rec.full_name}<br/>
            <strong>Location:</strong>&nbsp;{rec.loc}<br/>
            <strong>Date:</strong>&nbsp;{rec.result_date}<br/>
            <strong>Test:</strong>&nbsp;{rec.test_type}<br/>
            <strong>Result:</strong>&nbsp;{rec.result}<br/><br/>
            <button className="btn btn-primary mr-2"onClick={onClose}>Cancel</button>
            <button
              className="btn btn-hollow-default"
              onClick={() => {
                onClose();
                this.deleteResult(rec.id);
              }}
            >
            Delete
            </button>
          </div>
        );
      }
    });
  }

  async deleteResult(id) {
    this.setState({
      isLoading: true,
      noResults: true,
      results: []
    }, () => {
      Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = {
          headers: {
            Authorization: token
          },
          body: {
            id: id
          }
        };
        return API.post("offCampusResultDelete", "/offCampusResultDelete", myInit)
        .then(() => {
          toast.success("Result deleted");
          this.getResults();
        });
      }).catch(error => {
        console.log("Error in Auth.currentSession: " + error);
        return [];
      });
    });
  }
  
  addResult() {
    this.setState({ addResult: true });
  }

  // get file data for request
  async getFiles(uuid) {
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          'uuid': uuid,
        },
      }
      return API.get("offCampusResultsListFiles", "/offCampusResultsListFiles", myInit)
        .then(res => {
          this.setState({
            files: res.files,
          });
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      this.setState({
        files: []
      });
    });
  }

  // display files available for download
  async displayFiles(uuid) {
    await this.getFiles(uuid);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='card card-block'>
            <div className="card-title">
              <h4>
                Files
              </h4>
              <h5><em>(Links expire after 30 minutes)</em></h5>
            </div>
            <table>
              <tbody>
                {
                  this.state.files.map((f, i) => (
                    <tr key={`file-${i}`}>
                      <td>
                        {f.filename}
                      </td>
                      <td>
                        <a href={f.url}><i className="ua-brand-download text-blue"></i></a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <button className="btn btn-primary" onClick={onClose}>CLOSE</button>
          </div>
        );
      }
    });
  }

  handleResultAdd() {
    this.setState({ addResult: false },
      () => {
        toast.success("Result Added (if not showing click refresh)");
        this.getResults();
      }
    );
  }

  handleResultCancel() {
    this.setState({ addResult: false },
      () => {
        toast.info("Result add cancelled");
      }
    );
  }

  // handler for date range change for list
  handleListDatesChange = (dates) => {
    const [start, end] = dates;
    this.setState({
      listStartDate: start,
      listEndDate: end
    });
  };

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <CustomOAuthButton variant="primary" size="lg">Off-Campus Test Results Login</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>Test All Test Smart Results Portal</h1>
        <p>The Test All Test Smart Results Portal is for retrieving your COVID-19 viral or antibody test results. The information contained in this portal is for UArizona employees and students.</p>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Off-Campus Test Results</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter"/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoResults() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>Off-Campus Test Results</h2>
              <h3>There are no off-campus test results.</h3>
            </div>
          </div>
          {
            !this.state.housingOnly &&
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <button type="button" className="btn btn-success" onClick={this.addResult}>
                  <i className='ua-brand-plus'></i>&nbsp;&nbsp;Add Result
                </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  renderOffCampusResults() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row mb-1">
            <div className="col col-md-3 margin-align-middle">
              <span className="h3 margin-align-middle">Off-Campus Test Results</span>&nbsp;&nbsp;
              <button type="button" className="btn btn-sm btn-primary" onClick={() => { this.getResults(); }}>
                <i className="btn-icon ua-brand-refresh">&nbsp;</i>
              </button>
            </div>
            {
              !this.state.housingOnly &&
              <div className="col d-flex col-md-9 justify-content-md-end align-self-center">
                <button type="button" className="btn btn-success" onClick={this.addResult}>
                  <i className='ua-brand-plus'></i>&nbsp;&nbsp;Add Result
                </button>
              </div>
            }
          </div>
          <div className="row mb-3">
            <div className="form-group">
              <div className="col col-md-3 px-3">
                <label htmlFor="listRange">Date Range</label>
                <DatePicker
                  id="listRange"
                  selected={this.state.listStartDate}
                  onChange={this.handleListDatesChange}
                  startDate={this.state.listStartDate}
                  endDate={this.state.listEndDate}
                  className="form-control"
                  popperClassName="off-campus-datepicker"
                  selectsRange
                />
              </div>
              <div className="col col-md-3">
                <label htmlFor="listNetid">NetID Search</label>
                <input type="text" maxLength="16" value={this.state.netid} onChange={this.handleChange} name='listNetid' id="listNetid" className="form-control"/>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-md-2 btn-group">
                <button className="btn btn-sm btn-primary" onClick={this.getResults} disabled={this.state.listStartDate === null || this.state.listEndDate === null}>SEARCH</button>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-12">
              <div className="table-responsive" style={{ maxHeight: '50vh', overflow: 'scroll' }}>
                <IconContext.Provider value={{ color: "#8B0015" }}>
                  <table className="table-striped table-dark">
                    <thead>
                      <tr>
                        <th className="fixed-header">NetID</th>
                        <th className="fixed-header">Name</th>
                        <th className="fixed-header">Affiliation</th>
                        <th className="fixed-header">Test Type</th>
                        <th className="fixed-header">Symptomatic</th>
                        <th className="fixed-header">Test Result</th>
                        <th className="fixed-header">Test Location</th>
                        <th className="fixed-header">Collection Date</th>
                        <th className="fixed-header">Result Date</th>
                        <th className="fixed-header">Release Date</th>
                        <th className="fixed-header">Dorm</th>
                        <th className="fixed-header">Address</th>
                        <th className="fixed-header">Phone</th>
                        <th className="fixed-header">Entered By</th>
                        <th className="fixed-header">Entry Time</th>
                        <th className="fixed-header">Files</th>
                        {
                          !this.state.housingOnly &&
                          <th className="fixed-header">Delete</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !this.state.housingOnly &&
                        this.state.results.map((r, i) => (
                          <tr key={r.id}>
                            <td>{r.netid}</td>
                            <td>{r.full_name}</td>
                            <td>{r.eppa}</td>
                            <td>{r.test_type}</td>
                            <td>{r.symptomatic !== null ? (r.symptomatic ? 'Y' : 'N') : 'N/A'}</td>
                            <td>{r.result}</td>
                            <td>{r.loc}</td>
                            <td>{r.collection_date}</td>
                            <td>{r.result_date}</td>
                            <td>{r.release_date}</td>
                            <td>{r.dorm_name ? r.dorm_name : ''}</td>
                            <td>{r.address}</td>
                            <td>{r.phone ? r.phone : ''}</td>
                            <td>{r.entry_netid}</td>
                            <td>{r.entry_timestamp}</td>
                            <td>
                              {
                                r.document_txid !== null ?
                                  <center><a onClick={() => this.displayFiles(r.document_txid)}><i className="ua-brand-download text-chili"></i></a></center>
                                :
                                ''
                              }
                            </td>
                            <td><center><a onClick={() => this.deleteConfirm(r)}><FaTrashAlt/></a></center></td>
                          </tr>
                        ))
                      }
                      {
                        this.state.housingOnly &&
                        this.state.results.filter(i => i.dorm_name !== null && i.dorm_name.length > 0).map((r, i) => (
                          <tr key={r.id}>
                            <td>{r.netid}</td>
                            <td>{r.full_name}</td>
                            <td>{r.eppa}</td>
                            <td>{r.test_type}</td>
                            <td>{r.symptomatic !== null ? (r.symptomatic ? 'Y' : 'N') : 'N/A'}</td>
                            <td>{r.result}</td>
                            <td>{r.loc}</td>
                            <td>{r.collection_date}</td>
                            <td>{r.result_date}</td>
                            <td>{r.release_date}</td>
                            <td>{r.dorm_name}</td>
                            <td>{r.address}</td>
                            <td>{r.phone ? r.phone : ''}</td>
                            <td>{r.entry_netid}</td>
                            <td>{r.entry_timestamp}</td>
                            <td>
                              {
                                r.document_txid !== null ?
                                  <center><a onClick={() => this.displayFiles(r.document_txid)}><i className="ua-brand-download text-chili"></i></a></center>
                                :
                                ''
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </IconContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <div className="container">
          <OffCampusForm 
            handleResultAdd={this.handleResultAdd}
            handleResultCancel={this.handleResultCancel}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="Home">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.addResult)) && this.renderForm()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults && !this.state.addResult)) && this.renderNoResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && !this.state.addResult)) && this.renderOffCampusResults()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
