import React, { Component } from "react";
import moment from "moment";
import { Auth, API } from 'aws-amplify';
import Spinner from 'react-spinkit';
import CustomOAuthButton from '../CustomOAuthButton';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from "react-csv";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "../css/Home.css";

export default class Pac12Results extends Component {
  constructor(props) {
    super(props);
    let now_str = moment().format("YYYY-MM-DD");
    this.state = {
      testDate: now_str,
      isLoading: false,
      noResults: false,
      results: [],
      grpsOk: false,
      summary: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.getResults = this.getResults.bind(this);
  }

  // form fields onChange handler
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async componentDidUpdate(prevProps) {
    let validGroup = false;
    let allowedGroups = [
      'arizona.edu:services:enterprise:ctrds:pac12-results:allowed-users'
    ];

    if (this.props.groups !== prevProps.groups) {
      // console.log("*** inside componentDidUpdate()");
      // console.log(this.props.groups)
      this.setState({ csvGroups: this.props.groups });
      // console.log("componentWillReceiveProps: prev state = " + JSON.stringify(this.state));
      if (this.props.authn !== 'signedIn' || this.props.groups.length === 0) {
        return;
      }

      // see if any of allowed groups are in our group set
      for (let grp of allowedGroups) {
        if (this.props.groups.includes(grp)) {
          validGroup = true;
        }
      }

      if (validGroup) {
        this.setState({ grpsOk: true, isLoading: false });
        // this.setState({ resultsOpenState: {} });
        // console.log("*** calling getResults from componentDidUpdate");
      }
    }
    // console.log("componentWillReceiveProps: new state = " + JSON.stringify(this.state));
  }

  async componentDidMount() {
    // console.log("*** inside componentDidMount()");
    // console.log(this.props.groups);
    if (this.props.authn !== 'signedIn') {
      return;
    }
    // this.setState({ resultsOpenState: {} });
    // this.setState({ isLoading: true });
    // console.log("*** calling getResults from componentDidMount");
    // await this.getResults();
    // this.setState({ isLoading: false });
    // console.log("*** inside componentDidMount()");
    // console.log("componentDidMount: state = " + JSON.stringify(this.state));
  }

  // download JSON data from API endpoint
  async getResults() {
    this.setState({
      isLoading: true,
      noResults: false,
      results: [],
      summary: {}
    });
    // console.log("*** inside getResults:")
    // console.log(groups);
    return Auth.currentSession().then(session => {
      const token = session.idToken.jwtToken;
      let myInit = { // OPTIONAL
        headers: {
          Authorization: token
        },
        body: {
          date: this.state.testDate,
          test_type: this.state.testType
        }
      }
      return API.post("pac12Results", "/pac12Results", myInit)
        .then(res => {
          if (Object.keys(res).length === 0 && res.constructor === Object) {
            this.setState({
              noResults: true,
              results: [],
              summary: {},
              isLoading: false
            });
          } else {
            let _results = [];
            let id = 0;
            for (const [key, value] of Object.entries(res.results)) {
              _results.push({
                id: ++id,
                barcode: value.barcode,
                result: value.result,
                result_time: value.result_time,
              })
            }
            this.setState({
              rawResults: res.results,
              results: _results,
              summary: res.summary,
              noResults: false,
              isLoading: false
            });
          }
        });
    }).catch(error => {
      console.log("Error in Auth.currentSession: " + error);
      return [];
    });
  }

  renderLander() {
    return (
      <div className="lander">
        <h1>View PAC-12 Results Report</h1>
        <CustomOAuthButton variant="primary" size="lg">Login to View PAC-12 Results Report</CustomOAuthButton>
      </div>
    );
  }

  renderUnauthorized() {
    return (
      <div className="lander">
        <h1>View PAC-12 Results Report</h1>
        <div className="alert alert-danger" role="alert">You do not have the appropriate permissions to use this application.</div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2>View PAC-12 Results Report</h2>
              <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateTestSelectForm() {
    return (
      <form>
        <div className="row">
          <div className="col col-md-4 form-group">
            <label htmlFor="testDate">Test Date</label>
            <input className="form-control" type="date" id="testDate" name="testDate" placeholder="yyyy-mm-dd" onChange={this.handleChange} defaultValue={this.state.testDate} />
          </div>
        </div>
        <div className="row">
          <div className="col col-md-2">
            <button className={`btn btn-primary`} onClick={this.getResults}>View Results</button>
          </div>
        </div>
      </form>
    )
  }

  renderDateTestSelect() {
    return (
      <>
      <div className="row">
        <h3>View PAC-12 Results Report</h3>
        {
          this.state.noResults &&
          <div className='alert alert-warning'>There are no test results for that date.</div>
        }
        </div>
        { this.renderDateTestSelectForm()}
      </>
    );
  }

  renderResults() {
    let columns = [
      {
        dataField: 'barcode',
        text: 'Vial ID',
        sort: true
      },
      {
        dataField: 'result',
        text: 'Result',
        sort: true
      },
      {
        dataField: 'result_time',
        text: 'Result Time',
        sort: true
      }
    ];
    const defaultSorted = [
      {
        dataField: 'barcode',
        order: 'asc'
      }
    ];

    return (
      <div className="container">
        <div className="row mt-3 mb-3 d-flex">
          <div className="col col-md-6">
            <a href="/pac12"><span className="h2 pseudo-link">PAC-12 Results Report</span></a>&nbsp;&nbsp;
            <button type="button" className="btn btn-primary" onClick={() => { this.getResults(); }}>
              <i className="btn-icon ua-brand-refresh">&nbsp;</i>
            </button>
          </div>
          <div className="col ml-md-auto align-self-center">
            <CSVLink
              data={this.state.rawResults}
              filename={`PAC12 results ${this.state.testDate.replace(/\-/g, '')}.csv`}
              className="btn btn-primary btn-lg"
              target="_blank" rel="noopener noreferrer">
              Download CSV
            </CSVLink>
          </div>
        </div>
        <div className="row">
          <div className="alert alert-success text-white" role="alert"><strong>Results for {this.state.testDate}</strong></div>
          <h3>Summary Statistics</h3>
          <div className="table-responsive">
            <table className="table-striped">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>Total Tests</th>
                  <th style={{ textAlign: 'center' }}>Total Negatives</th>
                  <th style={{ textAlign: 'center' }}>Total Positives</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.total}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.neg}</strong></td>
                  <td style={{ textAlign: 'center' }}><strong>{this.state.summary.pos}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <BootstrapTable
            classes="table-responsive"
            keyField='id'
            data={this.state.results}
            columns={columns}
            defaultSorted={defaultSorted}
            hover
            striped
            condensed
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && this.state.isLoading)) && this.renderLoading()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && this.state.noResults)) && this.renderDateTestSelect()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length > 0)) && this.renderResults()}
        {(this.props.authn === 'signedIn' && (this.props.authz && this.state.grpsOk && !this.state.isLoading && !this.state.noResults && this.state.results.length === 0)) && this.renderDateTestSelect()}
        {(this.props.authn === 'signedIn' && (!this.props.authz || !this.state.grpsOk)) && this.renderUnauthorized()}
        {(this.props.authn !== 'signedIn') && this.renderLander()}
      </div>
    );
  }
}
