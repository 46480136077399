export const testingLocationOptions = [
    { value: '', display: 'Select a testing location' },
    { value: 'PHD', display: 'Paradigm Labs/Pima County Health Department' },
    { value: 'OHD', display: 'Other Health Department' },
    { value: 'WGN', display: 'Walgreens' },
    { value: 'CVS', display: 'CVS' },
    { value: 'URG', display: 'Urgent Care' },
    { value: 'HOS', display: 'Hospital' },
    { value: 'OTH', display: 'Other' }
];

export const testingTypeOptions = [
    { value: '', display: 'Select a type'},
    { value: 'AG', display: 'Antigen' },
    { value: 'PCR', display: 'PCR' }
];

export const testingResultOptions = [
    { value: '', display: 'Select a result'},
    { value: 'Negative', display: 'Negative' }
];
